import PractitionerProfileCard from "../practitionerProfileCard/PractitionerProfileCard";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import './PractitionerList.css'
import { useState, useRef, useEffect } from "react";
import { useClickOutside } from "../../hooks";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import { getAge } from "../../utils";
import PropTypes from 'prop-types';

function PractitionerList(props) {
    const { t } = useTranslation();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const insuranceType = params.get("type");
    const { client_name, careProvider, locationOptions = [], dob, parentCallback, client_type } = props;
    const [selectedcard, setSelectedcard] = useState();
    const [filterPractitioner, setFilterPractitioner] = useState([]);
    const [showList, setShowList] = useState(false)
    const [officeLocation, setOfficeLocation] = useState("");
    const [isProviderPresent, seIsProviderPresent] = useState(true)
    const [locationId, setLocationId] = useState('');
    const locationRef = useRef(null);
    useClickOutside(locationRef, () => setShowList(false));

    const displayOptionsList = () => {
        setShowList(!showList);
    }

    const selectPractitioner = (index, obj, locationId) => {
        setSelectedcard(index);
        parentCallback(obj, officeLocation, locationId);
    }

    const onSelectChange = (value, state, locationId, addressLine1, city) => {
        const location = value;
        setOfficeLocation(location);
        setFilterPractitioner([]);
        setShowList(false);
        setSelectedcard("");
        seIsProviderPresent(true);
        setLocationId(locationId);
        getPractitioner(locationId);
        parentCallback({ firstName: "", lastName: "" }, location, locationId);
    }

    const getPractitioner = (locationId) => {
        let age = getAge(dob);
        let locationObject = locationOptions.find(x => x.values[0].value === locationId);
        let providerResponseList = locationObject?.providers;
        let responseList = providerResponseList.filter(function (object) {
            let ageRange = object.configurations?.[0]?.value.split("-") ?? [];
            if (ageRange.length > 0) {
                let minAge = parseInt(ageRange[0]);
                let maxAge = ageRange[1];
                return (age >= minAge && (maxAge !== "unlimited" ? age <= parseInt(maxAge) : true))
            } else { return true }

        });
        let aNewArray = [];
        let aFilterMD = responseList.filter(function (object) {
            return (object.degree === "MD")
        });
        let aFilterNonMD = responseList.filter(function (object) {
            return (object.degree !== "MD")
        });
        aNewArray = [...aFilterMD, ...aFilterNonMD];

        setFilterPractitioner(aNewArray);
        if (aNewArray.length > 0) {
            if (careProvider.choosePrimary || careProvider.providerID) {
                for (let i = 0; i < aNewArray.length; i++) {
                    let item = aNewArray[i];
                    if (item.id === careProvider.providerID) {
                        setSelectedcard(i);
                        setLocationId(careProvider.locationId);
                        if ((!careProvider.choosePrimary) && (careProvider.providerID)) {
                            parentCallback(item, careProvider.location, careProvider.locationId);
                        }
                        break;
                    }
                }
            }
        }
        else {
            seIsProviderPresent(false);
        }
    }


    const onLocationChange = () => {

    }

    useEffect(() => {
        let defaultLocation = (careProvider && careProvider.location) ?? "";
        let defaultLocationId = (careProvider && careProvider.locationId) ?? "";
        setOfficeLocation(defaultLocation);
        setLocationId(defaultLocationId);
        if (defaultLocation) {
            getPractitioner(defaultLocationId);
        };
    }, [careProvider])


    return (
        <div className="practiioner-list-section">
            <Container className="select-office-section">
                <Row>
                    <Col md={3} xs={12} className='select-office-label'>Select location</Col>
                    <Col md={7} xs={12} className='select-office-input'>
                        <div ref={locationRef}>
                            <Form.Control className='form-select-input' type="text" placeholder="Select location" value={officeLocation} onClick={() => displayOptionsList()} onChange={() => onLocationChange()} />

                            <div className='option-list' hidden={!showList} >
                                {locationOptions.map((item, key) => {

                                    const locationCity = ((item.values.find(obj => obj.id === "city"))?.value) ?? "";
                                    const locationName = ((item.values.find(obj => obj.id === "name"))?.value) ?? "";
                                    const addressLine1 = ((item.values.find(obj => obj.id === "addressLine1"))?.value) ?? "";
                                    const locationState = ((item.values.find(obj => obj.id === "state"))?.value) ?? "";
                                    const locationId = ((item.values.find(obj => obj.id === "id"))?.value) ?? "";
                                    return (
                                        <div className='select-option' data-testid="location-select-test" onClick={() => onSelectChange(locationName, locationState, locationId, addressLine1, locationCity)} key={key}>{locationName}</div>
                                    )
                                })}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            {insuranceType !== 'medicare' && <p className="practitioner-list-title"> {t('practitionerList.chooseProvider')} {client_name}</p>}
            {filterPractitioner.length > 0 ?
                <Container>
                    <Row md={4} xs={12} className="d-flex">
                        {filterPractitioner.map((item, key) => (
                            <PractitionerProfileCard
                                select={key === selectedcard ? true : false}
                                first_name={item.first_name}
                                last_name={item.last_name}
                                practitioner_img={`data:image/png;base64, ${item.images?.length ? item.images[0].fileDataBase64 : ""}`}
                                availability={item.availability}
                                id={item.key} key={key}
                                details={item}
                                selectPractitioner={() => selectPractitioner(key, item, locationId)}
                                client_type={client_type}
                                biography={item.biography?.length ? JSON.parse(window.atob(item.biography[0].fileDataBase64)) : null}
                            />
                        ))}
                    </Row>
                </Container> :
                <div>
                    {!isProviderPresent && <p className="practitioner-list-title"> {t('practitionerList.noProviderAvailable')}</p>}
                </div>
            }

        </div>
    )
}

PractitionerList.propTypes = {
    client_name: PropTypes.string,
    careProvider: PropTypes.object,
    locationOptions: PropTypes.array,
    dob: PropTypes.string,
    parentCallback: PropTypes.func,
    client_type: PropTypes.string,
};
export default PractitionerList;