import './PageHeading.css'
import { useTranslation } from 'react-i18next';

function PageHeading(props) {

    const { title, description } = props;
    const { t } = useTranslation();
    return (
        <>
            <h2 className="page-heading" data-testid="pageHeading-test">{ t(title) }</h2>
            {description && <p className='page-description' data-testid="pageDescription-test">{ t(description) }</p>}
        </>
    )

}

export default PageHeading;