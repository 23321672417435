export const USER_LOADED = "USER_LOADED";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const AUTH_ERROR = "AUTH_ERROR";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const SET_CAPTCHA_TOKEN="SET_CAPTCHA_TOKEN"
export const SET_CAPTCHA_ERROR= "SET_CAPTCHA_ERROR"
export const CLEAR_AUTH_DATA= "CLEAR_AUTH_DATA"