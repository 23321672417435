import { DEPENDANT, PRIMARY, SPOUSE } from "../constants";
import { patientInfo } from './types';
import { store } from '../store';

export const getPatientInfo = (id) => {
  const patients = store.getState().patientInfo;
  if (id === PRIMARY)
    return patients.primaryDetails[0].basicInfo;
  if (id === SPOUSE)
    return patients.spouseDetails[0]?.basicInfo;
  if (id.includes(DEPENDANT))
    return (patients.dependentDetails.find(dep => dep.basicInfo.id === id))?.basicInfo;
  return null;
}

export const setPatientInfo = (form, lookupExtraDetailsDep) => async (dispatch) => {
  const patients = store.getState().patientInfo;
  if (form.status === PRIMARY || form.status === "") {
    if (form.id === patients.primaryDetails[0].basicInfo.id)
      dispatch({ type: patientInfo.UPDATE_PRIMARY_ACCOUNT_HOLDER, payload: { ...form, status: PRIMARY, id: PRIMARY } });
    else dispatch({ type: patientInfo.ADD_PRIMARY_ACCOUNT_HOLDER, payload: { ...form, status: PRIMARY, id: PRIMARY } });
  }
  else if (form.status === DEPENDANT) {
    const doesDependentExist = patients.dependentDetails.find(dep => dep.basicInfo.id === form.id);
    if (!!doesDependentExist) // !! converts to boolean exclusively
      dispatch({ type: patientInfo.UPDATE_DEPENDENT, payload: { id: doesDependentExist.id, data: form, relationship: DEPENDANT, status: DEPENDANT } });
      else await dispatch({ type: patientInfo.ADD_DEPENDENT, payload: {form: { ...form, relationship: DEPENDANT, status: DEPENDANT, id: form.id }, lookupExtraDetailsDep: lookupExtraDetailsDep ?? {insurance: {}, careProvider: {}}} });
  }
  else if (form.status === SPOUSE) {
    if (form.id === patients.spouseDetails[0]?.basicInfo?.id)
      dispatch({ type: patientInfo.UPDATE_SPOUSE, payload: { ...form, relationship: SPOUSE, status: SPOUSE, id: SPOUSE } });
    else dispatch({ type: patientInfo.ADD_SPOUSE, payload: {form: { ...form, relationship: SPOUSE, status: SPOUSE, id: SPOUSE }, lookupExtraDetailsDep: lookupExtraDetailsDep ?? {insurance: {}, careProvider: {}}} });
  }
};

export const onDirectChange = ({ id, key, value }) => async dispatch => dispatch({ type: patientInfo.PATIENT_INFO_DIRECT_CHANGE, payload: { id, key, value } });

export const updatePatientIds = ({ ids, patientIds }) => async (dispatch) => {
  dispatch({ type: patientInfo.UPDATE_PATIENT_IDS, payload: { ids, patientIds } });
}

export const removePatientInfo = (id, status) => async dispatch => {
  await dispatch({ type: patientInfo.REMOVE_MEMBER, payload: { id, status } });
}

export const passResources = (resources) => async dispatch => {
  dispatch({ type: patientInfo.PASS_RESOURCES, payload: resources });
}

export const clearAllData = () => async dispatch => await dispatch({ type: patientInfo.CLEAR_ALL_DATA });

export const setAllPatient = (data) => async (dispatch) => await dispatch({ type: patientInfo.SET_ALL_PATIENT, payload: data });

export const setCmdOffice = (data) => async (dispatch) => await dispatch({ type: patientInfo.SET_CMD_OFFICE, payload: data });
