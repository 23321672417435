import React from 'react'
import './signDocs.css';

function HIPPA() {
    return (
        <div className='hippa-root'>
            <h6><b>HIPAA NOTICE OF PRIVACY PRACTICES</b></h6>
            <p className='hippa-font-15'><b>
                THIS NOTICE DESCRIBES HOW MEDICAL INFORMATION ABOUT YOU MAY BE USED AND
                DISCLOSED AND HOW YOU CAN GET ACCESS TO THIS INFORMATION.
                PLEASE REVIEW IT CAREFULLY.
                </b>
            </p>
            <p>
                This Notice of Privacy Practices describes how we may use and disclose your protected health information (PHI) to carry out treatment, payment or health care operations (TPO), and for other purposes
                that are permitted or required by law. It also describes your rights to access and control your protected
                health information. “Protected health information” is information about you, including demographic information, that may identify you and that relates to your past, present or future physical or mental health
                or condition and related health care services.
            </p>
            <h6><b>1. Uses and Disclosures of Protected Health information</b></h6>
            <p>Your protected health information may be used and disclosed by your physician, our office staff and
                others outside of our office that are involved in your care and treatment for the purpose of providing
                health care services to you to pay your health care bills, to support the operation of the physician's
                practice, and any other use required by law.</p>
            <p>    <b>Treatment:</b> We will use and disclose your protected health information to provide, coordinate, or manage your health care and any related services.This includes the coordination or management of your
                health care with a third party.For example, we would disclose your protected health information, as
                necessary, to a home health agency that provides care to you.For example, your protected health information may be provided to a physician to whom you have been referred to ensure that the physician
                has the necessary information to diagnose or treat you.</p>
            <p><b>Payment:</b> Your protected health information, will be used, as needed, to obtain payment for your health
                care services.This may include certain activities that your health insurance plan may undertake before
                it approves or pays for the health care services we recommend for you such as; making a determination of eligibility or coverage for insurance benefits, reviewing services provided to you for medical necessity, and undertaking utilization review activities.For example, obtaining approval for a hospital stay
                may require that your relevant protected health information be disclosed to the health plan to obtain
                approval for the hospital admission.</p>
            <p><b>Healthcare Operations:</b> We may use or disclose, as needed, your protected health information in
                order to support the business activities of our physician’s practice.These activities include, but are
                not limited to, quality assessment activities, employee review activities, training of medical students,
                licensing, and conducting or arranging for other business activities.For example, we may disclose your
                protected health information, to medical school students that see patients at our office.In addition, we
                may use a sign -in sheet at the registration desk where you will be asked to sign your name and indicate
                your healthcare provider(i.e.specialist).We may also call you by name in the waiting room when your
                provider is ready to see you.We may use or disclose your protected health information, as necessary,
                to contact you to remind you of your appointment.</p>
            <p>We may use or disclose your protected health information in certain situations without your authorization.These situations include:</p>
            <p>as required by law, as required by law to address public health issues, in conjunction with health
                oversight of communicable diseases, pursuant to an investigation of abuse or neglect, as required by
                the Food and Drug Administration, pursuant to legal proceedings, to assist coroners, to assist funeral
                directors, pursuant to organ donation & research, military activity and national security, pursuant to
                workers' compensation claims, and required disclosures related to correctional facility inmates.We are
                required to make discourses to you as required by the Secretary of the Department of Health and Human Services to investigate or determine our compliance with the requirement of Sections 164.500.</p>
            <p>We will share your protected health information with third party ‘’business associates” that perform various activities (e.g., billing, transcription services) for the practice.Whenever an arrangement between
                our office and a business associated involves the use or disclosure of your protected health information, we will have a written contract that contains terms and procedures that will protect the privacy of
                your protected health information.</p>
            <p>We may use or disclose your protected health information as necessary to provide you with information
                about treatment alternatives or other health - related benefits and services that may be of interest to
                you.We may also use and disclose your protected health information for other marketing activities.For
                example, your name and address may be used to send you information about products or services that
                we believe may be beneficial to you.You may contact our Privacy Contact to request that these materials not be sent to you.<b>Other permitted and required uses and disclosures will be made only with
                    your consent, authorization or opportunity to object unless required by law.</b></p>
            <p>
                <b>You may revoke, this authorization,</b> at any time, in writing, except to the extent that your physician or
                the physician’s practice has already taken an action in reliance on the use or disclosure indicated in the
                provided authorization.
            </p>
            <p><b><u>Your Rights</u></b></p>
            <p>The following is a statement of your rights with respect to your protected health information.</p>
            <p><b><u>You have the right to inspect and copy your protected health information.</u></b> This means you may
                inspect and obtain a copy of the protected health information about you that is contained in a designated record set for as long as we maintain the protected health information.A “designated record set”
                contains medical and billing records and any other records that your physician and the practice use
                for making decisions about you.Under federal law, however, you may not inspect or copy the following records; psychotherapy notes; information compiled in reasonable anticipation of, or use in, a civil,
                criminal, or administrative action or proceeding, and protected health information that is subject to law
                that prohibits access to protected health information.</p>
            <p><b><u>You have the right to request a restriction on your protected health information.</u></b>This means you may ask us not to use or disclose any part of your protected health information for the purposes
                of treatment, payment, or healthcare operations.You may also request that any part of your protected
                health information not be disclosed to family members or friends who may be involved in your care
                or for notification purposes as described in this Notice of Privacy Practices.Your request must state
                the specific restriction requested and to whom you want the restriction to apply.Your physician is not
                required to agree to a restriction that you may request.If a physician believes it is in your best interest
                to permit use and disclosure of your protected health information, your protected health information will
                not be restricted.You then have the right to use another healthcare professional.</p>
            <p><b><u>You have the right to request to receive confidential communications from us by alternative
                means or at an alternative location.</u></b>We will accommodate reasonable requests.We may also condition this accommodation by asking you for information as to how payment will be handled or specification of an alternative address or other method of contact.We will not request an explanation for you as
                to the basis for the request.Please make this request in writing to our Privacy Contact.</p>
            <p><b><u>You have the right to receive an accounting of certain disclosures we have made, if any.of your
                protected health information.</u></b>This right applies to disclosures for purposes other than treatment,
                payment or healthcare operations as described in this Notice of Privacy Practices.</p>
            <p>We are required to abide by the terms of this Notice of Privacy Practices.We may change the terms of
                our notice at any time.The new notice will be effective for all protected health information that we maintain at this time.Upon your request, we will provide you with any revised Notice of Privacy Practices by
                calling the office and requesting that a revised copy be sent to you in the mail or asking for one at the
                time of your next appointment.</p>
            <p><b><u>Complaints</u></b></p>
            <p>You may file a complaint with us or with the Office of Civil Rights if you believe your privacy rights have
                been violated.You may file a complaint with us by notifying our privacy contact of your complaint.We
                will not retaliate against you for filing a complaint.You may obtain the address of the OCR Regional
                Manager, Denver, CO, from our privacy officer by calling 603 - 319 - 6227.</p>
            <p><b><u>Clinic Name and Phone #</u></b></p>
            <p>This notice was published and becomes effective on / or before July 13th, 2022.</p>
            <p>We are required by law to maintain the privacy of, and provide individuals with this notice of
                our legal duties and privacy practices with respect to protected health information.If you have
                any objections to this form, please ask to speak with our HIPAA Compliance Officer in
                person or by phone at 603 - 319 - 6227.</p>
            <p>Your signature on ConvenientMD’s Patient Signature Page is an acknowledgment that you have received this Notice of our Privacy Practices.</p>

        </div>
    )
}

export default HIPPA