import { useEffect } from "react";
import { useState } from "react";
import { formatSexValue, SPACING } from "..";
import Select from "react-select";
import PropTypes from 'prop-types';

const BasicInfoDropdown = ({
  htmlFor,
  mainLabelText,
  value,
  list,
  onDropdownChange,
  name,
  error,
  formId,
  isEditable,
  testId,
}) => {
  const [selectedOption, setSelectedOption] = useState();
  const [inputValue, setInputValue] = useState("");
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const optionList = list.map((item) => {
    return {
      value: item.value,
      label: name === "sex" ? formatSexValue(item.value) : (name === "state" ? `${item.code} - ${item.value}` : item.value),
    };
  });

  const changeFn = (val) => {
    setSelectedOption(val);
    onDropdownChange(val.value, name, formId);
  };

  const onInputChange = (val) => {
    const regexPattern = /[^A-Za-z\s]/g;
    setInputValue(val.replace(regexPattern, ""));
    if(name === "state"){
      if(selectedOption.label === ''){
        if(val.length === 2){
          optionList.map((ol) => {
            if(ol.label.slice(0, 2).toLowerCase().startsWith(val.toLowerCase())){
              setSelectedOption(ol);
              onDropdownChange(ol.value, name, formId);
              setInputValue(ol.value)
            } 
          })
        }
        if(val.length > 2){
          optionList.map((ol) => {
            if(ol.value.toLowerCase() === (val.toLowerCase())){
              setSelectedOption(ol);
              onDropdownChange(ol.value, name, formId);
              setInputValue(ol.value)
            } 
          })
        }
      }
      else
        setSelectedOption({value: value, label: value})
    }
  };

  const filterOption = (candidate, input) => {
    if(input.length <= 2)
      return candidate.data.__isNew__ || candidate.label.slice(0, 2).toLowerCase().startsWith(input.toLowerCase());
    return candidate.data.__isNew__ || candidate.value.toLowerCase().startsWith(input.toLowerCase());
  };

  useEffect(() => {
    setSelectedOption({
      value: value,
      label: name === "sex" ? formatSexValue(value) : value,
    });
  }, [value, name]);

  return (
    <div className="radio-section">
      <label
        htmlFor={htmlFor}
        className="label-text"
        style={{ marginBottom: `${SPACING.formLabelLast.bottom}%` }}
      >
        {mainLabelText}
      </label>

      <div className="select_wrap basic-info-input-accent" data-testid={testId}>
        <Select
          isDisabled={!isEditable}
          onFocus={() => {setInputValue(''); setSelectedOption({value: '', label: ''}); setMenuIsOpen(true)}}
          onBlur={() => {setSelectedOption({
            value: value,
            label: name === "sex" ? formatSexValue(value) : value,
          }); setMenuIsOpen(false)}}
          onInputChange={onInputChange}
          onChange={changeFn}
          blurInputOnSelect
          options={optionList}
          value={selectedOption}
          menuIsOpen={menuIsOpen}
          placeholder=""
          inputValue={inputValue}
          filterOption={filterOption}
          theme={(theme) => ({
            ...theme,
            borderRadius: "5px",
            colors: {
              ...theme.colors,
              primary25: "rgb(254, 180, 43)",
              primary: "white",
            },
          })}
          components={{
            IndicatorSeparator: () => null,
          }}
          styles={{
            dropdownIndicator: (provided, state) => ({
              ...provided,
              transform: state.selectProps.menuIsOpen && "scaleY(-1)",
              transition: "all 300ms ease",
            }),
            option: (provided) => ({
              ...provided,
              color: "black",
              "&:hover": {
                background: "rgb(254, 180, 43)",
              },
            }),
            control: (provided) => ({
              ...provided,
              backgroundColor: "#fff",
            }),
            singleValue: (provided) => ({
              ...provided,
              color: "black"
            })
          }}
        />
      </div>
      <div id={`${formId}-${name}Err`} className="pt-2 text-danger">
        {error && error.length > 0 && (<div>{error[0]}</div>)}
      </div>
    </div>
  );
};

BasicInfoDropdown.propTypes = {
  htmlFor: PropTypes.string,
  mainLabelText: PropTypes.string,
  value: PropTypes.string,
  list: PropTypes.array,
  onDropdownChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.array,
  formId: PropTypes.string,
  isEditable: PropTypes.bool,
  testId: PropTypes.string,
};

export default BasicInfoDropdown;
