import { auth } from "../actions/types";

const initialState = {
  token: localStorage.getItem("token") || null,
  isAuthenticated: null,
  loading: null,
  user: null,
  errors: [],
  captchaToken:null,
  captchaError:null
};

const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case auth.LOGIN_FAIL:
    case auth.REGISTER_FAIL:
    case auth.AUTH_ERROR:
    case auth.LOGOUT:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        errors: payload
      }
    case auth.REGISTER_SUCCESS:
    case auth.LOGIN_SUCCESS:
    case auth.USER_LOADED:
      localStorage.setItem('token', payload.token);
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false,
        errors: []
      };
    case auth.SET_CAPTCHA_TOKEN:
       return{...state,captchaToken:payload}
    case auth.SET_CAPTCHA_ERROR:
       return{...state,captchaError:payload}
    case auth.CLEAR_AUTH_DATA: {
        return initialState;
      }
    default:
      return state;
  }
}

export default authReducer;