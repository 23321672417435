import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Footer from "../../components/footer/Footer";
import ProgressBar from "../../components/progressBar/ProgressBar";
import PageHeading from '../../components/pageHeader/PageHeading';
import ConsentToTreat from "./SignedDocuments/ConsentToTreat";
import HIPPA from "./SignedDocuments/HIPPA";
import PatientBillOfRights from "./SignedDocuments/PatientBillOfRights";
import PrivacyPolicy from "./SignedDocuments/PrivacyPolicy";
import SelfPayPolicy from "./SignedDocuments/SelfPayPolicy";
import PrimaryPatientBlock from './components/PrimaryPatientBlock';
import PatientBlock from './components/PatientBlock';
import ValidationText from './components/ValidationText';
import './SignDocument.css';

import { DEPENDANT, PRIMARY, SPOUSE } from '../../constants';

export const defineInputValuesChecked = (signDocument, inputValueType) => {
  if(signDocument === undefined) {
    return false 
  }

  const inputValuesLength = Object.keys(signDocument).length;

  if(inputValuesLength === 0) {
    return false 
  }

  if(inputValuesLength < 5 && inputValueType === 'policy') {
    return false 
  }

  switch (inputValueType) {
    case 'hippa':
      return signDocument[0].checked;
    case 'rights':
      return signDocument[1].checked;
    case 'consent':
      return signDocument[2].checked;
    case 'privacy':
      return signDocument[3].checked;
    default:
      return signDocument[4].checked;
    }
  }

export const unCheckValidate = () => {
  const checkboxValues = document.getElementsByClassName("form-check-input");
  for (let props in checkboxValues) {
    if(checkboxValues[props].checked === false){
      return true;
      }
  }
}

const  SignDocument = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const insuranceType = params.get("type");

  const routingPath = {
    next: '/confirmEnrollment',
    back: '/practitioners'
  }

  // eslint-disable-next-line
  const [accordion, setAccordionRef] = useState();

  const patientInfo = useSelector(state => state.patientInfo);

  const primary = [{ 
    'type': 'Primary',
    'key': "Primary",
    'selectAll': false,
    id: `${patientInfo.primaryDetails[0]?.basicInfo.id}`, 
    name: `${patientInfo.primaryDetails[0]?.basicInfo.firstName} ${patientInfo.primaryDetails[0].basicInfo.lastName}`,
    'inputValues': [
          {
              'type': 'hippa',
              'checked': defineInputValuesChecked(patientInfo.primaryDetails[0].signDocument, 'hippa'),
              'toggleiframe': false,
              'text': 'HIPPA',
              'Itemid': 1,
              'iframeFlag': false,
              'iframe':  <HIPPA />
          },
          {
              'type': 'rights',
              'checked': defineInputValuesChecked(patientInfo.primaryDetails[0].signDocument, 'rights'),
              'text': 'Patient Bill of Rights',
              'Itemid': 2,
              'iframeFlag': false,
              'iframe': <PatientBillOfRights />
          },
          {
              'type': 'policy',
              'checked': defineInputValuesChecked(patientInfo.primaryDetails[0].signDocument, 'policy'),
              'text': 'Self Pay Policy',
              'Itemid': 3,
              'iframeFlag': false,
              'iframe': <SelfPayPolicy />
          },
          {
              'type': 'consent',
              'checked': defineInputValuesChecked(patientInfo.primaryDetails[0].signDocument, 'consent'),
              'text': 'Consent to Treat',
              'Itemid': 4,
              'iframeFlag': false,
              'iframe': <ConsentToTreat />
          },
          {
              'type': 'privacy',
              'checked': defineInputValuesChecked(patientInfo.primaryDetails[0].signDocument, 'privacy'),
              'toggleiframe': false,
              'text': 'Privacy Policy',
              'Itemid': 5,
              'iframeFlag': false,
              'iframe': <PrivacyPolicy />
          },
          
      ],
      'medicareFrame' : {
        'type': 'alignmentForm',
        'toggleiframe': false,
        'text': 'Medicare Voluntary Alignment Form',
        'iframeFlag': false,
        'iframe':    <iframe
        id="JotFormIFrame-223190764216050"
        title="CMS"
        onLoad="window.parent.scrollTo(0,0)"
        allowtransparency="true"
        allowFullScreen="true"
        allow="geolocation; microphone; camera"
        src="https://form.jotform.com/223190764216050"
        frameBorder="0"
        style={{minWidth: "100%", height: "539px", border:"none",  scrolling:"no"}}
      >
      </iframe>
    }
  }];

  let dependents = [];

  if(patientInfo.dependentDetails !== null) {
    for (let i = 0; i < patientInfo.dependentDetails.length; i++) {
      const dependant = patientInfo.dependentDetails[i];
      let inputValues = [
        {
          'type': 'hippa',
          'checked': defineInputValuesChecked(dependant.signDocument, 'hippa'),
          'toggleiframe': false,
          'text': 'HIPPA',
          'Itemid': 1,
          'iframeFlag': false,
          'iframe':  <HIPPA />
        },
        {
          'type': 'rights',
          'checked': defineInputValuesChecked(dependant.signDocument, 'rights'),
          'text': 'Patient Bill of Rights',
          'Itemid': 2,
          'iframeFlag': false,
          'iframe': <PatientBillOfRights />
        },
        {
        'type': 'policy',
        'checked': defineInputValuesChecked(dependant.signDocument, 'policy'),
        'toggleiframe': false,
        'text': 'Self Pay Policy',
        'Itemid': 3,
        'iframeFlag': false,
        'iframe': <SelfPayPolicy />
        }, 
        {
          'type': 'consent',
          'checked': defineInputValuesChecked(dependant.signDocument, 'consent'),
          'text': 'Consent to Treat',
          'Itemid': 4,
          'iframeFlag': false,
          'iframe': <ConsentToTreat />
        },
        {
          'type': 'privacy',
          'checked': defineInputValuesChecked(dependant.signDocument, 'privacy'),
          'toggleiframe': false,
          'text': 'Privacy Policy',
          'Itemid': 5,
          'iframeFlag': false,
          'iframe': <PrivacyPolicy />
        }
      ];

      if(insuranceType !== 'selfpay') { 
        inputValues.splice(2, 1); 
      }

      dependents.push({
        id: `${dependant.basicInfo.id}`, 
        name: `${dependant.basicInfo.firstName} ${dependant.basicInfo.lastName}`,
        relationship: dependant.basicInfo.status,
        status: dependant.basicInfo.status,
        'type': dependant.basicInfo.status,
        key: i + 1,
        'selectAll': false,
        inputValues
      })
    }
  }

  // Remove Self Pay element from object if user is not self-pay
  if(insuranceType !== 'selfpay') { primary[0].inputValues.splice(2, 1); }

  const [validationVar, setValidationVar] = useState(true);
  const [personDetails, setPersonDetails] = useState(primary);
  const [dependentDetails, setDependentDetails] = useState(dependents);

  const handleCallback = (ref) => setAccordionRef(ref);

  const updateDetailsAcknArray = (personType, mainId) => {
     // select all - inside every patient
    const newState = personType.map(eachPerson => {
      if (mainId === eachPerson.id) {
        if (!eachPerson.inputValues.every(item => item.checked)) {
          eachPerson.inputValues.map((item) => { 
            return item.checked = true; 
          });
        } else { eachPerson.inputValues.map((item) => { 
          return item.checked = false;
         }); }
        eachPerson.selectAll = !eachPerson.selectAll;
      }
      return eachPerson;
    });
    
    if(mainId === PRIMARY) {
      const inputValuesPrimary = (personDetails.map(pd => pd.inputValues));
      setPersonDetails(newState)
      dispatch({ type: "ADD_SIGN_DOCUMENT_PRIMARY", payload: inputValuesPrimary });
    }

    if (mainId === SPOUSE) {
      const inputValuesSpouse = dependents.find(d => d.id === SPOUSE).inputValues.map(iv => ({ ...iv, checked: !iv.checked }));
      setDependentDetails([...dependentDetails], newState)
      dispatch({ type: "ADD_SIGN_DOCUMENT_DEPENDENT", payload: { inputValuesDependents: [inputValuesSpouse], mainId: SPOUSE } });
    }

    if(mainId.includes(DEPENDANT)){
      const inputValuesDependents = (dependentDetails.map(dependant => dependant.inputValues ));
      setDependentDetails([...dependentDetails], newState)
      dispatch({ type: "ADD_SIGN_DOCUMENT_DEPENDENT", payload: { inputValuesDependents, mainId } });
    }  
  }

  const updateDetailsArray = (e, personType, currentitem, mainId) => {
    // update checkbox inside every item of every patient
   const newState = personType.map(eachPerson => {
     if (mainId === eachPerson.id) {
       eachPerson.inputValues.map(item => {
         if (item.Itemid === currentitem.Itemid) {
           return item.checked = !item.checked;
         }
         return item;
       });
     }
     return eachPerson;
   });

   if(mainId === PRIMARY) {
     const inputValuesPrimary = (personDetails.map(pd => pd.inputValues));
     setPersonDetails(newState)
     dispatch({ type: "ADD_SIGN_DOCUMENT_PRIMARY", payload: inputValuesPrimary });
   }

   if (mainId === SPOUSE) {
     const inputValuesSpouse = newState.find(d => d.id === SPOUSE);
     setDependentDetails([...dependentDetails], newState);
     dispatch({ type: "ADD_SIGN_DOCUMENT_DEPENDENT", payload: { inputValuesDependents: [inputValuesSpouse.inputValues], mainId: SPOUSE } });
   }

   if(mainId.includes(DEPENDANT)){
     const inputValuesDependents = (dependentDetails.map(dependant => dependant.inputValues ));
     setDependentDetails([...dependentDetails], newState)
     dispatch({ type: "ADD_SIGN_DOCUMENT_DEPENDENT", payload: { inputValuesDependents, mainId } });
   }  
 }

  const iframeDropdown = (personType, currentitem, mainId) => {
      // display Iframe (description) inside every item of every patient
    const newState = personType.map(eachPerson => {
      if (mainId === eachPerson.id) {
        eachPerson.inputValues.map(item => {
          if (item.Itemid === currentitem.Itemid) {
            return item.iframeFlag = !item.iframeFlag;
          }
          return item;
        });
      }
      return eachPerson;
    });

    updateStateAfterIframeToggle(newState, mainId);
  }

  const updateStateAfterIframeToggle = (newState, mainId) => {
    if(mainId === PRIMARY) { 
      setPersonDetails(newState);
    }

    if(mainId === SPOUSE || mainId.includes(DEPENDANT))  {
      setDependentDetails([...dependentDetails], newState)
    }
  }

  return (
    <>
      <div className='sign-root'>
        <ProgressBar currentPage="signdocument"/>
        <div className='signDocPageDes'>
          <PageHeading title={t('signDocument.heading')} description={t('signDocument.subheading')} />
        </div>
        <div className='accordion-container' data-testid="accordionContainer-test">
          <PrimaryPatientBlock  
            mainItem={personDetails[0]}
            updateDetailsAcknArray={updateDetailsAcknArray}
            personDetails={personDetails}
            iframeDropdown={iframeDropdown}
            updateDetailsArray={updateDetailsArray}
            handleCallback={handleCallback}
          />

          {dependentDetails.length > 0 && 
            <>
              {dependentDetails.map((mainItem) => 
                <PatientBlock 
                  mainItem={mainItem}
                  key={mainItem.id} 
                  updateDetailsAcknArray={updateDetailsAcknArray}
                  personDetails={dependentDetails}
                  iframeDropdown={iframeDropdown}
                  updateDetailsArray={updateDetailsArray}
                  handleCallback={handleCallback}
                  index={mainItem.id}
                />                        
              )}
            </>      
          }
          <ValidationText displayText={!validationVar}/>
        </div>
      </div>
      <div className='footer-section'>
        <Footer
          routingPath={routingPath}
          unCheckValidate={unCheckValidate}
          currentPage='signDocumentPage'
          validationVar={validationVar}
          setValidationVar={setValidationVar}
        />
      </div>
    </>
  )
}

export default SignDocument;