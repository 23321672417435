import * as React from 'react';
import './snackMessage.css';
import PropTypes from 'prop-types';

export const SnackMessage = ({snackMessage, show, clearMsg}) => {
    return (
        show && <div className="snack-msg">
            <span>{snackMessage}</span>
            <span className="pointer" onClick={clearMsg}>&times;</span>
        </div>
    );
};

SnackMessage.propTypes = {
    snackMessage: PropTypes.string,
    show: PropTypes.bool,
    clearMsg: PropTypes.func
};