export const UPDATE_NEXT_GEN_RESPONSE = "UPDATE_NEXT_GEN_RESPONSE";
export const ADD_PRIMARY_ACCOUNT_HOLDER = "ADD_PRIMARY_ACCOUNT_HOLDER";
export const UPDATE_PRIMARY_ACCOUNT_HOLDER = "UPDATE_PRIMARY_ACCOUNT_HOLDER";
export const ADD_SPOUSE="ADD_SPOUSE";
export const UPDATE_SPOUSE="UPDATE_SPOUSE";
export const ADD_DEPENDENT="ADD_DEPENDENT";
export const UPDATE_DEPENDENT="UPDATE_DEPENDENT";
export const REMOVE_MEMBER = "REMOVE_MEMBER";
export const UPDATE_INSURANCE_DETAILS_PRIMARY="UPDATE_INSURANCE_DETAILS_PRIMARY";
export const UPDATE_INSURANCE_DETAILS_SECONDARY="UPDATE_INSURANCE_DETAILS_SECONDARY";
export const UPDATE_INSURANCE_DETAILS_DEPENDENT="UPDATE_INSURANCE_DETAILS_DEPENDENT";
export const ADD_PRACTITIONER_PRIMARY="ADD_PRACTITIONER_PRIMARY";
export const ADD_PRACTITIONER_SECONDARY="ADD_PRACTITIONER_SECONDARY";
export const ADD_PRACTITIONER_DEPENDENT="ADD_PRACTITIONER_DEPENDENT";
export const ADD_SIGN_DOCUMENT_PRIMARY="ADD_SIGN_DOCUMENT_PRIMARY";
export const ADD_SIGN_DOCUMENT_SPOUSE="ADD_SIGN_DOCUMENT_SPOUSE";
export const ADD_SIGN_DOCUMENT_DEPENDENT="ADD_SIGN_DOCUMENT_DEPENDENT";
export const UPDATE_PATIENT_IDS = "UPDATE_PATIENT_IDS";
export const PASS_RESOURCES = "PASS_RESOURCES";
export const CLEAR_ALL_DATA = "CLEAR_ALL_DATA";
export const SAVE_ALL_PATIENT_INFO = "SAVE_ALL_PATIENT_INFO";
export const PATIENT_INFO_DIRECT_CHANGE = "PATIENT_INFO_DIRECT_CHANGE";
export const SET_ALL_PATIENT="SET_ALL_PATIENT";
export const SET_CMD_OFFICE="SET_CMD_OFFICE";
export const ADD_DEPENDENT_ADDITIONAL_DETAILS="ADD_DEPENDENT_ADDITIONAL_DETAILS"