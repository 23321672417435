
import { SITE_KEY } from "../constants/captcha";
import { CAPTCHA_API } from "../constants/urlEndpoint";

export const captcha = (fnFunction) => {
  if (window.grecaptcha) {
    window.grecaptcha.ready(() => {
        window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
          return fnFunction(token);
        });
      });
  }else{
    loadScriptByURL("recaptcha-key", `${CAPTCHA_API}${SITE_KEY}`, function () {
        console.log("Script loaded!");
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
              return fnFunction(token);
            });
          });
      });
    
  }
};

export const loadScriptByURL = (id, url, callback) => {
    const isScriptExist = document.getElementById(id);
 
    if (!isScriptExist) {
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.src = url;
      script.id = id;
      script.onload = function () {
        if (callback) callback();
      };
      document.body.appendChild(script);
    }
 
    if (isScriptExist && callback) callback();
  }
 
  // load the script by passing the URL
 
