import { useTranslation } from 'react-i18next';
import './success.css';
import { useLocation, useNavigate } from "react-router-dom";

function Error() {
    const location=useLocation();
    const params = new URLSearchParams(location.search);
    const errorType = params.get("errorCode");
    let navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div>
            <div className='container-xl'>
            {
                (
                errorType === 'AppNG_1500' ||
                errorType === 'AppNG_1501' ||
                errorType === 'AppNG_1502' ||
                errorType === 'AppNG_1503' ||
                errorType === 'AppNG_1504'
                ) ? 
                <div className='errorPopup'>
                    <div className='card-heading'>{t('ErrorPopup.heading')}</div>
                    <div className="success-card-text">
                            <span> 
                                { t('ErrorPopup.headingSuccessWithError') }
                            </span>
                    </div>
                </div>
                :
                <div className='errorPopup'>
                    <div className='card-heading'>{t('ErrorPopup.heading')}</div>
                    <button className='existing-card-button'  onClick={() => navigate(-1)}>
                        {t('ErrorPopup.btnContent')}
                    </button>
                </div>
            }

            </div>
            {/* <Footer routingPath={routingPath} currentPage="existingMember" /> */}
        </div>
    );
}

export default Error;

//headingSuccessWithError