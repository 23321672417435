import HeaderLogo from "../../assets/images/header.png";
import './Header.css';

function Header(){
    return(
        <div className="header-section">
            <img src={HeaderLogo} alt="logo" width={228} height={61} />
        </div>
    );
}

export default Header;