import MemberShipInformationBody from "./MembershipInformationBody";

const  MembershipInformation = () => {
    return (
        <div>
            <MemberShipInformationBody />
        </div>
    )
}

export default MembershipInformation;