import { SPACING } from "..";
import PropTypes from 'prop-types';

const RelationshipDropdown = ({
  htmlFor,
  mainLabelText,
  value,
  list,
  onDropdownChange,
  name,
  id,
  valPlaceholder,
  form
}) => {
  return (
    <div className="radio-section">
      <label
        htmlFor={htmlFor}
        className="label-text"
        style={{ marginBottom: `${SPACING.formLabelLast.bottom}%` }}
      >
        {mainLabelText}
      </label>

      <div className="select_wrap">
        <select
            className="form-control details-input basic-info-input-accent relationship-dropdown"
            id={id}
            value={value}
            name={name}
            htmlFor={htmlFor}
            placeholder={valPlaceholder}
            data-testid="relationshipDropdown-test"
            onChange={e => onDropdownChange(e.target.value, form)}
        >
            {list.map(rel => (
                <option key={rel.id} value={rel.status}>{rel.value}</option>
            ))}
        </select>
      </div>
    </div>
  );
};

RelationshipDropdown.propTypes = {
  htmlFor: PropTypes.string,
  mainLabelText: PropTypes.string,
  value: PropTypes.string,
  list: PropTypes.array,
  onDropdownChange: PropTypes.func,
  name: PropTypes.string,
  id: PropTypes.string,
  valPlaceholder: PropTypes.string,
  form: PropTypes.object,
};

export default RelationshipDropdown;

