import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import {
  Header,
} from './components';
import {
  BasicInfo,
  InsurancePage,
  LandingPage,
  PractitionersPage,
  ExistingMember,
  Error,
  SuccessEnrollment,
  SignDocument,
  SinglePractitioner,
  ConfirmEnrollment,
  MembershipInformation,
  PaymentsPage
} from './containers';
import { setAuthToken } from './utils';
import { useSelector } from 'react-redux';
import { BasicInfoProvider } from './contexts';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBLISHABLE_KEY } from './constants/stripe';
import NewLandingPage from './containers/landing-page/NewLandingPage';
if (localStorage.token) setAuthToken(localStorage.token);

function App() {
  const data = useSelector(state => state.patientInfo);
  const params = new URLSearchParams(window.location.search);
  const insuranceType = params.get('type') ?? 'anthem';
  const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

  const getAge = (dateString) => {
    let today = new Date();
    let birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    let month = today.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const adultDependents = () => {
    let adultDependentsLength = 0;
    if (data.dependentDetails) {
      for (const depenendent of data.dependentDetails) {
        const age = getAge(depenendent.basicInfo.dob)
        if (age >= 18) adultDependentsLength++;
      }
      return adultDependentsLength;
    }
  }

  const childDependents = () => {
    let childDependentsLength = 0;
    if (data.dependentDetails) {
      for (const depenendent of data.dependentDetails) {
        const age = getAge(depenendent.basicInfo.dob)
        if (age < 18) childDependentsLength++;
      }
      return childDependentsLength;
    }
  }

  return (
    <div className="App">
      <Header />
      <BasicInfoProvider>
        <Router>
          <Routes>

            <Route path='/' element={<NewLandingPage />}/>
            <Route exact path="/basic-info" element={<BasicInfo />} />
            <Route exact path="/insurance" element={
              data.primaryDetails[0].basicInfo.firstName ?
                <InsurancePage /> : <Navigate to='/basic-info' />}
            />
            <Route exact path="/practitioners" element={
              (insuranceType === "selfpay" || insuranceType?.includes("Admin_") || insuranceType?.includes("third-party_")) ? (
                data.primaryDetails[0].basicInfo.firstName ?
                  <PractitionersPage /> :
                  <Navigate to='/basic-info' />
              ) : (
                data.primaryDetails[0]?.insurance?.memberId ?
                  <PractitionersPage /> : <Navigate to='/insurance' />
              )}
            />
            <Route exact path="/practitioners/:id" element={<SinglePractitioner />} />
            <Route exact path="/signdocument" element={data.primaryDetails[0]?.careProvider?.location ? <SignDocument /> : <Navigate to='/practitioners' />} />
            <Route exact path="/existingUser" element={<ExistingMember />} />
            <Route exact path="/error" element={<Error />} />
            <Route exact path="/successEnrollmentCall" element={<SuccessEnrollment />} />
            <Route exact path="/confirmEnrollment" element={data.primaryDetails[0].signDocument !== null && data.primaryDetails[0].signDocument ? <ConfirmEnrollment /> : <Navigate to='/signdocument' />} />
            <Route exact path="/payments" element={(insuranceType === "selfpay") ?
              <Elements stripe={stripePromise}>
                <PaymentsPage
                  email={data.primaryDetails[0].basicInfo.email}
                  adultQty={data.primaryDetails.length + data.spouseDetails.length + adultDependents()}
                  dependentQty={childDependents()}
                />
              </Elements>
              : <Navigate to='/confirmEnrollment' />
            }
            />
            <Route exact path="/membershipInformation" element={(data.primaryDetails[0].basicInfo.firstName &&
              data.primaryDetails[0].careProvider.location &&
              data.primaryDetails[0].signDocument) ? <MembershipInformation /> : <Navigate to='/confirmEnrollment' />} />

            <Route exact path="/successEnrollment" element={(data.primaryDetails[0].basicInfo.firstName &&
              data.primaryDetails[0]?.careProvider?.location &&
              data.primaryDetails[0]?.signDocument) ? <SuccessEnrollment /> : <Navigate to='/membershipInformation' />} />
            <Route path="*" element={<Navigate to="/error" replace />} />
          </Routes>
        </Router>
      </BasicInfoProvider>
    </div>
  );
}

export default App;
