import { ApiCall, httpReq } from "./ApiCall";
import setAuthToken from './setAuthToken';
import { captcha, loadScriptByURL } from "./captcha";
import { formatPatientPayload } from "./formatPatientPayload";
import { getAge } from "./date";
export { ApiCall, httpReq, setAuthToken, captcha, loadScriptByURL, formatPatientPayload, getAge };
export const GET = "GET";
export const PUT = "PUT";
export const POST = "POST";
export const DELETE = "DELETE";
export const PATCH = "PATCH";

export const formatDate = (str) => {
    const [year, month, date] = str.split('-').map(x => parseInt(x));
    return `${month}/${date}/${year}`
}