import { doesFormExist, formTypes, generateRequestBody, initForm, SPACING, spinNewDepId } from '.';
import ProgressBar from '../../components/progressBar/ProgressBar';
import './PatientInformation.css';
import { useState, useMemo, useEffect } from "react";
import { useContext } from 'react';
import { useTranslation } from "react-i18next";
import { CURRENT, DEPENDANT, PRIMARY, SPOUSE } from "../../constants";
import { useLocalStorage, useQuery } from '../../hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect, useSelector } from "react-redux"
import { setPatientInfo, updatePatientIds, passResources, setCmdOffice } from '../../actions/patientInfo';
import { ApiCall, PUT, GET, POST } from '../../utils';
import { GET_GENDER_RACE_ETHNICITY_LANGUAGE_URL, BASE_URL_PATIENT, DUPLICATE_DEPENDENT_ERROR_MESSAGE, GET_LOCATION_API } from '../../constants';
import { BasicInfoContext } from '../../contexts';
import BasicInfoForm from "./BasicInfoForm";
import { Spinner } from 'react-bootstrap';
import parse from 'html-react-parser';
import { setCaptchaToken, setCaptchaError } from '../../actions/auth';
import PropTypes from 'prop-types';
const routingPath = { next: '/insurance', back: '/' };

function PatientInformation({ setPatientInfo, updatePatientIds, passResources, setCaptchaToken, setCaptchaError, setCmdOffice }) {
    const { checkAll, clearAllErrors, formState, formDispatch, setReminder, isEveryFormSaved } = useContext(BasicInfoContext);
    const hideCurrentForm = () => formDispatch({ type: formTypes.SET_CURRENT_FORM, payload: false });
    const navigate = useNavigate();
    const { t } = useTranslation();
    const location = useLocation();
    const insuranceType = useQuery().get("type");
    const cmdOffice = useSelector((x => x.patientInfo.cmdOffice));
    const resource = useSelector((x => x.patientInfo.resources));
    const [resourceType, setResourceType] = useState(() => resource.length > 0 ? resource : null);
    /** uncommnet captchaToken , once captcha is configured in secret manager */
    // eslint-disable-next-line
    const [_, setProgress, removeProgress] = useLocalStorage('progressbar');
    const primary = useSelector((x => x.patientInfo.primaryDetails[0]?.basicInfo));
    const spouse = useSelector((x => x.patientInfo.dependentDetails.find(de => de?.basicInfo?.id === SPOUSE || de?.basicInfo?.relationship === SPOUSE || de?.basicInfo?.status === SPOUSE)));
    const dependants = useSelector((x => x.patientInfo.dependentDetails));
    const [shadowForm, setShadowForm] = useState(() => initForm);
    const [apiErrorMessage, setApiErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [cmdOffices, setCmdOffices] = useState(cmdOffice);
    useEffect(() => {
        if (location?.state?.from) hideCurrentForm();
        if (!doesFormExist(formState.current)) clearAllErrors();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (location?.state?.from) hideCurrentForm();
        // eslint-disable-next-line
    }, []);
    const inline = useMemo(() => (Object.keys(primary).length > 0 && primary) || spouse || dependants.length > 0 ? true : false, [primary, spouse, dependants]);
    const slist = resourceType?.length > 0 ? resourceType[1]?.values : [];
    const elist = resourceType?.length > 0 ? resourceType[2]?.values : [];
    const rlist = resourceType?.length > 0 ? resourceType[0]?.values : [];
    const llist = resourceType?.length > 0 ? resourceType[3]?.values : [];

    const nextClick = async () => {
        const { hasErrors } = checkAll(formState.showCurrentForm, insuranceType);
        // shadow form only tracks the main instance of data input
        // in other cases the default values (primary, spouse, dependents - dependent[basicInfo])
        if (hasErrors) return;
        const allFormsSaved = insuranceType !== 'medicare' ? isEveryFormSaved() : true;
        if (!allFormsSaved) { setReminder(true); return; }
        const URL_CONDITION = ((Object.keys(primary).length === 0 && Object.keys(shadowForm).length !== 0) || Object.keys(primary).length !== 0) || dependants.length > 0 || Object.keys(shadowForm).length !== 0;
        let requestBody = {};
        if (formState.showCurrentForm) {
            hideCurrentForm();
            const rs = formState.current.relationship;
            const currentDepIds = dependants.map(d => d.basicInfo.id);
            const newId = spinNewDepId(false, `${DEPENDANT}-${dependants.length}`, currentDepIds);
            await setPatientInfo({ ...formState.current, id: rs.includes(PRIMARY) ? PRIMARY : rs.includes(SPOUSE) ? SPOUSE : rs.includes(DEPENDANT) ? newId : CURRENT });
        }
        const newProgress = parseInt(localStorage.getItem('progressbar')) - 1 < 0 ? 3 : parseInt(localStorage.getItem('progressbar')) + 1;
        removeProgress();
        setProgress(newProgress.toString());
        await clearAllErrors();
        ///////// api call ////////
        if (Object.keys(primary).length === 0 && Object.keys(shadowForm).length !== 0 && shadowForm?.relationship === '')
            requestBody = generateRequestBody(shadowForm, PRIMARY, insuranceType, false, primary, slist, elist, rlist, llist);
        else if (Object.keys(primary).length !== 0) {
            const primId = localStorage.getItem("RES_FOR_PATIENT_POST") === null ? '' : JSON.parse(localStorage.getItem("RES_FOR_PATIENT_POST"))?.patientId;
            requestBody = generateRequestBody({ ...primary, patientId: primId }, PRIMARY, insuranceType, true, primary, slist, elist, rlist, llist);
        }
        if (insuranceType !== "medicare") {
            if (dependants.length > 0) {
                dependants.forEach(element => {
                    const requestDependent = generateRequestBody(element.basicInfo, element.basicInfo.status, insuranceType, false, primary, slist, elist, rlist, llist);
                    requestBody.dependents.push({
                        "dependentType": DEPENDANT,
                        details: requestDependent
                    })
                });
            }
        }
        if (shadowForm.relationship !== "" && formState.showCurrentForm) {
            const shadowFormReqBody = generateRequestBody(shadowForm, shadowForm.relationship, insuranceType, false, primary, slist, elist, rlist, llist);
            requestBody.dependents.push({
                "dependentType": shadowForm.relationship,
                details: shadowFormReqBody
            })
        }

        if (URL_CONDITION) {
            const api_url = localStorage.getItem("RES_FOR_PATIENT_POST") === null ? BASE_URL_PATIENT : `${BASE_URL_PATIENT}1`;
            const method = localStorage.getItem("RES_FOR_PATIENT_POST") === null ? POST : PUT;
            setIsLoading(true);
            ApiCall(api_url, requestBody, method, null, async function (response) {
                const depsIds = dependants.map(dep => dep.basicInfo.id);
                const depsGenIds = (requestBody?.dependents && requestBody?.dependents.length > 0) ? requestBody?.dependents.map(dep => dep.details.patientType) : [];
                const allPeopleIds = [primary.id, spouse?.id, ...depsIds];
                const generatedIds = [requestBody.patientType, spouse && SPOUSE, ...depsGenIds];
                await updatePatientIds({ ids: allPeopleIds, patientIds: generatedIds });

                if (response.status.toString().startsWith('20')) {
                    localStorage.setItem("RES_FOR_PATIENT_POST", JSON.stringify(response.data));
                    if (localStorage.getItem("RES_FOR_PATIENT_POST") === null) return navigate("/error");
                    if (insuranceType === "medicare") navigate({ pathname: routingPath.next, search: '?type=medicare' });
                    else if (insuranceType === "selfpay" || insuranceType?.includes("Admin_") || insuranceType?.includes("third-party_")) navigate({ pathname: "/practitioners", search: `?type=${insuranceType}`, });
                    // else if (insuranceType?.includes("third-party_") && localStorage.getItem("hasInsurance") === "true" || insuranceType?.includes("third-party_")) navigate('/insurance?type=' + insuranceType);
                    else navigate('/insurance?type=anthem');
                } else if (response.status.toString() === '409') {
                    if (response.data?.errorCode === "AppDB_1003") setApiErrorMessage(DUPLICATE_DEPENDENT_ERROR_MESSAGE);
                    else if (response.data?.errorCode === "NG_1002") {
                        let name = response.data.message.split(":")[1].replace(",", "");
                        let message = t('ErrorMessageInBanner.dependentAlreadyPresent1') + "<b> " + name + "</b> " + t('ErrorMessageInBanner.dependentAlreadyPresent2');
                        setApiErrorMessage(message);
                    }
                } else if (response.status.toString() === '417') {
                    if (response.data?.errorCode === "AppCMD_1002") {
                        let name = response.data.message.split(":")[2].replace(",", "");
                        let message = t('ErrorMessageInBanner.dependentAlreadyPresent1') + "<b> " + name + "</b> " + t('ErrorMessageInBanner.dependentAlreadyPresent2');
                        setApiErrorMessage(message);
                        // navigate(`/existingUser?type=${insuranceType}`)
                    }
                }
                else navigate('/error');
                setIsLoading(false);
            });
        }
    };


    const getRaceGenderEthnicitylanguage = () => {
        if (resourceType === null) {
            ApiCall(GET_GENDER_RACE_ETHNICITY_LANGUAGE_URL, null, GET, null, async function (response) {
                if (response.status === 200) {
                    setResourceType(response.data.resources);
                    await passResources(response.data.resources);
                }
            });

        }

    }

    const getCmdLocation = () => {
        if (cmdOffices.length === 0) {
            ApiCall(GET_LOCATION_API, null, GET, null, async function (response) {
                if (response.status === 200) {
                    let locationFilter = response.data.cmdLocations.filter(function (object) {
                        return (object.providers.length > 0)
                    })
                    setCmdOffices(locationFilter);
                    await setCmdOffice(locationFilter)
                }
            });
        }
    }

    const showErrorMessage = (errorMessage) => {
        setApiErrorMessage(errorMessage);
    }

    // eslint-disable-next-line
    useEffect(() => { getRaceGenderEthnicitylanguage(); getCmdLocation() }, []);

    return (
        <div>

            {isLoading &&
                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <Spinner style={{ color: "#264E5A", width: "5rem", height: "5rem", borderWidth: "0.5rem", marginTop: "70px" }} animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            }
            {
                !isLoading &&
                <>
                    <div className="basic-info-main-div container-xl">
                        <div className="basic-info-progress-container">
                            <ProgressBar currentPage='patient-info' />
                        </div>
                        <h1 className="app-index-h1" style={{ margin: `${SPACING.title.top}% 0 ${SPACING.title.bottom}% 0` }}>{t('basic.title')}</h1>
                        <div className="basic-info-form-section">
                            <div className="basic-info-header-section mb-4">
                                <h3 className="mt-0 me-0 ms-0 text-center mb-2">{t('basic.form.h4')}</h3>
                                <p className="text-center" style={{ margin: `${SPACING.bluetext.top}% 0 0 0` }}>{insuranceType === "medicare" ? t('basic.form.p') : t('basic.form.p')}</p>
                            </div>
                            {inline && (
                                <div className="basic-info-header-section px-0">
                                    {primary && Object.keys(primary).length > 0 && (
                                        <BasicInfoForm shadowForm={shadowForm} setShadowForm={setShadowForm} accordionID={PRIMARY} resource={resourceType} showErrorMessageCallback={(message) => showErrorMessage(message)} cmdOffice={cmdOffices} />
                                    )}
                                    {dependants.length > 0 && dependants.map((d, i) => (
                                        <BasicInfoForm shadowForm={shadowForm} setShadowForm={setShadowForm} key={i} accordionID={d.basicInfo.id} resource={resourceType} showErrorMessageCallback={(message) => showErrorMessage(message)} cmdOffice={cmdOffices} />
                                    ))}
                                </div>
                            )}
                            <BasicInfoForm setShadowForm={setShadowForm} shadowForm={shadowForm} isMainInstance resource={resourceType} showErrorMessageCallback={(message) => showErrorMessage(message)} cmdOffice={cmdOffices} />
                        </div>
                        {insuranceType === "medicare" &&
                            <div data-testid="medicaremsg-test" className="my-3 medicare-message-spouse-dependent-div" style={{ color: "#264E5A" }}>
                                <b className="medicare-message-spouse-dependent" >{t('basic.medicareMessageforSpouse')}</b>
                            </div>
                        }
                        {insuranceType !== "medicare" && (
                            <div>
                                {primary && (
                                    <div className="text-center my-3" style={{ color: "#264E5A" }}>
                                        <b>{t('basic.finished')}</b>
                                    </div>
                                )}
                            </div>
                        )}
                        {apiErrorMessage &&
                            <div className="patient-info-snack-msg patient-info-snack-msg-danger">
                                <span>{parse(apiErrorMessage)}</span>
                                <span className="pointer" onClick={() => setApiErrorMessage("")}>&times;</span>
                            </div>}
                    </div>
                    <div className="footer-section">
                        <div className="btn-section">
                            <button className="back-btn" disabled onClick={(e) => e.preventDefault()}>Back</button>
                            <button className="next-btn" onClick={() => { nextClick() }}>Next</button>
                        </div>
                        <div className="footer-phoneNum">
                            {t('footer.questions')} (603) 810-6700
                        </div>
                    </div>
                </>
            }

        </div>
    );
}


PatientInformation.propTypes = {
    setPatientInfo:PropTypes.func,
    updatePatientIds:PropTypes.func,
    passResources:PropTypes.func,
    setCaptchaToken:PropTypes.func,
    setCaptchaError:PropTypes.func,
    setCmdOffice:PropTypes.func
};

export default connect(null, { setPatientInfo, updatePatientIds, passResources, setCaptchaToken, setCaptchaError, setCmdOffice })(PatientInformation);
