import {appData} from "../actions/types";

const initialState = {
    config: {}
};

const appDataReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case appData.SET_CONFIG:
            return {
                ...state,
                config: payload.reduce((object, configItem) => {
                    object[configItem.key] = configItem.value;
                    return object;
                }, {})
            }
        default:
            return state;
    }
}

export default appDataReducer