import Footer from '../../components/footer/Footer';
import PageHeading from '../../components/pageHeader/PageHeading';
import ProgressBar from '../../components/progressBar/ProgressBar';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '../../hooks';
import { useTranslation } from 'react-i18next';
import './MembershipInformationBody.css';
import { useEffect } from 'react';
import MembersInformationForm from '../../components/forms/MembersInformationForm';
import { useSelector } from 'react-redux';
import { ApiCall, PUT } from '../../utils';
import { BASE_URL_PATIENT } from '../../constants';
import Spinner from 'react-bootstrap/esm/Spinner';
import { useState } from 'react';
import { formatPatientPayload } from '../../utils/formatPatientPayload';

const MemberShipInformationBody = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false)
    const params = useQuery();
    const insuranceType = params.get("type");
    const oGlobalPatientInfoObject = useSelector(state => state.patientInfo);
    const resources = useSelector((state) => state.patientInfo.resources);
    const config = useSelector(state => state.appData.config);
    const isPaymentDisabled = (config["DisablePayment"] ?? "true") === "true";
    const routingPath = {
        next: !isPaymentDisabled && (insuranceType === "selfpay" || insuranceType?.includes("Admin_") || insuranceType?.includes("third-party_")) ? '/payments' : '/successEnrollment',
        back: '/confirmEnrollment'
    }

    useEffect(() => {
        if (insuranceType === 'medicare') navigate("/");
    }, [insuranceType, navigate])

    /* istanbul ignore next */
    const getApiPayload = async () => {
        // final payload 
        let patientPayload = formatPatientPayload(oGlobalPatientInfoObject, insuranceType, resources, true);
        await finalAPIcall(patientPayload);
    }

    /* istanbul ignore next */
    const finalAPIcall = async (patientPayload) => {
        setIsLoading(true)
        await ApiCall(BASE_URL_PATIENT + '1', patientPayload, PUT, null, function (response) {
            if (response.status === 200) {
                setIsLoading(false)
                localStorage.removeItem('patient_details');
                localStorage.removeItem('RES_FOR_PATIENT_POST');
                navigate({
                    pathname: '/successEnrollment',
                    search: '?type=' + insuranceType,
                });
            } else if (response.status === 409 || response.status === 417) {
                if (response.data?.errorCode === "NG_1002" || response.data?.errorCode === "AppCMD_1002") {
                    navigate({ pathname: '/existingUser', search: '?type=' + insuranceType });
                }
            }
            else {
                setIsLoading(false)
                navigate('/error');
                return false;
            }
        });
    }


    return (
        <>
            <div className='membership-information-body-root'>
                {isLoading &&
                    <Spinner style={{
                        color: "#264E5A",
                        width: "5rem",
                        height: "5rem",
                        borderWidth: "0.5rem",
                        marginTop: "70px"
                    }} animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                }
                {!isLoading &&
                    <>
                        <div className='membership-information-body'>
                            <ProgressBar currentPage="membershipInformation" />
                            <PageHeading title={t('membershipInformation.heading')}
                                description={insuranceType !== "selfpay" ? t('membershipInformation.subheading') : null} />
                            <MembersInformationForm />
                        </div>
                        {/* membership-information-body */}
                        <Footer
                            routingPath={routingPath}
                            currentPage='membershipInformation'
                            footerCallback={getApiPayload}
                        />
                    </>
                }

            </div>
            {/* membership-information-body-root */}
        </>
    )
}

export default MemberShipInformationBody;
