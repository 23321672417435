import axios from "axios";
import { BASE_URL } from "../constants";
import { setAuthToken } from "../utils";
import { auth } from "./types";

export const loadUser = () => async (dispatch) => {
  try {
    if (localStorage.token) setAuthToken(localStorage.token);
    const res = await axios.get(`${BASE_URL}api/auth`);
    await dispatch({
      type: auth.USER_LOADED,
      payload: res.data,
    });
  } catch (e) {
    console.warn(e.message); // Console warning
    await dispatch({ type: auth.CLEAR_PROFILE });
    await dispatch({ type: auth.AUTH_ERROR, payload: e });
  }
};

// Register a user
export const register = (body) => async (dispatch) => {
  try {
    const res = await axios.post(`${BASE_URL}api/users`, body);
    await dispatch({
      type: auth.REGISTER_SUCCESS,
      payload: res.data,
    });
    await dispatch(loadUser());
  } catch (e) {
    await dispatch({ type: auth.REGISTER_FAIL, payload: e });
  }
};

// Log the user in
export const login = (info) => async(dispatch) => {
  const { email, phone, username, password } = info;
  let body;
  if (info.email) body = { email, password };
  else if (info.username) body = { username, password };
  else if (info.phone) body = { phone, password };

  try {
    const res = await axios.post(`${BASE_URL}api/auth`, body);
    await dispatch({
      type: auth.LOGIN_SUCCESS,
      payload: res.data,
    });
    await dispatch(loadUser());
  } catch (e) {
    await dispatch({
      type: auth.LOGIN_FAIL,
      payload: e
    });
  }
};

// Logout / clear profile
export const logout = () => (dispatch) => dispatch({ type: auth.LOGOUT });

export const setCaptchaToken=(data)=>async(dispatch)=>{
  dispatch({ type: auth.SET_CAPTCHA_TOKEN, payload: data });
  }

export const setCaptchaError=(data)=>async(dispatch)=>{
    dispatch({ type: auth.SET_CAPTCHA_ERROR, payload: data });
  }

  export const clearAuthData = () => async dispatch => await dispatch({ type: auth.CLEAR_AUTH_DATA });