import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';

function BasicInfoRadio({ patientId, id, list, onChange, error, setRadio, stateVal, readOnly }) {
	const { t } = useTranslation();
	return (
		<div className="form-group col-12 p-0">
			<div className="basic-info-radio-section">
					<label htmlFor="preferredMobile" className="label-text m-b-10">
							{t('basic.form.r5.label')}
					</label>
					<div className="basic-info-custom-radio-mapper d-inline-flex m-b-10 m-b-13">
							{list.map((n, i) => (
									<div onClick={() => readOnly ? null : setRadio(n, id)} className="basic-info-radio-div mb-0 mx-2" key={n.id}>
											<input
													type="radio"
													value={n.value}
													name="preferredMobile"
													readOnly={readOnly}
													onChange={(e) => readOnly ? null : onChange(e, id)}
													checked={n.value === stateVal}
											/>
											<label htmlFor="preferredMobile" className="radio-labels ms-1">{t(`basic.form.r5.option${i + 1}`)}</label>
									</div>
							))}
					</div>
			</div>
			<div className="pt-2 text-danger" id={`${patientId}-preferredMobileErr`}>
				{error && error.length > 0 && (<div>{error[0]}</div>)}
			</div>
		</div>
	)
}

BasicInfoRadio.propTypes = {
	patientId: PropTypes.string,
	id: PropTypes.string,
	list: PropTypes.array,
	onChange: PropTypes.func,
	error: PropTypes.array,
	setRadio: PropTypes.func,
	stateVal: PropTypes.string,
	readOnly: PropTypes.bool,
};

export default BasicInfoRadio;