const ValidationText = ({displayText}) => {
    return (
        <>
            {displayText && 
                <div className='signDocvalidation' data-testid="validationText-test" role="alert">
                    Please accept all forms to continue 
                </div> 
            }
        </>
    )
}

export default ValidationText;