import { DEPENDANT, PRIMARY, CURRENT, SPOUSE, ERROR_MSG, REMINDER_MESSAGE, DUPLICATE_DEPENDENT_ERROR_MESSAGE } from "./patients";
import { BASE_URL, GET_GENDER_RACE_ETHNICITY_LANGUAGE_URL, BASE_URL_PATIENT, GET_LOCATION_API } from "./urlEndpoint";
import { authResponse } from "./auth";
import {CAPTCHA_CONFIG} from './captcha.js'

export {
    DEPENDANT,
    PRIMARY,
    CURRENT,
    SPOUSE,
    BASE_URL,
    ERROR_MSG,
    REMINDER_MESSAGE,
    DUPLICATE_DEPENDENT_ERROR_MESSAGE,
    GET_GENDER_RACE_ETHNICITY_LANGUAGE_URL,
    BASE_URL_PATIENT,
    GET_LOCATION_API,
    authResponse,
    CAPTCHA_CONFIG
};