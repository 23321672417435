import { useTranslation } from 'react-i18next';
import {useLocation,useNavigate } from "react-router-dom";
import './success.css';


function ExistingMember() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location=useLocation();
    const params = new URLSearchParams(location.search)
    const insuranceType = params.get("type");
    const paymentFail=location.state?.paymentStatusFail ?? null;
   
    return (
        <div>
            <div className='container-xl'>
                    <div className='existingMemberCard'>
                        <div className='card-heading'>{t('ExistingUser.alreadyHavAnAcct')}</div>
                        {(insuranceType==="selfpay" && (paymentFail===true))?
                        <div>
                            <div className='card-subHeading' >
                                <span>{t('ExistingUser.selfpayEnroll')}</span>
                                <p>{t('ExistingUser.selfpayCollectPayment')}</p>
                            </div>
                        </div>:
                        <div>
                            <div className='card-subHeading' >
                                <span>{t('ExistingUser.pleaseCall')}</span>
                                <p>{t('ExistingUser.memberPortal')}</p>
                            </div>
                        
                            <a href="https://www.medfusion.net/convenientmdprimarycare-27073/portal/#/user/login"  className='existing-card-button'>
                                <button>
                                {t('ExistingUser.loginMemberPortal')}
                                </button>
                            </a>
                        </div>}
                    </div>
                    <div className='exit-btn-div'>
                     <button className='exit-button' onClick={()=>navigate({
                        pathname: '/',
                        search:'?type='+insuranceType,
                     })}>{t('ExistingUser.goBackbtn')}</button>
	                </div>        
            </div>
        </div>
    );
}

export default ExistingMember;