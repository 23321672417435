import React from 'react'
import './signDocs.css';

function ConsentToTreat() {
    return (
        <div className='consent-root'>
            <h6><b>PATIENT’S CONSENT FOR TREATMENT & RELEASE OF INFORMATION</b></h6>
            <p>I, the undersigned, hereby consent to the following treatment:</p>

            <ul>
                <li><b>Administration and performance of all treatments</b></li>
                <li><b>Administration of any needed anesthetics</b></li>
                <li><b>Performance of such procedures as may be deemed necessary or advisable
                    in the treatment of this patient.</b></li>
                <li><b>Use of prescribed medication.</b></li>
                <li><b>Performance of diagnostic procedures, tests, and/or cultures.</b></li>
                <li><b>Performance of other medically accepted laboratory tests that may be
                    considered medically necessary or advisable based on the judgement of the
                    attending physician or their assigned designee.</b></li>
            </ul>
            <p>I fully understand that this is given in advance of any specific diagnosis or treatment.</p>
            <p>I intend this consent to be continuing in nature even after a specific diagnosis has been made and
                treatment recommended. The consent will remain in full force until revoked in writing.</p>
            <p>I understand that ConvenientMD may include the consent at the Satellite offices under common
                ownership.</p>
            <p>I, the undersigned, acknowledge that ConvenientMD may use and disclose my medical and
                demographic information for purposes of treatment, payment, and healthcare operations. This may
                include release of medical or demographic information to other medical providers involved in my care.
                These records may be released to their parties as verbally instructed by me.</p>
            <p>A photocopy of this consent shall be considered as valid as the original.</p>
            <p>Medicare Patients: I authorize the release of medical information about me to the Social Security
                Administration or its intermediaries for my Medicare claims. I assign the benefits payable for services to
                ConvenientMD.</p>
            <p>I certify that I have read and fully understand the above statements and consent fully and voluntarily to
                its contents</p>
        </div>
    )
}

export default ConsentToTreat