import { configureStore } from '@reduxjs/toolkit'
import { applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const initialState = {};
const middlewares = [thunk];
const middlewareEnhancer = applyMiddleware(...middlewares);
const enhancers = [middlewareEnhancer];

export const store = configureStore({
  reducer: persistedReducer,
  middleware: middlewares,
  preloadedState: initialState, 
  devTools: composeWithDevTools(...enhancers)
});

export const persistor = persistStore(store);