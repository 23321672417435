import { useCallback, useEffect, useState } from "react";

function useStorage(key, defaultValue, storageObject) {
    const [value, setValue] = useState(() => {
        const jsonValue = storageObject.getItem(key);
        if (isNaN(jsonValue)) {
            if (typeof defaultValue === 'function') return defaultValue();
            else return defaultValue;
        }
        if (jsonValue !== null) return JSON.parse(jsonValue);
        if (typeof defaultValue === 'function') return defaultValue();
        else return defaultValue;
    });
    useEffect(() => {
        if (value === undefined || isNaN(value)) return storageObject.removeItem(key);
        storageObject.setItem(key, JSON.stringify(value));
    }, [key, value, storageObject]);
    const remove = useCallback(() => setValue(undefined), []);
    const setter = (newVal) => storageObject.setItem(key, newVal); 
    return [value, setter, remove];
}

export function useLocalStorage(key, defaultValue) {
    return useStorage(key, defaultValue, window.localStorage);
}

export function useSessionStorage(key, defaultValue) {
    return useStorage(key, defaultValue, window.sessionStorage);
}