import React from 'react';
import { useTranslation } from 'react-i18next';
import {useLocation } from "react-router-dom";
import PractitionerSingleDisplay from '../../components/practitionerProfileCard/PractitionerSingleDisplay';
import ProgressBar from '../../components/progressBar/ProgressBar';
import "./SinglePractitioner.css";

function SinglePractitioner() {
     const { t } = useTranslation()
       const location = useLocation();
    const patientState = location.state; 

    return (
        <>
            <div className="single-practitioner-main-styles">
                <ProgressBar />
                <h1 className="single-practitioner-title">Provider Details</h1>
                <PractitionerSingleDisplay
                    education={patientState.biography?.education}
                    certification={patientState.biography?.certification}
                    location={patientState.providerCity}
                    pronouns={patientState.biography?.pronouns}
                    name={patientState.firstName+" "+patientState.lastName}
                    intro={patientState.biography?.description}
                    sees18={patientState.biography?.patient_age}
                    healthy={patientState.biography?.what_keeps_healthy}
                    profile_img={patientState.profile_img}
                    position={patientState.degree}
                    client_type={patientState.client_type}
                />                
            </div>
            <div className='single-pracitioner-footer'>
                <div>
                    { t('footer.questions') } (603) 810-6700
                </div>
            </div>
        </>
        )
}

export default SinglePractitioner;