import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router-dom';
import './success.css';
import Footer from '../../components/footer/Footer';
import { useSelector } from "react-redux";
import { ApiCall } from '../../utils/ApiCall';
import { PUT } from '../../utils';
import { CheckYellow } from "../../assets";
import Modal from 'react-bootstrap/Modal';

function SuccessEnrollment() {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const params = new URLSearchParams(location.search);
    const resources = useSelector((state) => state.patientInfo.resources);
    const insuranceType = params.get("type");
    const errorType = params.get("errorCode");

    const patientId = location.state?.patientId
    const hasPCP = location.state?.hasPCP
    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);
    // disabling browser back btn
    window.history.pushState(null, null, location.href);
    window.onpopstate = function () {

        window.history.go(1);
    };

    const [hasUpdated, setHasUpdated] = useState(false)
    const [respVal, setRespVal] = useState("")

    const updatePCP = async (val) => {
        if (!hasUpdated) {
            await ApiCall("patient/abletoupdatepcp", {
                "patientId": patientId,
                "wasAbleToUpdatePCPAnthem": val
            },
                PUT, null, function (response) {
                    if (response.status === 200) {
                        setHasUpdated(true)
                        setRespVal(val)

                    } else {
                        navigate('/error');
                        return false;
                    }
                });
        }
    }


    return (
        <div className="successPageup-Layout">
            <div className="successPageup">
                <div className="Header">
                    <h1 className="successPageP">Success!</h1>
                    <h1 className="successPageP">{t('success.welcome')}</h1>
                    <h1 className="successPageP">{t('success.primarycare')}</h1>
                </div>
                {errorType == 'AppNG_1304' ? (<div className="success-card-layout">
                    <div className={`success-card-message ${(insuranceType === 'medicare' || (insuranceType === "selfpay" || insuranceType?.includes("Admin_") || insuranceType?.includes("third-party_"))) && 'medicare'}`}>
                        <span>{t('success.syam')}</span>
                    </div>
                    {(insuranceType === 'medicare' || (insuranceType === "selfpay" || insuranceType.includes("Admin_") || insuranceType?.includes("third-party_"))) ?
                        <div className={`success-card-text ${(insuranceType === 'medicare' || (insuranceType === "selfpay" || insuranceType?.includes("Admin_") || insuranceType?.includes("third-party_"))) && 'medicare'}`}>
                            <p className="">
                                {t('success.scheduleFirstAppoinmentMedicare')}
                            </p>
                            <div className="medicare-phonenumber">
                                <p>{t('success.phoneNum')}</p>
                            </div>
                        </div> :
                        <div className="success-card-text">
                            <div style={{ marginBottom: '30px' }}>
                                <p>{t('success.scheduleAppointment')}</p>
                            </div>
                            <p className="bold-text">
                                {t('success.schedulePhoneCall')}
                            </p>

                            <div>
                                <p>{t('success.phoneNum')}</p>
                            </div>
                        </div>}
                </div>) : <>
                    <div>
                        <div className="success-steps">
                            <div className="success-step">
                                <span>
                                    <img src={CheckYellow} alt="" />
                                </span>
                                <h5>Enrollment completed</h5>
                            </div>
                            {
                                (insuranceType === "anthem" || insuranceType === "selfpay" || insuranceType?.includes("Admin_") || insuranceType?.includes("third-party_")) &&
                                <div className="success-step">
                                    <span></span>
                                    <div>
                                        <h5>Complete paperwork</h5>
                                        <p>Forms will arrive by mail next week</p>
                                    </div>
                                </div>
                            }

                            <div className="success-step">
                                <span></span>
                                <div>
                                    <h5>Schedule your first visit </h5>
                                    <p>You will receive an email within 24 hours <br />to schedule your visit with ConvenientMD</p>
                                </div>
                            </div>
                            {/*Adding comments to the following code to align with client requirements,
                             as it may be used in the future(for reference-VCMD-1226)*/}
                            {/* {
                                insuranceType === "medicare" &&
                                <div className="success-step" data-testid="medicareSuccessMsg-test">
                                    <span></span>
                                    <div>
                                        <h5>Sign your Medicare documents now</h5>
                                        <p>Use button below</p>
                                    </div>
                                </div>
                            } */}
                            {insuranceType === "anthem" &&
                                <div className="success-step">
                                    <span></span>
                                    <div>
                                        <h5>Please update your PCP now</h5>
                                        <p>Instructions below</p>
                                    </div>
                                </div>
                            }

                        </div>
                        {insuranceType === "anthem" &&

                            <div className="success-card-layout">
                                <div className={`success-card-message ${(insuranceType === 'medicare' || (insuranceType === "selfpay" || insuranceType?.includes("Admin_") || insuranceType?.includes("third-party_"))) && 'medicare'}`}>
                                    <span>How to update your PCP with Anthem:</span>
                                </div>
                                <div className="success-card-text" style={{ textAlign: 'left' }}>
                                    <ol className="success-card-ol">
                                        {
                                            hasPCP ?
                                                <li>Log into
                                                    <a href="https://www.anthem.com/account-login/" target="_blank"> anthem.com</a>
                                                    or download the <a href="https://www.sydneyhealth.com/?cmp=WEB-MRKCOMM-DL-SYDAPP-ABCBS&shortlink=ede481a3&c=DLSydHealth&af_ad=abcbs&pid=Web&af_adset=mktcomm&source_caller=ui" target="_blank">Sydney App</a>
                                                </li>
                                                :
                                                <li> Download the <a href="https://www.sydneyhealth.com/?cmp=WEB-MRKCOMM-DL-SYDAPP-ABCBS&shortlink=ede481a3&c=DLSydHealth&af_ad=abcbs&pid=Web&af_adset=mktcomm&source_caller=ui" target="_blank">Sydney App</a>
                                                </li>
                                        }
                                        <li>Select CARE in the main menu </li>
                                        <li>Then choose UPDATE PRIMARY CARE PHYSICIAN </li>
                                        <li>Search for your ConvenientMD provider </li>
                                        <li>Select ADD TO CARE TEAM</li>
                                    </ol>
                                </div>
                            </div>
                        }
                        {insuranceType === "anthem" &&

                            <div className="anthem-success-footer">
                                <p>Were you able to update your PCP with Anthem?</p>
                                <div className="anthem-action-btns">
                                    <button className={`green-btn  ${respVal && respVal !== 'YES' ? 'hide-btn' : ''} ${respVal === 'YES' ? 'selected' : ''} `} onClick={() => updatePCP("YES")} disabled={respVal && respVal !== 'YES'}>YES, I updated my PCP</button>
                                    {/* <button className={`red-btn  ${respVal && respVal !== 'NO' ? 'hide-btn' : ''} ${respVal === 'NO' ? 'selected' : ''}`} onClick={() => updatePCP("NO")} disabled={respVal && respVal !== 'NO'}>NO, I did not update my PCP</button> */}
                                    <button className={`orange-btn  ${respVal && respVal !== 'EXPERIENCED_ISSUES' ? 'hide-btn' : ''} ${respVal === 'EXPERIENCED_ISSUES' ? 'selected' : ''}`} onClick={() => updatePCP("EXPERIENCED_ISSUES")} disabled={respVal && respVal !== 'EXPERIENCED_ISSUES'}>TRIED, but experienced issues</button>
                                </div>
                            </div>
                        }
                    {/*Adding comments to the following code to align with client requirements,
                             as it may be used in the future(for reference-VCMD-1226)*/}
                        {/* {insuranceType === "medicare" &&
                            <a className="medicare-btn-orange" href='https://yesdoc.us/YQszjfwF' target="_blank">Go to Medicare documents</a>
                        } */}
                    </div>
                </>

                }
                <Footer currentPage='successEnrollment' />
            </div>
            {hasUpdated &&
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body> Your response has been received! </Modal.Body>
                </Modal>
            }
        </div>
    );
}

export default SuccessEnrollment;