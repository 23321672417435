import { SPACING } from "..";
import PropTypes from 'prop-types';

function BasicInfoFormGroup({ patientId, formId, name, id, value, placeholder, type, className, onChange, readOnly, error, isHalf, labelText = "", ...inputProps }) {
  return (
    <div className={`form-group ${isHalf ? "col-6" : "col-12"}`}>
      {!isHalf && <label htmlFor={name} className="label-text form-label" style={{ marginBottom: `${SPACING.formLabelLast.bottom}%` }}>{labelText}</label>}
      <input
      autoComplete="new-password"
        name={name}
        id={id}
        value={value}
        placeholder={placeholder}
        type={type || "text"}
        className={className}
        onChange={(e) => onChange(e, formId)}
        readOnly={readOnly}
        { ...inputProps }
      />
      <div id={`${patientId}-${name}Err`} className="pt-2 text-danger" data-testid="formInputError-test">
        {error && error.length > 0 && (<div>{error[0]}</div>)}
      </div>
    </div>
  );
}

BasicInfoFormGroup.propTypes = {
  patientId: PropTypes.string,
  formId: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  error: PropTypes.array,
  isHalf: PropTypes.bool,
  labelText: PropTypes.string
};

export default BasicInfoFormGroup;