import { useTranslation } from "react-i18next";
import { check } from "../../assets";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from "react";
import { ApiCall, GET, getAge } from "../../utils";
import { useQuery } from '../../hooks';
import { GET_PRICES_ANTHEM, GET_PRICES_MEDICARE, GET_PRICES_SELFPAY } from "../../constants/urlEndpoint";
import { useNavigate } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import { SET_CONFIG } from "../../actions/types/appData";

const MembersInformationForm = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const patientInfo = useSelector(state => state.patientInfo);
    const config = useSelector(state => state.appData.config);
    const isPaymentDisabled = (config["DisablePayment"] ?? "true") === "true";
    const [adultPrice, setAdultPrice] = useState(0);
    const [childPrice, setChildPrice] = useState(0);
    const [actualAdultPrice, setActualAdultPrice] = useState(0);
    const [actualChildPrice, setActualChildPrice] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const params = useQuery();
    const insuranceType = params.get("type");

    const [adults,] = useState(() => {
        const arr = []
        if (patientInfo.primaryDetails[0].basicInfo.firstName && patientInfo.primaryDetails[0].basicInfo.lastName)
            arr.push(`${patientInfo.primaryDetails[0].basicInfo.firstName} ${patientInfo.primaryDetails[0].basicInfo.lastName}`);
        if (patientInfo.spouseDetails[0]?.basicInfo?.firstName && patientInfo.spouseDetails[0]?.basicInfo?.lastName)
            arr.push(`${patientInfo.spouseDetails[0]?.basicInfo?.firstName} ${patientInfo.spouseDetails[0]?.basicInfo?.lastName}`);
        if (patientInfo.dependentDetails.length !== 0) {
            patientInfo.dependentDetails.map((item) => {
                let age = getAge(item.basicInfo.dob);
                if (age >= 18) {
                    arr.push(`${item?.basicInfo?.firstName} ${item?.basicInfo?.lastName}`);
                }
            })
        }
        return arr;
    }); // PRIMARY ONLY
    const [children,] = useState(() => {
        const arr = []
        if (patientInfo.dependentDetails.length !== 0) {
            patientInfo.dependentDetails.map((item) => {
                let age = getAge(item.basicInfo.dob);
                if (age < 18) {
                    arr.push(`${item?.basicInfo?.firstName} ${item?.basicInfo?.lastName}`);
                }
            })
        }
        return arr;
    });
    
    /* istanbul ignore next */
    const getPrice = async () => {
        setIsLoading(true)
        await ApiCall(insuranceType === 'medicare' ? GET_PRICES_MEDICARE : (
            insuranceType === 'anthem' ? GET_PRICES_ANTHEM : GET_PRICES_SELFPAY
        ), null, GET, null, function (response) {
            if (response.status === 200) {
                let config = response.data.configItems;
                if (config) {
                    const adultPrice = ((config.find(obj => obj.key === "AdultPrice"))?.value) ?? 0;
                    setAdultPrice(adultPrice);
                    const childPrice = ((config.find(obj => obj.key === "DependentPrice"))?.value) ?? 0;
                    setChildPrice(childPrice)
                    const ActualAdultPrice = ((config.find(obj => obj.key === "ActualAdultPrice"))?.value) ?? 0;
                    setActualAdultPrice(ActualAdultPrice);
                    const ActualChildPrice = ((config.find(obj => obj.key === "ActualChildPrice"))?.value) ?? 0;
                    setActualChildPrice(ActualChildPrice);
                    if (insuranceType === "anthem") {
                        let total = (adults.length * ActualAdultPrice) + (children.length * ActualChildPrice);
                        setTotalPrice(total);
                    } else {
                        let total = (adults.length * adultPrice) + (children.length * childPrice);
                        setTotalPrice(total);
                    }
                    dispatch({ type: SET_CONFIG, payload: response.data.configItems });
                }
            } else {
                navigate('/error');
                return false;
            }
        });
        setIsLoading(false)
    }


    useEffect(() => {

    }, [patientInfo])
    useEffect(() => {
        getPrice();
    }, [])
    return (
        <>
            {
                isLoading && (
                    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <Spinner style={{
                            color: "#264E5A",
                            width: "5rem",
                            height: "5rem",
                            borderWidth: "0.5rem",
                            marginTop: "70px"
                        }} animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                )
            }
            {
                !isLoading && (
                    <div className="form">
                        <h3>{insuranceType === "anthem" ? t('membershipInformation.formHeadingYearly') : t('membershipInformation.formHeading')}</h3>
                        <div className='form-section-membership'>
                            <div className='form-section-heading'>
                                <div
                                    className='form-section-heading-label'>{t('membershipInformation.formSectionHeadingLabel1')}</div>
                                {insuranceType === "anthem" ?
                                    <div className='form-section-heading-value'>
                                        <span className="membershipSPan">${adults.length * actualAdultPrice}</span> <span
                                            className="memberShipslashText">/</span> <span
                                                className="memberShipYearText">{t('membershipInformation.formYear')}</span>
                                    </div> :
                                    <div className='form-section-heading-value'>
                                        <span className="membershipSPan">${adults.length * adultPrice}</span> <span
                                            className="memberShipslashText">/</span> <span
                                                className="memberShipYearText">{t('membershipInformation.formMonth')}</span>
                                    </div>}
                            </div>
                            {adults.map((item, index) =>
                                <div className='form-section-item' key={index}>
                                    <div className="membership-tick-icon">
                                        <img className="membership-form-section-item-image" src={check} alt="check-icon" />
                                    </div>
                                    {item}
                                </div>
                            )}
                        </div>
                        {/* form-section */}
                        {children.length > 0 && (
                            <div className='membershipInformation.formHeading'>
                                <div className='form-section-heading'>
                                    <div
                                        className='form-section-heading-label'>{t('membershipInformation.formSectionHeadingLabel2')}</div>
                                    {insuranceType === "anthem" ?
                                        <div className='form-section-heading-value'>
                                            <span className="membershipSPan">${children.length * actualChildPrice}</span>
                                            <span className="memberShipslashText">/</span> <span
                                                className="memberShipYearText">{t('membershipInformation.formYear')}</span>
                                        </div> :
                                        <div className='form-section-heading-value'>
                                            <span className="membershipSPan">${children.length * childPrice}</span> <span
                                                className="memberShipslashText">/</span> <span
                                                    className="memberShipYearText">{t('membershipInformation.formMonth')}</span>
                                        </div>}
                                </div>
                                {children.map((item, index) =>
                                    <div className='form-section-item' key={index}>
                                        <div className="membership-tick-icon">
                                            <img className="membership-form-section-item-image" src={check}
                                                alt="check-icon" />
                                        </div>
                                        {item}
                                    </div>
                                )}
                            </div>
                        )}
                        {/* form-section */}
                        <div className='form-total'>
                            <div className='memberShip-form-total-label'>Total</div>
                            {insuranceType === "selfpay" && totalPrice < 300 &&
                                <div className='form-total-value'><span>${totalPrice}.00</span></div>
                            }
                            {insuranceType === "selfpay" && totalPrice > 299 &&
                                <div className="memberShipTotalDiv">
                                    <div className='membership-total-value'><span>${totalPrice}.00</span></div>
                                    <div className='membershipCalculated-total-value'><span className="calculatedTotalValue"> $299</span></div>
                                </div>
                            }
                            {insuranceType === "anthem" &&
                                <div className="memberShipTotalDiv">
                                    <div className='membership-total-value'><span>${totalPrice}.00</span></div>
                                    <div className='membershipCalculated-total-value'><span className="calculatedTotalValue"> $0</span></div>
                                </div>
                            }
                        </div>
                        {/* form-total */}
                        <div
                            className='form-membership-info'> {t(isPaymentDisabled && (insuranceType === "selfpay" || insuranceType?.includes("Admin_") || insuranceType?.includes("third-party_")) ? 'membershipInformation.formPaymentCollectionMessage' : '')}</div>
                    </div>
                )
            }
        </>
    )
}

export default MembersInformationForm;