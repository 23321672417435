import AccordionComponent from "../../../components/accordion/AccordionComponent";
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import PatientIframe from "./PatientIframe";
import PropTypes from 'prop-types';

const PrimaryPatientBlock = ({ mainItem , updateDetailsAcknArray, personDetails, iframeDropdown, updateDetailsArray, handleCallback }) => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const insuranceType = params.get("type");
    const { t } = useTranslation();
    
    return (
        <div className='signDoc-accordian-root' role="tab">          
            {insuranceType === "medicare" &&           
            <div>
                <div className='approval-padding-5'>
                <div className="approval-flex-container">
                    <div className='signDocBtnSec'>
                    <div className="signDocapproval-font-age">{t('signDocument.ageConfirmation')}</div>
                    <div>
                        <button 
                        className='signDoc-btn-select-all' 
                        onClick={() => updateDetailsAcknArray(personDetails, mainItem.id)}
                        >
                        {t('signDocument.btnSelectAll')}
                        </button>
                    </div>
                    </div>
                </div>
                </div> 
                {mainItem.inputValues.map((item, index) => 
                    <PatientIframe
                        key={item.id} 
                        item={item}
                        iframeDropdown={iframeDropdown}
                        personDetails={personDetails}
                        mainItem={mainItem}
                        updateDetailsArray={updateDetailsArray}
                        index={index}
                     />
                )}
            </div>  
            // end of medicare condition
            }
            {insuranceType !== "medicare" &&        
            <AccordionComponent 
                alwaysOpen id={mainItem.key} 
                client_name={mainItem.name} 
                client_type={"Primary account holder"} 
                parentCallback={handleCallback}>
                <div>           
                    <div className='approval-padding-5'>
                        <div className="approval-flex-container">
                        <div className='signDocBtnSec'>
                            <div className="signDocapproval-font-age">{t('signDocument.ageConfirmation')}</div>
                            <div>
                            <button 
                                data-testid="signDoc-selectAll-test"
                                className='signDoc-btn-select-all' 
                                onClick={() => updateDetailsAcknArray(personDetails, mainItem.id)}
                            >
                                {t('signDocument.btnSelectAll')}
                            </button>
                            </div>
                        </div>
                        </div>
                    </div> 
                    {mainItem.inputValues.map((item, index) => (
                        <PatientIframe
                            key={item.id} 
                            item={item}
                            iframeDropdown={iframeDropdown}
                            personDetails={personDetails}
                            mainItem={mainItem}
                            updateDetailsArray={updateDetailsArray}
                            index={index}
                        />
                    ))}
                </div>
            </AccordionComponent>
            // end of selfpay-anthem condition
            }             
        </div>
)}

PrimaryPatientBlock.propTypes = {
    mainItem: PropTypes.object,
    updateDetailsAcknArray: PropTypes.func,
    personDetails: PropTypes.array,
    iframeDropdown: PropTypes.func,
    updateDetailsArray: PropTypes.func,
    handleCallback: PropTypes.func
};
export default PrimaryPatientBlock;