import React from 'react'
import './signDocs.css';
import LogoDark from '../../../assets/images/logo-dark.png';

const SelfPayPolicy = () => {
    return (
        <div className='selfpay-root'>
            <div className='selfpay-page'>
                <div className='selfpay-header'>
                    <img src={LogoDark} alt="logo-dark" />
                </div>
                <div className='selfpay-body'>
                    <h6 className='text-center'><b>ConvenientMD Primary Care</b></h6>
                    <h6 className='text-center'><b>Self-Pay Program Consent Form</b></h6>
                    <div className='selfpay-patient-info'>
                        <div className='selfpay-patient-info-item'>Patient’s First and Last Name</div>
                        <div className='selfpay-patient-info-item'>Patient’s Date of Birth</div>
                    </div>
                    <p>
                        For Members of the ConvenientMD Primary Care membership program described herein who are not covered by a 
                        participating insurance plan, ConvenientMD Primary Care has established a program that covers certain primary 
                        care and urgent care services for an annual membership fee paid in monthly installments of $99 per adult Member 
                        per month or $49 per dependent (aged 0-26) per month. Membership provides you with access to the following 
                        services that may be obtained at your assigned ConvenientMD Primary Care practice location or any ConvenientMD Urgent Care clinic:
                    </p>
                    <ul className='selfpay-dashed-list'>
                        <li>
                            Primary Care Services – Access to your regular primary care provider for routine annual physical exams, 
                            preventative screenings, recommended immunizations, management of chronic conditions, medical advice, 
                            sick visits, and access to Care Navigation services to advise on specialist selection, specialist 
                            appointment scheduling, and referral management.
                        </li>
                        <li>
                        	A.
                        </li>
                        <li>
                            Virtual Care – Virtual visits to address a variety of illnesses and injuries.
                        </li>
                        <li>
                            Care & Navigation Team – Access to a team of clinicians for guidance around all aspects of your health. 
                            Your provider may refer you to any member of the team which includes a Health Coach, Pharmacist, Nurse Care Manager, a
                            nd Behavioral Health Specialist. 
                        </li>
                    </ul>
                    <p>
                        Members will have the option to pay the annual membership fee as a single payment or in monthly installments. 
                        Members electing to pay as a single payment will receive a 5% discount on the annual fee. 
                        For Members electing monthly installments, payment of the membership fee is due by the 5th day of each month. 
                        The first month of membership will be pro-rated from the day of enrollment. 
                        Failure to pay by the 15th of the month may result in dismissal from the program. ConvenientMD reserves the right to deny reentry into the program. 
                    </p>
                    <p>
                        In the event you need to cancel membership, we require 90 days’ notice in writing addressed to primarycare@convenientmd.com 
                        or 111 New Hampshire Ave. Suite 2 Portsmouth, NH 03801. The last day of membership will be the last day of the month 
                        following 90 days’ post written notice. You may continue to use services until the last day of membership.
                    </p>
                    <h6 className='text-center'><b>Membership Payment Plan Options</b></h6>
                    <div className='selfpay-form'>
                        <div>Payment Frequency</div>
                        <div>Payment Amount</div>
                        <div>Payment Due Date</div>

                        <div>Annual</div>
                        <div>$1,128.60/year/adult <br/> $558.60/year/child</div>
                        <div>The 5th of the first full month of coverage</div>

                        <div>Montly</div>
                        <div>$99/month/adult <br/> $49/month/dependent</div>
                        <div>The 5th of each month</div>
                    </div>
                </div>
                <p>
                    If your insurance provider is added to our network or you switch to an insurance provider 
                    that is already part of the ConvenientMD Primary Care network while you are a self-pay member, 
                    ConvenientMD will convert your membership as of the day the insurance provider is added to the network and, 
                    if applicable, refund the prorated membership fee effective the first day of qualified insurance coverage.
                </p>
                <div className='selfpay-footer'>
                    <p style={{marginBottom: "0" }}>1600 Woodbury  Ave.</p> 
                    <p>Portsmouth, NH 03801</p>
                </div>
            </div>{/* selfpay-page */}
            <div className='selfpay-page'>
                <div className='selfpay-header' style={{marginBottom: "30px" }}>
                    <img src={LogoDark} alt="logo-dark" />
                </div>
                <div className='selfpay-body'>
                    <p>
                        By consenting to membership you are authorizing ConvenientMD to charge your chosen form 
                        of payment at the time of initial payment and automatically on each recurring payment due date. 
                        You agree and are required to maintain a valid form of payment on file with ConvenientMD Primary 
                        Care to complete automatic membership payments when due. You agree to provide your identified 
                        banking institution with any required notice or documentation in order for your provided payment 
                        method to be automatically charged when membership payments are due. If the form of payment provided 
                        expires or becomes invalid, you agree to promptly provide updated billing information. Failure to 
                        adhere to any of the above requirements may result in being dismissed from the membership program by 
                        ConvenientMD. If you are dismissed from the ConvenientMD Primary Care membership program, you will no longer 
                        be obligated to make any further payments under your membership plan, nor will you be entitled to any benefits 
                        under the membership plan for any period of time after the last month for which payment has been made.
                    </p>
                    <p>
                        Membership at ConvenientMD Primary Care is NOT a health insurance policy or a substitute for 
                        health insurance coverage. As such, membership is not subject to health insurance protections 
                        provided for by state law. This plan is not a Qualified Health Plan under the Affordable Care Act. 
                        Membership at ConvenientMD Primary Care is a program solely to receive access to the services 
                        described above and does not cover hospital, specialist, or any services not directly 
                        provided by ConvenientMD. Members are still responsible for payment of all ancillary 
                        medical services beyond those services listed above.
                    </p>
                    <p>
                        In certain situations, Members may require further medical attention beyond what is
                        covered under the membership program including but not limited to: medical prescriptions, 
                        referral to a specialist, and referral to a hospital or other treatment that 
                        is not included in your membership.  The cost of any charges associated with 
                        these additional services are NOT covered by this membership. 
                        Any such costs are the sole responsibility of the Member. 
                        This membership may not be assigned or transferred.
                    </p>
                    <p style={{marginBottom: "30px" }}>
                        The ConvenientMD Notice of HIPAA Privacy Practices will be distributed to 
                        the personal email address of each individual Member upon registration 
                        and annually thereafter.
                    </p>
                    <p>By signing this form, I acknowledge that I understand the self-pay services and cancelation policy.</p>
                    <div className='selfpay-patient-info'>
                        <div className='selfpay-patient-info-item'>Patient’s Signature</div>
                        <div className='selfpay-patient-info-item'>Date</div>
                    </div>
                    <div className='selfpay-patient-info'>
                        <div className='selfpay-patient-info-item'>Relationship, if not patient</div>
                        <div className='selfpay-patient-info-item' style={{border: "none" }}></div>
                    </div>
                    <p>Preauthorized Payment Method Card Number – Last Four Digits</p>
                    <p>XXXX—XXXX—XXXX—<span className='selfpay-credit-card-lastt-digits'>&#x200B;</span></p>
                    <div className='selfpay-footer' style={{marginBottom: "30px" }}>
                        <p style={{marginBottom: "0" }}>1600 Woodbury  Ave.</p> 
                        <p>Portsmouth, NH 03801</p>
                    </div>
                </div>
            </div>{/* selfpay-page */}
        </div>
    )
}

export default SelfPayPolicy