import { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './LandingPage.css';
import { useLocalStorage } from "../../hooks";
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { clearAllData } from '../../actions/patientInfo';
import { clearAuthData } from '../../actions/auth';
import { ApiCall, GET } from "../../utils";

const LandingPage = ({ clearAllData, clearAuthData }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const insuranceType = params.get("type");

  const emailRef = useRef("");
  const [isValid, setIsValid] = useState(true);

  const [progress, setProgress, removeProgress] = useLocalStorage("progressbar", 1);

  localStorage.clear();
  useEffect(() => {
    if ((progress && progress !== 1 && JSON.parse(localStorage.getItem('progressbar')) !== 1)) {
      removeProgress();
    }
    // eslint-disable-next-line
  }, [progress]);

  if (JSON.parse(localStorage.getItem('progressbar')) === null || JSON.parse(localStorage.getItem('progressbar')) !== 1) {
    setProgress(1)
  }

  const navigatePage = () => {
    setProgress(2);
    if (insuranceType === "medicare") {
      navigate("/basic-info?type=medicare")
    } else if (insuranceType !== null && (insuranceType === "selfpay" || insuranceType?.includes("Admin_") || insuranceType?.includes("third-party_"))) {
      navigate(`/basic-info?type=${insuranceType}`)
    } else {
      navigate('/basic-info?type=anthem');
    }
  }
  const nextClick = () => {

    //email validation 
    const email = emailRef.current.value;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    setIsValid(emailRegex.test(email));
    const emailValid = emailRegex.test(email);
    let isEmailForm = insuranceType?.includes("Admin_") || insuranceType?.includes("third-party_");
    if (isEmailForm) {
      if (emailValid) {
        localStorage.setItem('leadEmail', email);
        navigatePage();
      }
    }
    else {
      navigatePage();
    }
  }

  useEffect(() => {
    clearAllData();
    localStorage.removeItem('RES_FOR_PATIENT_POST');
    localStorage.removeItem('patient_details');
  }, []);

  useEffect(() => {
    localStorage.setItem('type', JSON.stringify(insuranceType ?? 'anthem'));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (insuranceType?.includes("third-party_")) {
      ApiCall(`config?configType=${insuranceType.replace("third-party_", "")}`, null, GET, null, function (response) {
        if (response.status === 200) {
          let config = response.data.configItems.length;
          if (config > 0) {
            response.data.configItems.map((item) => {
              localStorage.setItem(item.key, item.value)
            })
          }
        }
        else {
          navigate('/error');
          return false;
        }
      });
    }
  }, [insuranceType])


  return (
    <div className="">
      <div className="landing-main-div">
        <div className="landing-body">
          <div>
            <div className="landing-main-heading">
              <h1 className="landing-main-headingP">
                {/* { t('landing.header') } */}
                <div className="landing-main-heading-div"> {t('landing.AreYouReadyFor')}</div>
                <div className="landing-main-heading-div">{t('landing.betterPrimaryCare')}</div>
              </h1>
            </div>
            <p className="landing-sub-heading">
              {t('landing.subheader')}
            </p>
            {(insuranceType?.includes("Admin_") || insuranceType?.includes("third-party_")) &&
              <form id="enrollmentemp" className='needs-validation  landing-form mb-5' data-testid="landing-emailForm">
                <div className='Employeeid' >
                  <label className='emailLabel'>
                    {insuranceType?.includes("third-party_") ? 'EmployerGroup' : 'ConvenientMD Employee'}  Email ID
                  </label>
                  <br></br>
                  <input className='Newemailid mt-2' id="enrolledByEmployeeEmail" type="email" name="email" ref={emailRef} required data-testid="emailInput-test" />
                  {!isValid && <p className='text-danger' data-testid="emailerror-msg">Please enter a valid email address.</p>}
                </div>
              </form>
            }
            <div className="landing-button">
              <button className="landing-start-btn"  data-testid="nextBtn-test" onClick={() => nextClick()}>
                {t('landing.lgs')}
              </button>
            </div>
          </div>
          <span>
            {(!insuranceType?.includes("Admin_") && !insuranceType?.includes("third-party_")) &&
              < div className="landing-card-section"> {/* SOME MARGINS OR STH */}
                <p className="landing-card-header">
                  {t('landing.tmtq')}
                </p>
                <div className="landing-card-sub-header m-b-43">
                  <span>
                    {insuranceType === "medicare" ?
                      `${t('landing.basicinfo_medicare')}`
                      : insuranceType === "selfpay" ? `${t('landing.basicinfo_selfpay') + t('landing.pneo')}`
                        : <div>
                          <div>
                            {t('landing.basicinfo')}
                          </div>
                          <div>
                            {t('landing.pneo_anthem')}
                          </div>
                        </div>
                    }
                  </span>
                </div>
                <p className="landing-card-sub-header m-b-43">
                  {insuranceType === "medicare" ? <p>{t('landing.insurancedetails')}</p>
                    : insuranceType === "selfpay" ? null
                      : <i>{t('landing.eotcsi')}</i>
                  }
                </p>
                {insuranceType === "medicare" ? null :
                  <p className="landing-card-sub-header">
                    {t('landing.cctpm')}
                  </p>}
              </div>
            }
          </span>
        </div>


      </div>

      <div className="footer-phoneNum">
        {t('footer.questions')} (603) 810-6700
      </div>
    </div>
  );
}

export default connect(null, { clearAllData, clearAuthData })(LandingPage);