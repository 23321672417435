import { useEffect } from "react";
import { useSelector } from "react-redux"
import PatientInformation from "../../components/forms/PatientInformation";

function BasicInfo() {

    const oGlobalObject = useSelector(state => state.patientInfo);
    useEffect(() => {
        /*localStorage.setItem("patient_details", JSON.stringify(oGlobalObject));*/
      }, [oGlobalObject])
    return <PatientInformation />;
}

export default BasicInfo;