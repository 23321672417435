import './AccordionComponent.css'
import Accordion from 'react-bootstrap/Accordion';
import React, { useRef } from 'react';
import { formatStatus } from '../forms';
import PropTypes from 'prop-types';

function AccordionComponentBasic(props, ref){
    const { children, client_name, client_type, id, parentCallback, styles, alwaysOpen=false,page_name, isClosed = true} = props;
    const locationRef = useRef();
    const onSelectAccordion = (e) => parentCallback(locationRef, e, id);
    function addPropsToChildren(children, props) {
        if (!Array.isArray(children)) {
          return addPropsToReactElement(children, props)
        }
        return children.map(childElement =>
          addPropsToReactElement(childElement, props)
        )
      }
    function addPropsToReactElement(element, props) {
        if (React.isValidElement(element)) {
          return React.cloneElement(element, props)
        }
        return element
      }
      
    return(
        <div className='accordion-section'>
            <Accordion activeKey={alwaysOpen && isClosed ? id : null} alwaysOpen={alwaysOpen}>
                <Accordion.Item eventKey={id}>
                <Accordion.Header onClick={(e)=>onSelectAccordion(e)} ref={locationRef}>
                    <div className='accordion-section-header'>
                            <p className='accordion-header-name' style={styles}>{client_name}</p>
                            <p className='accordion-header-client-type'>{formatStatus(client_type)}</p>
                    </div>
                    </Accordion.Header>
                    <Accordion.Body>
                    {page_name==="Practitioner"?
                            addPropsToChildren(children, { accordianRef:locationRef })
                        :children
                        }
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )
}
AccordionComponentBasic = React.forwardRef(AccordionComponentBasic);

AccordionComponentBasic.propTypes = {
  children: PropTypes.node,
  client_name: PropTypes.string,
  client_type: PropTypes.string,
  id: PropTypes.string,
  parentCallback: PropTypes.func,
  styles: PropTypes.object,
  alwaysOpen: PropTypes.bool,
  page_name: PropTypes.string,
  isClosed: PropTypes.bool
};

export default AccordionComponentBasic;