import { BasicInfoContext, BasicInfoProvider } from "./BasicInfoContext";
const getCoordinates = (id, patientId, offset = 100) => {
    if (!id && !patientId) return;
    const elem = document.getElementById(`${patientId}-${id}`);
    window.scrollTo({
        behavior: 'smooth',
        top:
          elem.getBoundingClientRect().top -
          document.body.getBoundingClientRect().top -
          offset,
      })
}
export { BasicInfoContext, BasicInfoProvider, getCoordinates };