import { createContext, useReducer, useState } from "react";
import { getCoordinates } from ".";
import { AT_LEAST_ONE_LETTER_REGEX, doesFormExist, formatErrorFieldName, formatErrorIds, formReducer, formTypes, INITIAL_FORM_STATE, isFieldValid, isOverEighteen, isValidEmail, isValidUSPhoneNumber } from "../components/forms";
import { CURRENT, DEPENDANT, ERROR_MSG, PRIMARY, REMINDER_MESSAGE, SPOUSE } from "../constants";
export const BasicInfoContext = createContext();

const SET_PRIMARY_ERRORS = "SET_PRIMARY_ERRORS";
const SET_DEPENDENT_ERRORS = "SET_DEPENDENT_ERRORS";
const UPDATE_DEPENDENT_ERRORS = "UPDATE_DEPENDENT_ERRORS";
const SET_CURRENT_ERRORS = "SET_CURRENT_ERRORS";
const CLEAR_ALL_ERRORS = "CLEAR_ALL_ERRORS";
const SET_ERROR_EXISTENCE = "SET_ERROR_EXISTENCE";
const REMINDER_WARNING = "REMINDER_WARNING";
const CLEAR_MESSAGE = "CLEAR_MESSAGE";
const SET_ERR_MSG = "SET_ERR_MSG";

const initState = {
    primaryErrors: {
        id: PRIMARY,
        firstNameErr: [],
        lastNameErr: [],
        dobErr: [],
        sexErr: [],
        emailErr: [],
        phonenumberErr: [],
        preferredMobileErr: [],
        addressLine1Err: [],
        cityErr: [],
        stateErr: [],
        zipcodeErr: [],
        raceErr: [],
        ethnicityErr: [],
        languageErr: [],
    },
    dependentsErrors: [],
    currentErrors: {
        id: CURRENT,
        firstNameErr: [],
        lastNameErr: [],
        dobErr: [],
        sexErr: [],
        emailErr: [],
        phonenumberErr: [],
        preferredMobileErr: [],
        addressLine1Err: [],
        cityErr: [],
        stateErr: [],
        zipcodeErr: [],
        raceErr: [],
        ethnicityErr: [],
        languageErr: [],
    },
    hasErrors: false,
    message: ""
};

function initReducer(state, action) {
    const { type, payload } = action;
    switch (type) {
        case SET_PRIMARY_ERRORS: return { ...state, primaryErrors: payload.primary, hasErrors: payload.hasErrors };
        case SET_CURRENT_ERRORS: return { ...state, currentErrors: payload.current, hasErrors: payload.hasErrors };
        case SET_DEPENDENT_ERRORS: return { ...state, dependentsErrors: [...state.dependentsErrors, payload.dep], hasErrors: payload.hasErrors };
        case UPDATE_DEPENDENT_ERRORS: return { ...state, dependentsErrors: state.dependentsErrors.map(de => de.id === payload.id ? payload.data : de), hasErrors: payload.hasErrors };
        case SET_ERROR_EXISTENCE: return { ...state, hasErrors: payload, message: payload ? ERROR_MSG : "" };
        case REMINDER_WARNING: return { ...state, message: payload ? REMINDER_MESSAGE : "" };
        case CLEAR_MESSAGE: return { ...state, message: "" };
        case SET_ERR_MSG: return { ...state, message: ERROR_MSG };
        case CLEAR_ALL_ERRORS: return initState;
        default: return state;
    }
}

export function BasicInfoProvider({ children }) {
    const [errors, errorDispatch] = useReducer(initReducer, initState);
    const [formState, formDispatch] = useReducer(formReducer, INITIAL_FORM_STATE);
    const [additionalErrorMsg, setAdditionalErrorMsg] = useState("");
    // FORM FNS
    const changeValue = ({ id, key, value }) => formDispatch({ type: formTypes.CHANGE_VALUE, payload: { id, key, value } });
    const addPrimary = ({ val, next }) => formDispatch({ type: formTypes.ADD_PRIMARY, payload: { val, next } });
    const addSpouse = ({ val, next }) => formDispatch({ type: formTypes.ADD_DEP, payload: { val, next } });
    const addDependant = ({ val, next }) => formDispatch({ type: formTypes.ADD_DEP, payload: { val, next } });
    const updateDependent = ({ val, next }) => formDispatch({ type: formTypes.UPDATE_DEP, payload: { val, next } });
    const changeFocus = (id) => formDispatch({ type: formTypes.CHANGE_FOCUS, payload: id });
    const clearCurrent = () => formDispatch({ type: formTypes.CLEAR_CURRENT, payload: null });
    const showCurrentForm = () => formDispatch({ type: formTypes.SET_CURRENT_FORM, payload: true });
    const hideCurrentForm = () => formDispatch({ type: formTypes.SET_CURRENT_FORM, payload: false });
    const prepareCurrentForm = (val) => formDispatch({ type: formTypes.PRE_FILL_CURRENT_FORM, payload: val });
    const removePersonCtx = (id) => formDispatch({ type: formTypes.REMOVE_PERSON, payload: id });
    const saveForm = (id) => formDispatch({ type: formTypes.SAVE_FORM, payload: id });
    const saveAll = () => formDispatch({ type: formTypes.SAVE_ALL });
    const loadForm = (id, form) => formDispatch({ type: formTypes.LOAD_FORM, payload: { id, form } });
    // ERRORS
    const clearMsg = () => errorDispatch({ type: CLEAR_MESSAGE });
    const setErrMsg = () => errorDispatch({ type: SET_ERR_MSG });
    const setReminder = (bool) => errorDispatch({ type: REMINDER_WARNING, payload: bool });
    const setErrorExistence = (isErr) => errorDispatch({ type: SET_ERROR_EXISTENCE, payload: isErr });
    const setPrimaryErrors = (primaryErrors, hasErrors) => errorDispatch({ type: SET_PRIMARY_ERRORS, payload: { primary: { ...primaryErrors, id: PRIMARY }, hasErrors } });
    const setCurrentErrors = (currentErrors, hasErrors) => errorDispatch({ type: SET_CURRENT_ERRORS, payload: { current: { ...currentErrors, id: CURRENT }, hasErrors } });
    const clearAllErrors = () => {
        setAdditionalErrorMsg(() => ERROR_MSG);
        errorDispatch({ type: CLEAR_ALL_ERRORS, payload: null });
    }
    const setDependentsErrors = ({ id, dependentsErrors, /* relationship */ }, hasErrors) => {
        const dep = errors.dependentsErrors.find(item => item?.id === id);
        if (dep) {
            return errorDispatch({
                type: UPDATE_DEPENDENT_ERRORS,
                payload: {
                    id,
                    data: { ...dependentsErrors, id },
                    hasErrors
                }
            })
        }
        // const newId = relationship === SPOUSE ? SPOUSE : `${DEPENDANT}-${errors.dependentsErrors.length}`
        return errorDispatch({
            type: SET_DEPENDENT_ERRORS,
            // Maybe generate ids here later?
            payload: { dep: { ...dependentsErrors, id }, hasErrors }
        })
    };
    const check = (dataitem, isMainInstance, isInternal, insuranceType = "anthem") => {
        const newErrObj = {
            id: null,
            firstNameErr: [],
            lastNameErr: [],
            dobErr: [],
            sexErr: [],
            emailErr: [],
            phonenumberErr: [],
            preferredMobileErr: [],
            addressLine1Err: [],
            cityErr: [],
            stateErr: [],
            zipcodeErr: [],
            raceErr: [],
            ethnicityErr: [],
            languageErr: [],
        };
        newErrObj.id = dataitem.id;
        for (const key of Object.keys(dataitem)) {
            if (
                key === "relationship" ||
                key === "status" ||
                key === "addressLine2" ||
                key === 'isFormSaved' ||
                key === "patientType" ||
                key.startsWith('primary')
            ) continue; // these will not be checked as they are not in the form
            if (!key && dataitem.status === PRIMARY) continue;
            if (dataitem[key].length === 0) newErrObj[`${key}Err`].push(`${formatErrorFieldName(key)} is required.`);
            if (key === 'firstName' || key === 'lastName') {
                if (!isFieldValid(AT_LEAST_ONE_LETTER_REGEX, dataitem[key])) newErrObj[`${key}Err`].push(`${formatErrorFieldName(key)} has to consist of at least one letter.`);
                if (dataitem[key].length > 30) newErrObj[`${key}Err`].push(`${formatErrorFieldName(key)} cannot be over 30 characters long.`);
            }
            if (key === 'zipcode') {
                if (dataitem[key].length < 5) newErrObj[`${key}Err`].push(`${formatErrorFieldName(key)} has to be exactly 5 digits long.`);
            }
            if (key === 'dob') {
                const date = new Date(dataitem[key]);
                const year = date.getFullYear();
                const month = date.getMonth() + 1; // month indices start at 0 for January
                const day = date.getDate();
                const today = new Date();

                if ((dataitem.status === "" || dataitem.status === PRIMARY) && insuranceType === "medicare" && !isOverEighteen(year, month, day))
                    newErrObj[`${key}Err`].push("You must be over 18 to enroll.");

                if (today.getTime() < date.getTime()) newErrObj[`${key}Err`].push("Adding future dates is not available.");

                if (year < 1900) newErrObj[`${key}Err`].push("You cannot enter year below 1900.");

                if ((dataitem.status === "" || dataitem.status === PRIMARY) && !isOverEighteen(year, month, day))
                    newErrObj[`${key}Err`].push("You must be over 18 to enroll.");

                if ((dataitem?.status === SPOUSE || dataitem?.relationship === SPOUSE) && !isOverEighteen(year, month, day))
                    newErrObj[`${key}Err`].push("You must be over 18 to enroll.");

                if (dataitem[key].length < 0 || new Date(dataitem[key]).toString() === 'Invalid Date')
                    newErrObj[`${key}Err`].push("Please enter a valid date.");
            }
            if (key === 'sex' || key === 'dob' || key === 'race' || key === 'ethnicity' || key === 'language') {
                if (!dataitem[key]) newErrObj[`${key}Err`].push('Please select a valid response.');
            }
            // dataitem.preferredMobile === 'Mobile' &&
            if (key === 'phonenumber' && !isValidUSPhoneNumber(dataitem[key])) newErrObj[`${key}Err`].push(`The phone number is not valid`);
            if (key === 'email' && !isValidEmail(dataitem[key])) newErrObj[`${key}Err`].push(`The email is not valid.`);
        }
        if (dataitem.id && dataitem.id.includes(PRIMARY)) {
            const hasErrors = formatErrorIds(newErrObj).some(x => (newErrObj[x] && newErrObj[x].length > 0));
            const allErroredItems = formatErrorIds(newErrObj)
            setPrimaryErrors({ ...newErrObj, id: PRIMARY }, hasErrors);
            if (isInternal) setErrMsg();
            return { hasErrors, errIds: allErroredItems, errors: newErrObj };
        }
        if (dataitem.id && (dataitem.id.includes(DEPENDANT) || dataitem.id.includes(SPOUSE))) {
            const hasErrors = formatErrorIds(newErrObj).some(x => (newErrObj[x] && newErrObj[x].length > 0));
            const allErroredItems = formatErrorIds(newErrObj);
            setDependentsErrors({ id: dataitem.id, dependentsErrors: newErrObj, relationship: dataitem.relationship }, hasErrors);
            if (isInternal) setErrMsg();
            return { hasErrors, errIds: allErroredItems, errors: newErrObj };
        }
        if (!dataitem.id || dataitem.id === CURRENT || isMainInstance) {
            const hasErrors = formatErrorIds(newErrObj).some(x => (newErrObj[x] && newErrObj[x].length > 0));
            const allErroredItems = formatErrorIds(newErrObj);
            setCurrentErrors(newErrObj, hasErrors);
            if (isInternal) setErrMsg();
            return { hasErrors, errIds: allErroredItems, errors: newErrObj };
        }
    };
    const checkAll = (currentForm = false, insuranceType) => {
        const errorsCheck = [];
        const erroredForms = [];
        if (doesFormExist(formState.primary)) {
            const { hasErrors, errors } = check(formState.primary, false, false, insuranceType);
            if (hasErrors) {
                changeFocus(PRIMARY);
                const errIds = formatErrorIds(errors);
                getCoordinates(errIds[0], PRIMARY);
                erroredForms.push({ id: PRIMARY, errIds });
            }
            errorsCheck.push(hasErrors);
        }
        if (formState.dependents.length > 0) {
            for (const dep of formState.dependents) {
                const { hasErrors, errors } = check(dep, false, false, insuranceType);
                if (hasErrors) {
                    changeFocus(dep.id);
                    const errIds = formatErrorIds(errors);
                    erroredForms.push({ id: dep.id, errIds });
                    getCoordinates(errIds[0], dep.id);
                }
                errorsCheck.push(hasErrors);
            }
        }
        if (currentForm && formState.current) {
            const { hasErrors, errors } = check(formState.current, true, false, insuranceType);
            if (hasErrors) {
                changeFocus(CURRENT);
                const errIds = formatErrorIds(errors);
                erroredForms.push({ id: CURRENT, errIds });
                getCoordinates(errIds[0], CURRENT);
            }
            errorsCheck.push(hasErrors);
        }

        const hasErrors = errorsCheck.some(err => err);
        if (hasErrors) setErrMsg();
        setErrorExistence(hasErrors);
        return { hasErrors, erroredForms };
    }
    const isEveryFormSaved = () => {
        const current = formState.showCurrentForm ? formState.current.isFormSaved : true; // Saved if not shown
        const prim = formState.primary.isFormSaved;
        const deps = formState.dependents.map(d => d.isFormSaved);
        const returnValue = [prim, current, ...deps].every(x => x === true);
        return returnValue;
    }
    const getErrorObject = ({ id, isMainInstance }) => {
        if (isMainInstance || !id) return errors.currentErrors;
        if (id.includes(PRIMARY)) return errors.primaryErrors;
        if (id.includes(SPOUSE)) return errors.dependentsErrors.find(de => de.id === SPOUSE);
        if (id.includes(DEPENDANT)) return errors.dependentsErrors.find(de => de.id === id);
        return errors.currentErrors;
    }
    return (
        <BasicInfoContext.Provider value={{
            errors,
            setPrimaryErrors,
            setDependentsErrors,
            setCurrentErrors,
            check,
            checkAll,
            clearAllErrors,
            getErrorObject,
            additionalErrorMsg,
            clearMsg,
            setReminder,
            formState,
            formDispatch,
            changeValue,
            addPrimary,
            addSpouse,
            addDependant,
            updateDependent,
            changeFocus,
            clearCurrent,
            showCurrentForm,
            hideCurrentForm,
            prepareCurrentForm,
            removePersonCtx,
            saveForm,
            saveAll,
            isEveryFormSaved,
            loadForm
        }}>{children}</BasicInfoContext.Provider>
    )
}
