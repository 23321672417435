import axios from 'axios';
import {
    twFlag,
    spFlag,
    usFlag
} from '../../assets'
import { CURRENT, DEPENDANT, PRIMARY, SPOUSE } from '../../constants';
import states from "../forms/utils/states.json";
import HIPPA from "../../containers/Sign-In Document/SignedDocuments/HIPPA";
import PatientBillOfRights from "../../containers/Sign-In Document/SignedDocuments/PatientBillOfRights";
import PrivacyPolicy from "../../containers/Sign-In Document/SignedDocuments/PrivacyPolicy";
import ConsentToTreat from "../../containers/Sign-In Document/SignedDocuments/ConsentToTreat";


const INITIAL_ROUTE = "https://pvnemy7wuf.execute-api.us-east-1.amazonaws.com/PatientManage_V1";
export const isObjEmpty = (obj) => obj != null && Object.keys(obj).length > 0;
const isValidField = (str, regex) => str.match(regex);
export const isFieldValid = (regex, str) => regex.test(str);
// eslint-disable-next-line
const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{1,10})+$/; // Change the last 2 numbers to any min-max value agreed upon
const PHONE_REGEX = /^(\(\d{3}\) |\d{3}-)\d{3}-\d{4}$/;
const NAME_REGEX = /^[0-9A-Za-z-' \/.:()]{0,30}$/;
const ZIP_REGEX = /^\d+$/;
export const AT_LEAST_ONE_LETTER_REGEX = /[a-zA-Z]/;
export const isValidEmail = (str) => isValidField(str, EMAIL_REGEX);
export const isValidUSPhoneNumber = (str) => isFieldValid(PHONE_REGEX, str);
export const isValidZipCode = (str) => isValidField(str, ZIP_REGEX)
// "Name" in this case refers to ANY PROPER NOUN; This will work fine with cities (countries, mountains, rivers, etc as well)
export const isValidName = (str) => isValidField(str, NAME_REGEX)
export const isValidDate = (d) => d instanceof Date && !isNaN(d);
export const formatStatus = (status) => {
    switch (status) {
        case PRIMARY:
            return "Primary Account Holder";
        case SPOUSE:
            return "Spouse";
        case DEPENDANT:
            return "Dependant";
        case CURRENT:
            return "";
        default:
            return status;
    }
}

export const initForm = {
    firstName: "",
    lastName: "",
    dob: "",
    sex: "",
    email: "",
    phonenumber: "",
    preferredMobile: "",
    city: "",
    state: "",
    zipcode: "",
    addressLine1: "",
    addressLine2: "",
    race: "",
    ethnicity: "",
    language: "",
    status: "", // starts off with this
    relationship: "", // not needed for all forms
    /*primaryEmail: false,
    primaryPhone: false,
    primaryAddress: false*/
};

export const initErrors = {
    id: null,
    firstNameErr: [],
    lastNameErr: [],
    dobErr: [],
    sexErr: [],
    emailErr: [],
    phonenumberErr: [],
    preferredMobileErr: [],
    addressLine1Err: [],
    cityErr: [],
    stateErr: [],
    zipcodeErr: [],
    raceErr: [],
    ethnicityErr: [],
    languageErr: [],
}

export const sameAddress = [
    {
        id: 1,
        value: 1,
        text: "Yes"
    },
    {
        id: 2,
        value: 0,
        text: "No"
    }
]

export const nums = [{ id: '1', value: 'Mobile' }, { id: '2', value: 'Home' }];

//For mapping with the API's value
export const sexual = [
    {
        id: 'M',
        value: 'Male',
    },
    {
        id: 'F',
        value: 'Female'
    },
    {
        id: 'U',
        value: 'Prefer not to disclose'
    },
    {
        id: 'O',
        value: 'Other'
    }
];

export const racial = [
    {
        id: '1',
        value: 'American Indian or Alaska Native',
    },
    {
        id: '2',
        value: 'Asian'
    },
    {
        id: '3',
        value: 'Black of African American',
    },
    {
        id: '4',
        value: 'Native Hawailan or Other Pacific Islander',
    },
    {
        id: '5',
        value: 'Two or More Race'
    },
    {
        id: '6',
        value: 'Prefer not to disclose',
    }
];

export const ethnic = [
    {
        id: '1',
        value: 'White (Not Hispanic or Latino)',
    },
    {
        id: '2',
        value: 'Hispanic or Latino',
    },
    {
        id: '3',
        value: 'Prefer not to disclose'
    }
];

export const linguistic = [
    {
        id: '1',
        value: 'Spanish',
        icon: spFlag,
        key: "sp"
    },
    {
        id: '2',
        value: 'Mandarin',
        icon: twFlag,
        key: "zh"
    },
    {
        id: '3',
        value: 'American English',
        icon: usFlag,
        key: "en"
    }
];

export const relational = [
    // {
    //     id: 1,
    //     value: formatStatus(PRIMARY),
    // },
    {
        id: 2,
        value: formatStatus(DEPENDANT),
        status: DEPENDANT
    },
    {
        id: 3,
        value: formatStatus(SPOUSE),
        status: SPOUSE
    }
]

export const formatErrorFieldName = (fieldname) => {
    switch (fieldname) {
        case "firstName":
            return "First name"
        case "lastName":
            return "Last name";
        case "dob":
            return "Date of birth";
        case "email":
            return "Email";
        case "phonenumber":
            return "Phone";
        case "preferredMobile":
            return "Type of number";
        case "addressLine1":
            return "Address";
        case "addressLine2":
            return "Additional address information";
        case "city":
            return "City";
        case "zipcode":
            return "Zip code";
        case "sex":
            return "Sex";
        case "state":
            return "State";
        case "race":
            return "Race";
        case "ethnicity":
            return "Ethnicity";
        case "language":
            return "Preferred language";
        default:
            break;
    }
}

export const phoneNumberFormat = /(\d{3}) \d{3}-\d{4}/gm;

export const formatPhoneNumberInput = (value) => {
    // return nothing if no value
    if (!value) return value;
    // only allows 0-9 inputs
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;
    // if (!previousValue || value.length > previousValue.length) {
    // returns: "x", "xx", "xxx"
    if (cvLength < 4) return currentValue;
    // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
    if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
    // }
};

export const formatAddressInput = (value, previousValue) => {
    if (!value) return value;
    const num = parseInt(value.split(" ")[0]);
    if (num.length > 0 || isNaN(num)) return previousValue;
    else if (!isNaN(num)) return value;
    else return value;
}

export const extractDateFormat = (date) =>
    `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;

export const SPACING = {
    title: {
        top: 5,
        bottom: 3
    },
    bluetext: {
        top: 2,
        bottom: 7
    },
    formLabelLast: {
        bottom: 3,
    },
    radioItems: {
        top: 5,
        bottom: 3
    }
}

// CORS error on the backend - server-side issue

export const getExternalResources = async () => {
    const headers = {
        // "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "sourceFrom": "qweqwe",
        transactionID: "qweqwe",
        requestedDatetime: "05/07/2022",
        clientID: "qweqwe",
        Accept: "application/json",
        mode: 'no-cors'
        // "Access-Control-Allow-Headers" : "Content-Type, X-Amz-Date, Authorization, X-Api-Key, X-Amz-Security-Token",
        // "Access-Control-Allow-Methods": "OPTIONS,POST,GET"
    }
    const config = {
        method: 'GET',
        url: `${INITIAL_ROUTE}/resource?resourceType=GENDER,RACE,LANGUAGE,ETHNICITY`,
        headers
    };
    try {
        const res = await axios(config);

        return res.data
    } catch (e) {
        console.warn(e.message);
    }
}

export const formatSexValue = (val) => val === "M" ? "Male" : val === "F" ? "Female" : val === "O" ? "Other" : val === "U" ? "Unknown" : "";
export const formatStateValue = (val) => val;


export const isOverEighteen = (year, month, day) => {
    const now = parseInt(new Date().toISOString().slice(0, 10).replace(/-/g, ''));
    const dob = year * 10000 + month * 100 + day * 1; // Coerces strings to integers
    return now - dob > 180000;
}


export const getNYearsAgo = (yearsAgo) => {
    const nowDate = new Date(Date.now());
    nowDate.setFullYear(nowDate.getFullYear() - parseInt(yearsAgo));
    return nowDate.toISOString().split("T")[0];
}

export const getYearsOfPerson = (date) => {
    const dob = new Date(date);
    const monthDiff = Date.now() - dob.getTime();
    const ageDt = new Date(monthDiff);
    const year = ageDt.getUTCFullYear();
    const age = Math.abs(year - 1970);
    return age;
}

export const formFillCheck = (form) => {
    let x = [];
    for (const key of Object.keys(form)) {
        if (
            key === 'relationship' ||
            key === 'status' ||
            key === 'id' ||
            key === 'addressLine2' ||
            key === 'isFormSaved' ||
            key.startsWith('primary')
        ) continue
        if (form[key].length > 0) x.push(true);
        else x.push(false);
    }
    return x.every(z => z);
}

export const doesFormExist = (form) => {
    let x = [];
    for (const key of Object.keys(form)) {
        if (
            key === 'relationship' ||
            key === 'status' ||
            key === 'id' ||
            key === 'addressLine2' ||
            key === 'isFormSaved' ||
            key.startsWith('primary')
        ) continue
        if (form[key].length > 0) x.push(true);
        else x.push(false);
    }
    return x.some(z => z);
}

export const getSexId = (val, slist) => (slist.find(item => item.value === val))?.value;
export const getStateCode = (val) => (states.find(st => st.value === val))?.code;
export const getEthnicityId = (val, elist) => (elist.find(st => st.value === val))?.id;
export const getRaceId = (val, rlist) => (rlist.find(st => st.value === val))?.id;
export const getLanguageId = (val, llist) => (llist.find(st => st.value === val))?.id;

export const generateRequestBody = (
    form,
    status,
    insuranceType,
    hasDeps = false,
    primaryObject,
    slist = [],
    elist = [],
    rlist = [],
    llist = []
) => {
    const sexId = getSexId(form.sex, slist);
    const stateCode = getStateCode(form.primaryAddress ? primaryObject.state : form.state);
    const ethnicityId = getEthnicityId(form.ethnicity, elist);
    const raceId = getRaceId(form.race, rlist);
    const languageId = getLanguageId(form.language, llist);

    const dayPhoneNumberToUse = (form.primaryPhone && form.preferredMobile !== 'Mobile') ? primaryObject.phonenumber : form.preferredMobile !== 'Mobile' ? form.phonenumber : '';
    const cellPhoneNumberToUse = (form.primaryPhone && form.preferredMobile === 'Mobile') ? primaryObject.phonenumber : form.preferredMobile === 'Mobile' ? form.phonenumber : '';

    const dateOfBirth = new Date(form.dob).toISOString().replace("00.000Z", "00Z");
    return {
        patientId: form?.patientId ? form.patientId : "",
        patientOrder: 0,
        patientType: status,
        firstName: form.firstName,
        lastName: form.lastName,
        dateOfBirth,
        emailAddress: form.primaryEmail ? primaryObject.email : form.email,
        dayphone: dayPhoneNumberToUse,
        cellphone: cellPhoneNumberToUse,
        addressLine1: form.primaryAddress ? primaryObject.addressLine1 : form.addressLine1,
        addressLine2: form.primaryAddress ? primaryObject.addressLine2 : form.addressLine2,
        city: form.primaryAddress ? primaryObject.city : form.city,
        state: stateCode,
        zip: form.primaryAddress ? primaryObject.zipcode : form.zipcode,
        sex: sexId,
        raceId: raceId,
        ethnicityId: ethnicityId,
        languageId: languageId,
        primaryCareProviderId: "",
        isSelfPayer: insuranceType === 'selfpay' ? true : false,
        policyNumber: "",
        planNumber: "",
        groupName: "",
        groupNumber: "",
        insuranceType: insuranceType.includes("third-party_") ? localStorage.getItem("insuranceType") : insuranceType,
        isPatientInsurance: true,
        isActive: true,
        policyEffectiveDate: "",
        policyExpirationDate: "",
        payerId: "",
        payerName: form.firstName + ' ' + form.lastName,
        //     enrollmentType: "Online",
        // enrollmentLocation: "Portsmouth",
        // enrolledBy: "cmd@cmd.com",
        enrollmentType: insuranceType.includes("Admin_") ? "UrgentCare" : insuranceType.includes("third-party_") ? "EmployerGroup" : 'online',

        enrollmentLocation : insuranceType.includes("Admin_") ? insuranceType.split("Admin_")[1] : insuranceType.includes("third-party_") ? insuranceType.split("third-party_")[1] : '',

        enrolledBy:(insuranceType.includes("Admin_") || insuranceType.includes("third-party_"))?  localStorage.getItem('leadEmail') : '',
        
        dependents: hasDeps ? [] : null
    }
}

export const INITIAL_FORM_STATE = {
    primary: {
        id: PRIMARY,
        firstName: "",
        lastName: "",
        dob: "",
        sex: "",
        email: "",
        phonenumber: "",
        preferredMobile: "",
        city: "",
        state: "",
        zipcode: "",
        addressLine1: "",
        addressLine2: "",
        race: "",
        ethnicity: "",
        language: "",
        status: "",
        relationship: "",
        isFormSaved: false
    },
    dependents: [],
    current: {
        id: CURRENT,
        firstName: "",
        lastName: "",
        dob: "",
        sex: "",
        email: "",
        phonenumber: "",
        preferredMobile: "",
        city: "",
        state: "",
        zipcode: "",
        addressLine1: "",
        addressLine2: "",
        race: "",
        ethnicity: "",
        language: "",
        status: "",
        relationship: "",
        primaryEmail: false,
        primaryPhone: false,
        primaryAddress: false,
        isFormSaved: false
    },
    focused: null, // currently focused form
    showCurrentForm: true
}

export const formTypes = {
    CHANGE_VALUE: "CHANGE_VALUE",
    ADD_PRIMARY: "ADD_PRIMARY",
    UPDATE_PRIMARY: "UPDATE_PRIMARY",
    UPDATE_DEP: "UPDATE_DEP",
    ADD_DEP: "ADD_DEP",
    CHANGE_FOCUS: "CHANGE_FOCUS",
    CLEAR_CURRENT: "CLEAR_CURRENT",
    SET_CURRENT_FORM: "SET_CURRENT_FORM",
    PRE_FILL_CURRENT_FORM: "PRE_FILL_CURRENT_FORM",
    REMOVE_PERSON: "REMOVE_PERSON",
    SAVE_FORM: "SAVE_FORM",
    SAVE_ALL: "SAVE_ALL",
    LOAD_FORM: "LOAD_FORM"
}

export function formReducer(state, { type, payload }) {
    switch (type) {
        case formTypes.SAVE_ALL:
            return {
                ...state,
                primary: { ...state.primary, isFormSaved: true },
                current: { ...state.current, isFormSaved: true },
                dependents: state.dependents.map(dep => ({ ...dep, isFormSaved: true }))
            }
        case formTypes.SAVE_FORM: {
            const newPrim = payload === PRIMARY ? { ...state.primary, isFormSaved: true } : state.primary;
            const newCurrent = payload === CURRENT ? { ...state.current, isFormSaved: true } : state.current;
            const newDeps = (payload.includes(SPOUSE) || payload.includes(DEPENDANT)) ?
                state.dependents.map(dep => dep.id === payload ? { ...dep, isFormSaved: true } : dep) : state.dependents;
            return {
                ...state,
                primary: newPrim,
                current: newCurrent,
                dependents: newDeps
            };
        }
        case formTypes.CHANGE_VALUE: {
            const { id, key, value } = payload;
            const primary = id && id === PRIMARY ? { ...state.primary, [key]: value, isFormSaved: false } : state.primary;
            const current = id && id === CURRENT ? { ...state.current, [key]: value, isFormSaved: false } : state.current;
            const dependents = id && (id.includes(DEPENDANT) || id === SPOUSE) ? state.dependents.map(dep => dep.id === id ? { ...dep, [key]: value, isFormSaved: false } : dep) : state.dependents;
            return {
                ...state,
                primary,
                current,
                dependents
            };
        }
        case formTypes.UPDATE_PRIMARY:
        case formTypes.ADD_PRIMARY: {
            const { val, next } = payload;
            return {
                ...state,
                primary: { ...val, isFormSaved: true },
                current: {
                    ...state.current,
                    relationship: next,
                    status: next
                },
                focused: null
            };
        }
        case formTypes.UPDATE_DEP:
        case formTypes.ADD_DEP: {
            const { val, next } = payload;
            const doesItemExist = state.dependents.find(dep => dep.id === val.id);
            const newDependents = doesItemExist ? state.dependents.map(de => de.id === val.id ? { ...val, isFormSaved: true } : de) : [...state.dependents, { ...val, isFormSaved: true }];
            return {
                ...state,
                dependents: newDependents,
                // doesItemExist ? state.dependents.map(de => de.id === val.id ? { ...val, isFormSaved: true } : de) : [...state.dependents, { ...val, isFormSaved: true }],
                current: {
                    ...state.current,
                    relationship: next,
                    status: next
                },
                focused: null
            }
        }
        case formTypes.REMOVE_PERSON: {
            return {
                ...state,
                dependents: state.dependents.filter(dep => dep.id !== payload ? dep : console.log("TAJ CAJLD", dep)),
                focused: null,
            }
        }
        case formTypes.CHANGE_FOCUS: {
            if (!payload) {
                // Assume everything is saved when nothing is focused
                return {
                    ...state,
                    primary: { ...state.primary, isFormSaved: true },
                    current: { ...state.current, isFormSaved: true },
                    dependents: state.dependents.map(dep => ({ ...dep, isFormSaved: true })),
                    focused: payload
                };
            }
            const newPrim = payload === PRIMARY ? { ...state.primary, isFormSaved: false } : state.primary;
            const newDeps = (payload.includes(DEPENDANT) || payload.includes(SPOUSE)) ? state.dependents.map(de => de.id === payload ? { ...de, isFormSaved: false } : de) : state.dependents;
            const newCurr = payload === CURRENT ? { ...state.current, isFormSaved: false } : state.current;
            return {
                ...state,
                primary: newPrim,
                dependents: newDeps,
                current: newCurr,
                focused: payload
            };
        }
        case formTypes.LOAD_FORM: {
            const { id, form } = payload;
            const newPrimary = id === PRIMARY ? form : state.primary;
            const newDependents = (id.includes(DEPENDANT) || id.includes(SPOUSE)) ? state.dependents.map(dep => dep.id === id ? form : dep) : state.dependents;
            return {
                ...state,
                primary: newPrimary,
                dependents: newDependents
            }
        }
        case formTypes.SET_CURRENT_FORM: return { ...state, showCurrentForm: payload };
        case formTypes.PRE_FILL_CURRENT_FORM: return { ...state, current: payload };
        case formTypes.CLEAR_CURRENT:
            return {
                ...state,
                current: {
                    id: CURRENT,
                    firstName: "",
                    lastName: "",
                    dob: "",
                    sex: "",
                    email: "",
                    phonenumber: "",
                    preferredMobile: "",
                    city: "",
                    state: "",
                    zipcode: "",
                    addressLine1: "",
                    addressLine2: "",
                    race: "",
                    ethnicity: "",
                    language: "",
                    status: "",
                    relationship: "",
                    primaryEmail: false,
                    primaryPhone: false,
                    primaryAddress: false
                }
            }
        default: return state;
    }
}

export function spinNewDepId(isEdit, id, depIds) {
    if (isEdit) return id;
    const validDepIds = [`${DEPENDANT}-0`, `${DEPENDANT}-1`, `${DEPENDANT}-2`, `${DEPENDANT}-3`, `${DEPENDANT}-4`, `${DEPENDANT}-5`, `${DEPENDANT}-6`, `${DEPENDANT}-7`, `${DEPENDANT}-8`, `${DEPENDANT}-9`];
    const difference = validDepIds.filter(vdid => !depIds.includes(vdid));
    for (const depId of difference) { if (depId !== id) return depId; }
}

export function formatErrorIds(errObj) {
    const newErrIdObj = [];
    for (const id of Object.keys(errObj)) {
        if (id === "id") continue;
        if (errObj[id].length > 0) newErrIdObj.push(id);
    }
    return newErrIdObj;
}

export const generateForm = (form, racial = [], ethnic = [], linguistic = []) => {
    return {
        firstName: form.firstName,
        lastName: form.lastName,
        dob: new Date(form.dateOfBirth).toJSON().substring(0, 10),
        email: form.emailAddress,
        phonenumber: formatPhoneNumberInput(form.cellphone !== "" ? form.cellphone : form.dayPhoneNumber),
        preferredMobile: form.cellphone ? "Mobile" : "Home",
        city: form.addresses[0].city,
        state: states.find(item => item.code === form.addresses[0].state)?.value ?? "",
        zipcode: form.addresses[0].zip,
        addressLine1: form.addresses[0].addressLine1,
        addressLine2: form.addresses[0].addressLine2,
        sex: (form.sex),
        race: racial.find(item => item.id === form.raceId)?.value ?? "",
        ethnicity: ethnic.find(item => item.id === form.ethnicityId)?.value ?? "",
        language: linguistic.find(item => item.id === form.languageId)?.value ?? "",
        status: form.status, // starts off with this
        relationship: ""
    }
}

export const generateSignDocObject = (form) => {
    return (
        [
            {
                'type': 'hippa',
                'checked': form.isHippa ? true : false,
                'toggleiframe': false,
                'text': 'HIPPA',
                'Itemid': 1,
                'iframeFlag': false,
                'iframe': <HIPPA />
            },
            {
                'type': 'rights',
                'checked': form.isPatientBillOfRights ? true : false,
                'text': 'Patient Bill of Rights',
                'Itemid': 2,
                'iframeFlag': false,
                'iframe': <PatientBillOfRights />
            },
            {
                'type': 'consent',
                'checked': form.isConsentToTreat ? true : false,
                'text': 'Consent to Treat',
                'Itemid': 4,
                'iframeFlag': false,
                'iframe': <ConsentToTreat />
            },
            {
                'type': 'privacy',
                'checked': form.isPrivacyPolicy ? true : false,
                'toggleiframe': false,
                'text': 'Privacy Policy',
                'Itemid': 5,
                'iframeFlag': false,
                'iframe': <PrivacyPolicy />
            }
        ]
    )
}
