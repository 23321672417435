export const getProgressFromPage = (pageName) => {
    switch (pageName) {
        case "landing":
        case "landingPage":
            return 1;
        case 'basicInfoPage':
        case 'basicInfo':
            return 2;
        case 'insurancePage':
        case 'insurance':
            return 3;
        case 'practitionersPage':
        case 'practitioners':
            return 4;
        case 'signDocumentPage':
        case 'signDocument':
            return 5;
        // forgot how the routing flow works for these pages that come a bit later... someone who does should take a look into this
        // case 'confirmEnrollmentPage':
        //     return 6;
        // case 'successEnrollmentPage':
        //     return 7;
        default:
            return false;
    }
}