import States from '../components/forms/utils/states.json';
import { PRIMARY} from '../constants';

export const formatPatientPayload=(globalObject,insuranceType,resources,isFinalSubmit)=>{
    const primaryPatientBasicInfo = {...globalObject.primaryDetails[0].basicInfo,patientId:JSON.parse(localStorage.getItem("RES_FOR_PATIENT_POST"))?.patientId};
    const primaryInsuranceInfo = globalObject.primaryDetails[0].insurance;
    const primaryProviderInfo = globalObject.primaryDetails[0].careProvider;
    const patientPayload = payloadObject(primaryPatientBasicInfo,primaryInsuranceInfo,primaryProviderInfo,isFinalSubmit,insuranceType,true,resources);
    if(globalObject.dependentDetails?.length > 0){
        globalObject.dependentDetails.forEach(dependentElement => {
            const dependentBasicInfo = {...dependentElement.basicInfo,payerName:`${primaryPatientBasicInfo.firstName} ${primaryPatientBasicInfo.lastName}`};
            const dependentInsuranceInfo = {...dependentElement.insurance,groupNumber:`${primaryInsuranceInfo.groupNumber??""}`};
            const dependentProviderInfo = dependentElement.careProvider;
            const payload=payloadObject(dependentBasicInfo,dependentInsuranceInfo,dependentProviderInfo,isFinalSubmit,insuranceType,false,resources);
            patientPayload.dependents.push({ "dependentType": dependentElement.basicInfo.status, "details": payload });
        })
    }
    return patientPayload;
}

export const payloadObject=(basicform,insuranceForm,providerform,isFinalSubmit,insuranceType,hasDeps,resources)=>{
   
    const race =resources[0].values ?? [];
    const ethnicity = resources[2].values ?? [];
    const language = resources[3].values ?? [];
    const getRaceId =(val) => (race.find(st => st.value === val))?.id;
    const getEthnicityId = (val) => (ethnicity.find(st => st.value === val))?.id;
    const getLanguageId = (val) => (language.find(st => st.value === val))?.id;
    
    const payloadObject = {
        patientId: basicform?.patientId ? basicform.patientId: "",
        patientOrder: 0,
        patientType: basicform.status,
        firstName: basicform.firstName,
        lastName: basicform.lastName,
        dateOfBirth: new Date(basicform.dob).toISOString().replace("00.000Z","00Z"),
        emailAddress: basicform.email,
        dayphone: basicform.preferredMobile!== 'Mobile' ? basicform.phonenumber : '',
        cellphone: basicform.preferredMobile=== 'Mobile' ? basicform.phonenumber : '',
        addressLine1: basicform.addressLine1 ?? "",
        addressLine2: basicform.addressLine2 ?? "",
        city: basicform.city ?? "",
        state: (States.find(st => st.value === basicform.state))?.code,
        zip: basicform.zipcode,
        sex: basicform.sex,
        raceId: getRaceId(basicform.race),
        ethnicityId: getEthnicityId(basicform.ethnicity),
        languageId: getLanguageId(basicform.language),
        primaryCareProviderId: providerform.providerID ?? "",
        location:providerform.location?providerform.location.split(",")[0]:"",
        locationId:providerform.locationId ?? "",
        isSelfPayer: insuranceType === 'selfpay' ? true : false,
        isFinalSubmission: isFinalSubmit,
        policyNumber:insuranceForm.memberId ?? "",
        planNumber: insuranceForm.memberId ?? "",
        groupName: "",
        groupNumber: insuranceForm.groupNumber ?? "",
        insuranceType: insuranceType.includes("third-party_") ? localStorage.getItem("insuranceType") : insuranceType,
        isPatientInsurance: true,
        isActive: true,
        policyEffectiveDate: "",
        policyExpirationDate: "",
        payerId: "",
        isHippa:isFinalSubmit?true:false,
        isPatientBillOfRights:isFinalSubmit?true:false,
        isConsentToTreat:isFinalSubmit?true:false,
        isPrivacyPolicy:isFinalSubmit?true:false,
        isSelfPayPolicy:insuranceType === 'selfpay' ? true : false,
        payerName:basicform.status===PRIMARY? (basicform.firstName + ' ' + basicform.lastName):basicform.payerName,
        dependents: hasDeps ? [] : null,
        enrolledByEmployeeEmail: localStorage.getItem('leadEmail'),

        enrollmentType: insuranceType.includes("Admin_") ? "UrgentCare" : insuranceType.includes("third-party_") ? "EmployerGroup" : 'online',

        enrollmentLocation : insuranceType.includes("Admin_") ? insuranceType.split("Admin_")[1] : insuranceType.includes("third-party_") ? insuranceType.split("third-party_")[1] : '',

        enrolledBy:(insuranceType.includes("Admin_") || insuranceType.includes("third-party_"))?  localStorage.getItem('leadEmail') : '',
    }
   
    return payloadObject;
}