import axios from "axios";

export const authResponse = async () => await axios.post(
  process.env.REACT_APP_TOKEN_URL,
  new URLSearchParams({
    'client_id': process.env.REACT_APP_CLIENT_ID,
    'client_secret': process.env.REACT_APP_CLIENT_SECRET,
    'grant_type': process.env.REACT_APP_GRANT_TYPE,
    'redirect_uri': process.env.REACT_APP_REDIRECT_URI,
    'scope': process.env.REACT_APP_SCOPE
  })
);
