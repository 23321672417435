import { GrDown, GrUp  } from 'react-icons/gr';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';

const PatientIframe = ({item, iframeDropdown, personDetails, mainItem, updateDetailsArray, index }) => {
    return (
        <div>
            <div className='checkbox approval-padding-5'>
                <div className='approval-box'  style={{ backgroundColor: item.iframeFlag ? '#FEB52B' : 'white'}}>
                <div className="approval-flex-container" >
                    <div className="flex-item-left">
                    <button 
                        className='signDoc-iframe-dropdown' 
                        style={{ backgroundColor: item.iframeFlag ? '#FEB52B' : 'white'}} 
                        onClick={()=> iframeDropdown(personDetails, item, mainItem.id)}
                    >
                        {item.iframeFlag ? <GrUp /> : <GrDown />}
                    </button> 
                    &nbsp; &nbsp; {item.text}
                    </div>
                    <Form.Check 
                        type="checkbox"
                        id={'inputRadioAcknow' + index} 
                        name={item.type}
                        checked={item.checked} 
                        role="checkbox"
                        onChange={(e) => updateDetailsArray(e, personDetails, item, mainItem.id)}
                    />
                </div>
                </div>
            </div>
            {item.iframeFlag &&
                <div className={item.Itemid === 1 ? "iframe-text_jottform" : "iframe-text"} readOnly  role="listitem">
                    {item.iframe}
                </div>
            }
        </div>
    )
}

PatientIframe.propTypes = {
    item: PropTypes.object, 
    iframeDropdown: PropTypes.func,
    personDetails: PropTypes.array,
    mainItem: PropTypes.object,
    updateDetailsArray: PropTypes.func,
    index: PropTypes.string
};
export default PatientIframe;