import Button from 'react-bootstrap/Button'
import { useTranslation } from 'react-i18next';
import {useNavigate,useLocation } from 'react-router-dom';
import { S3BUCKET_NOIMAGE_URL } from '../../constants/urlEndpoint';
import './PractitionerProfileCard.css';
import PropTypes from 'prop-types';

function PractitionerProfileCard(props){
  const { t } = useTranslation();
  const navigate = useNavigate();
  const locationType=useLocation();
  const params = new URLSearchParams(locationType.search);
  const insuranceType=params.get("type");

  const {selectPractitioner,select,practitioner_img,details,client_type, biography}=props;

  const onViewprofile = (e, name, id) => {
    e.stopPropagation();
    navigate("/practitioners/"+name+"?type="+insuranceType, { state: {...details ,profile_img:practitioner_img,client_type:client_type, biography:biography }}) 
  }
 
  return(
    <div data-testid="card-select-test" className={select ? "practitioner-card-selected" : details.isAcceptingPatient === true ? "practitioner-card" : "practitioner-card flex-order"} onClick={ details.isAcceptingPatient === true &&  selectPractitioner}>
      <img alt="profile" className='practitiner-card-image' src={practitioner_img} onError={event => {
          event.target.src = S3BUCKET_NOIMAGE_URL
          event.onerror = null
        }}/>         
      <p className="practitioner-name">{details.firstName}<br></br>{details.lastName}, {details.degree}</p>
      {details.isAcceptingPatient === false ? <p className="practitioner-error">Not accepting new patients </p> : <p className="practitioner-text">Accepting new patients </p>}
      <p className={'view-profile-link'} onClick={(e)=>onViewprofile(e, details.firstName+" "+details.lastName,details.id)}>{ t('practitioner.profile.viewprofile') }</p>  
     
      <Button variant="outline-dark" className={ details.isAcceptingPatient === false ? 'practitiner-card-button-disabled practitiner-card-button':'practitiner-card-button'}>{select ? t('practitioner.profile.selected') : t('practitioner.profile.select')}</Button>
    </div>
  )
}

PractitionerProfileCard.propTypes = {
  selectPractitioner: PropTypes.func,
  select: PropTypes.bool,
  practitioner_img: PropTypes.string,
  details: PropTypes.object,
  client_type: PropTypes.string,
  biography: PropTypes.object,
};

export default PractitionerProfileCard;