import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next";

import Footer from "../../components/footer/Footer";
import ProgressBar from "../../components/progressBar/ProgressBar";
import AccordionComponent from "../../components/accordion/AccordionComponent";
import PageHeading from "../../components/pageHeader/PageHeading";
import InputField from "../../components/InputField/Inputfield";
import { Spinner } from "react-bootstrap";
import Form from 'react-bootstrap/Form';

import InsuranceCard from '../../assets/images/insurance-card.webp';
import MedicareCard from '../../assets/images/medicare-card.webp';

import { ApiCall } from '../../utils/ApiCall';
import { useLocalStorage, useQuery } from "../../hooks";
import { formatPatientPayload } from "../../utils/formatPatientPayload";
import { formatMedicareNumber } from ".";

import { PUT } from '../../utils';
import { BASE_URL_PATIENT, DEPENDANT, PRIMARY, SPOUSE } from '../../constants';

import './InsurancePage.css'

function InsurancePage() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch(); 
    const insuranceType = useQuery().get('type');
    const [_, setProgress, removeProgress] = useLocalStorage('progressbar', 1);

    const routingPath = {
        next: `/practitioners`,
        back: '/basic-info'
    }

    const resources = useSelector(state => state.patientInfo.resources);
    const patientInfo = useSelector(state => state.patientInfo);

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(true);
    const [errorMessageField, setErrorMessageField] = useState("");
    const [isRadioButonDisabled, setIsRadioButtonDisabled] = useState(patientInfo.primaryDetails[0].insurance.memberId ? false : true);

    const patientInsuranceData = {
        "primaryDetails" :[
            {
              "insurance":{
                "memberId":patientInfo.primaryDetails[0].insurance?.memberId ?? "",
                "groupNumber":patientInfo.primaryDetails[0].insurance?.groupNumber ?? ""
              },
            }
          ] ,
          "dependentDetails":[]
    }
    for (let value of patientInfo.dependentDetails) {
        patientInsuranceData.dependentDetails.push(
            {
            "insurance":
                {
                    "memberId": value?.insurance?.memberId ?? "",
                    "SameMemberId": value?.insurance?.SameMemberId ?? false
                },
            }
        )
    }

    const [insuranceDetails, setInsuranceDetails] = useState(patientInsuranceData);

    const [callPutMethod, setCallPutMethod]=useState(true)
    const insuranceFormCallback = (index, field, value, event, type, isMedicare = false) => {
        const regexPattern = /[^A-Za-z0-9]/g;
        let newInsuranceDetailsData = insuranceDetails;  
        let newDependantDetailsData = patientInfo.dependentDetails;
        
        if (type === "PRIMARY") {
            newInsuranceDetailsData.primaryDetails[0].insurance[field] = value;

            if (field === "memberId") {
                if (value === "") {
                    setIsRadioButtonDisabled(true);
                } else {
                    setIsRadioButtonDisabled(false);
                }
            }
            if (event === "onChange") {
                newInsuranceDetailsData.primaryDetails[0].insurance.memberId = isMedicare ?
                    formatMedicareNumber(newInsuranceDetailsData.primaryDetails[0].insurance.memberId.replace(regexPattern, "")) :
                    newInsuranceDetailsData.primaryDetails[0].insurance.memberId.replace(regexPattern, "");

                    newInsuranceDetailsData.primaryDetails[0].insurance.groupNumber = newInsuranceDetailsData.primaryDetails[0].insurance.groupNumber.replace(regexPattern, "");

                if(newInsuranceDetailsData && newInsuranceDetailsData.dependentDetails && field === "memberId"){
                    let shouldUpdateDependats = false;

                    insuranceDetails.dependentDetails.forEach((item, key) => {
                        if (newInsuranceDetailsData.dependentDetails[key].insurance.SameMemberId) {
                            shouldUpdateDependats = true;
                            newInsuranceDetailsData.dependentDetails[key].insurance.memberId = value.replace(regexPattern, "");
                            newDependantDetailsData[key].insurance.memberId = value.replace(regexPattern, "");
                        }
                    });

                    if(shouldUpdateDependats) {
                        dispatch({ type: "UPDATE_INSURANCE_DETAILS_DEPENDENT", payload: newDependantDetailsData });
                    }
                }      
            }   
            
            setInsuranceDetails(prev => ({ ...prev, primaryDetails: newInsuranceDetailsData.primaryDetails }));
            dispatch({ type: "UPDATE_INSURANCE_DETAILS_PRIMARY", payload: newInsuranceDetailsData });

        } else if (type === DEPENDANT || type === SPOUSE) {
            let newDependantDetailsData = patientInfo.dependentDetails;
            if (event === "onChange") {
                newInsuranceDetailsData.dependentDetails[index].insurance[field] = value.replace(regexPattern, "");
                newDependantDetailsData[index].insurance = newInsuranceDetailsData.dependentDetails[index].insurance;
                newDependantDetailsData[index].insurance.SameMemberId = false;
            }
            setInsuranceDetails(prev=> ({  ...prev, dependentDetails: newInsuranceDetailsData.dependentDetails }))
            dispatch({ type: "UPDATE_INSURANCE_DETAILS_DEPENDENT", payload: newDependantDetailsData });
        } 
        setCallPutMethod(true);    
    }

    const onCheckRadioBtn = (e, index, type) => {
        let newInsuranceDetailsData = insuranceDetails;   
        let newDependantDetailsData = patientInfo.dependentDetails;

        if (e.target.checked) {
            if (type === DEPENDANT || type === SPOUSE){
                newInsuranceDetailsData.dependentDetails[index].insurance.memberId = insuranceDetails.primaryDetails[0].insurance.memberId;
                newInsuranceDetailsData.dependentDetails[index].insurance.SameMemberId = true;
                newDependantDetailsData[index].insurance = newInsuranceDetailsData.dependentDetails[index].insurance;

                setInsuranceDetails(prev => ({ ...prev, dependentDetails: newInsuranceDetailsData.dependentDetails }));
                dispatch({ type: "UPDATE_INSURANCE_DETAILS_DEPENDENT", payload: newDependantDetailsData});
            }
        } else {
            if(type === DEPENDANT || type === SPOUSE){
                newInsuranceDetailsData.dependentDetails[index].insurance.memberId = "";
                newInsuranceDetailsData.dependentDetails[index].insurance.SameMemberId = false;

                newDependantDetailsData[index].insurance = newInsuranceDetailsData.dependentDetails[index].insurance;
                setInsuranceDetails(prev => ({ ...prev, dependentDetails: newInsuranceDetailsData.dependentDetails }));
                dispatch({ type: "UPDATE_INSURANCE_DETAILS_DEPENDENT", payload: newDependantDetailsData });
            }
        }
        setCallPutMethod(true);
    }

    const handleCallback = (ref,id) => {};

    const insuranceValidation = () => {
        localStorage.setItem("patient_details", JSON.stringify(insuranceDetails));
        const medicareRegex = new RegExp(/^[0-9]{1}[a-zA-Z]{1}[a-zA-Z0-9]{1}[0-9]{1}[/-]{1}[a-zA-Z]{1}[a-zA-Z0-9]{1}[0-9]{1}[/-]{1}[a-zA-Z]{2}[0-9]{2}$/);

        let groupIdRegExValidation = true;
        let bPrimaryValidation = true;
        let memberIdRegExValidation = true;
        
        if (insuranceType !== 'medicare') {
            memberIdRegExValidation = insuranceDetails?.primaryDetails[0]?.insurance?.memberId?.length >= 3 &&  insuranceDetails?.primaryDetails[0]?.insurance?.memberId?.length <= 250 ? true : false;
            if (insuranceDetails?.primaryDetails[0]?.insurance?.groupNumber !== "")
                groupIdRegExValidation = insuranceDetails?.primaryDetails[0]?.insurance?.groupNumber?.length >= 1 && insuranceDetails?.primaryDetails[0]?.insurance?.groupNumber?.length <= 250 ? true : false;
            bPrimaryValidation = (memberIdRegExValidation && groupIdRegExValidation) ? true : false;
        } else bPrimaryValidation = medicareRegex.test(insuranceDetails?.primaryDetails[0]?.insurance?.memberId);

        let bDependentValidation = true;
        
        if (patientInfo?.dependentDetails?.length>0) {
            for (let i = 0; i < patientInfo?.dependentDetails?.length; i++) {
                if (!(insuranceDetails?.dependentDetails[i]?.insurance?.memberId?.length >= 3 && insuranceDetails?.dependentDetails[i]?.insurance?.memberId?.length <= 250 && insuranceDetails?.dependentDetails[i]?.insurance?.memberId !== "")) {
                    bDependentValidation = false;
                    break;
                }         
            }
        }
        if (insuranceType === "medicare") {
            setErrorMessage(bPrimaryValidation);
            if (bPrimaryValidation) {
                if(callPutMethod) postInsurance(bPrimaryValidation); 
                else goToNextPage();
            }
            else return bPrimaryValidation;
        } else {
            setErrorMessage(bPrimaryValidation && bDependentValidation);
            setErrorMessageField(() => {
                if ((!bDependentValidation && !groupIdRegExValidation) || (!memberIdRegExValidation && !groupIdRegExValidation)) 
                    return "both";
                if(!groupIdRegExValidation) return "groupnumber";
                if (!bDependentValidation || !memberIdRegExValidation) return "memberid";
            });
            if (bPrimaryValidation && bDependentValidation) {
                if (callPutMethod) postInsurance(bPrimaryValidation && bDependentValidation);
                else goToNextPage();
            } else return (bPrimaryValidation  && bDependentValidation);
        }
    }
    
    /* istanbul ignore next */
    const postInsurance = async(value) => {
        const patientPayload = formatPatientPayload(patientInfo ,insuranceType, resources, false);     
        
        if(value){
            setIsLoading(true);
           await ApiCall(BASE_URL_PATIENT+'1',patientPayload , PUT, null, function (response) {
                if(response.status === 200){
                    if(response.data?.errorCode==="AppNG_1304"){
                        navigate('/successEnrollmentCall?errorCode=AppNG_1304');
                        return false;
                    }
                    setIsLoading(false);
                    goToNextPage();
                }else if (response.status === 409 || response.status === 417) {
                    if (response.data?.errorCode === "NG_1002" || response.data?.errorCode === "AppCMD_1002") {
                        navigate({ pathname: '/existingUser', search: '?type='+insuranceType });
                    }
                } else {
                    setIsLoading(false);
					navigate('/error');
                    return false;
				}
            });
        }
    };
    const goToNextPage = () => {
        const newProgress = 4;
        removeProgress();

        setProgress(newProgress.toString());

        if (insuranceType === "medicare") {
            navigate({
                pathname: routingPath.next,
                search: '?type=medicare',
            })
        } else if (insuranceType?.includes("third-party_")) {
            navigate({
                pathname: routingPath.next,
                search: `?type=${insuranceType}`,
            })
        } else {
            navigate({
                pathname: routingPath.next,
                search: '?type=anthem',
            });
        }
    }

    return (
        <>
            {isLoading &&
                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <Spinner style={{ color: "#264E5A", width: "5rem", height: "5rem", borderWidth: "0.5rem", marginTop: "70px"}} animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            }
            {!isLoading &&
                <>
                <div className={insuranceType === "medicare" ? "insurancepage-main-medicare  container-xl" :" insurancepage-main container-xl"}>
                    <ProgressBar currentPage="insurance" />
                    <PageHeading title={t('insurance.page_heading')} description={t('insurance.description')} />
                    <img 
                        src={insuranceType === "medicare" ? MedicareCard : InsuranceCard} 
                        alt="insurance card" 
                        className={insuranceType === "medicare" ? "insurance-card-img-medicare" : "insurance-card-img"} 
                        width={486}
                        height={317}
                    />
                    
                    {insuranceType === "medicare" ? 
                        <div className=" medicare-form-css">
                            <InputField
                                type="search"
                                label_title="Medicare Number"
                                // maxLength="11"
                                testId="textInput-medicareNumber"
                                placeholder="XXXX-XXX-XXXX"
                                control_id="member_id"
                                value={insuranceDetails.primaryDetails[0].insurance.memberId ?? ""}
                                parentCallback={(value,event) => insuranceFormCallback(0, "memberId", value, event, "PRIMARY", true)}
                            />
                        </div>
                        : <AccordionComponent 
                            alwaysOpen
                            client_name={patientInfo.primaryDetails[0].basicInfo.firstName +" " + patientInfo.primaryDetails[0].basicInfo.lastName} 
                            client_type={PRIMARY} 
                            parentCallback={handleCallback}>
                            <div className="insurance-form">
                                <InputField 
                                    testId="textInput-insuranceHolderName"
                                    disabled 
                                    type="text" 
                                    label_title="Primary insurance holder" 
                                    control_id="insurance_holder"
                                    value={patientInfo.primaryDetails[0].basicInfo.firstName + " " + patientInfo.primaryDetails[0].basicInfo.lastName}/>
                                <div>
                                    <InputField 
                                        testId="textInput-memberId"
                                        type="text" 
                                        maxLength="20" 
                                        label_title="Member ID" 
                                        placeholder="Enter the Member ID (Length 3-20 characters)"  
                                        control_id="member_id" 
                                        value={insuranceDetails.primaryDetails[0].insurance.memberId ?? ""} 
                                        parentCallback={(value,event) => insuranceFormCallback(0, "memberId", value, event, "PRIMARY")}/>
                                    <InputField 
                                        type="text" 
                                        maxLength="50" 
                                        label_title="Group number" 
                                        placeholder="65342Cftw" 
                                        control_id="group_number" 
                                        value={insuranceDetails.primaryDetails[0].insurance.groupNumber} 
                                        parentCallback={(value,event) => insuranceFormCallback(0, "groupNumber", value, event, "PRIMARY")}/>
                                </div>
                            </div>
                        </AccordionComponent> 
                    }
                    <div>
                        {patientInfo && patientInfo.dependentDetails.length > 0 && patientInfo.dependentDetails.map((item, key) => (
                            <AccordionComponent 
                                client_name={`${item.basicInfo.firstName} ${item.basicInfo.lastName}`} 
                                client_type={item.basicInfo.status} 
                                key={key} 
                                id={key} 
                                parentCallback={handleCallback}>
                                <div className="insurance-form">
                                    <label className="form-input-label">Member ID</label>
                                    <div  className="insurance-radio-div">
                                        <Form.Check 
                                            type="checkbox" 
                                            onClick={(e)=>onCheckRadioBtn(e, key, item.basicInfo.status)} 
                                            disabled={isRadioButonDisabled} 
                                            checked={item.insurance.SameMemberId}/>        
                                        <label htmlFor="numtype" className="insurance-radio-labels">{ t('insurance.numtype') }</label>
                                    </div>
                                        <InputField
                                            type="text"
                                            testId="textInput-memberId"
                                            placeholder="Enter the Member ID (Length 3-20 characters)"
                                            control_id="member_id"
                                            maxLength="20"
                                            parentCallback={(value,event) => insuranceFormCallback(key, "memberId", value, event, "DEPENDANT")}
                                            value={insuranceDetails.dependentDetails[key]?insuranceDetails.dependentDetails[key].insurance.memberId:""}
                                        />
                                </div>
                            </AccordionComponent>
                        ))}
                    </div>
                </div>
                {!errorMessage && insuranceType !== 'medicare' && 
                    <div className='practitioner-error-message' data-testid="errorMessage-test"> 
                        {errorMessageField==="both" ? t('insurance.page_error_group_memberid') : 
                        errorMessageField==="memberid" ? t('insurance.page_errorMessage') :
                        errorMessageField==="groupnumber" ? t('insurance.page_errorMessage_groupno') : 
                        null} 
                    </div>}
                {!errorMessage && insuranceType === 'medicare' && <div className='practitioner-error-message' data-testid="errorMessage-medicare"> {t('insurance.pageErrorMessage')} </div>}
                <Footer 
                    routingPath={routingPath} 
                    footerCallback={insuranceValidation} 
                    currentPage="insurancePage" 
                    pageData={insuranceDetails}/>
                </>
            }        
        </>
    );
}

export default InsurancePage;