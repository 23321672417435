import addIcon from './basic/add.svg';
import twFlag from './basic/ctflag.svg';
import spFlag from './basic/spflag.svg';
import usFlag from './basic/usflag.svg';
import ddarrow from './basic/arrow.svg';
import logo from './logo.svg';
import check from './basic/check.svg';
import aptIcon from "./basic/b.svg";
import dollarIcon from './basic/dollarIcon.svg';
import group836 from './basic/Group836.svg';
import box from './basic/box.svg';
import anthem from './basic/Anthem.svg';
import dollarNine from './basic/dollarnine.svg' ;
import dollarfive from './basic/dollarfive.svg' ;
import dollartwentyeight from './basic/$28.svg' ;
import CheckYellow from './basic/Check_Yellow.svg';
import stethoscope from "./basic/Stethoscope.png";
import stickFigures from './basic/stickfig.png';
import creditcard from "./basic/creditcard.png";

export {
    addIcon,
    twFlag,
    spFlag,
    usFlag,
    logo,
    ddarrow,
    check,
    aptIcon,
    dollarIcon,
    group836,
    box,
    anthem,
    dollarNine,
    dollarfive,
    dollartwentyeight,
    CheckYellow,
    stethoscope,
    stickFigures,
    creditcard
};