import React from 'react';
import { useQuery } from '../../hooks';
import './ProgressBar.css';

function ProgressBar(probs) {
  const params = useQuery();
  const insuranceType = params.get("type");
  let numbers = [1, 2, 3, 4, 5, 6];

  if (insuranceType === 'medicare') {
    numbers = [1, 2, 3, 4, 5, 6];
  } else if (insuranceType === 'anthem') {
    numbers = [1, 2, 3, 4, 5];
  } else if (insuranceType?.includes("Admin_") || (insuranceType?.includes("third-party_"))) {
    numbers = [1, 2, 3, 4];
  }

  const progressBararray = [];

  // anthem

  let new_progress_anthem = probs.currentPage === 'landing' ? 0 :
    (probs.currentPage === 'patient-info' ? 1 : (
      probs.currentPage === 'insurance' ? 2 : (
        probs.currentPage === 'practitioners' ? 3 : (
          probs.currentPage === 'signdocument' ? 4 : (
            probs.currentPage === 'confirmEnrollment' ? 5 : null
          )
        )
      )
    ));

  // medicare

  let new_progress_medicare = probs.currentPage === 'landing' ? 0 :
    (probs.currentPage === 'patient-info' ? 1 : (
      probs.currentPage === 'insurance' ? 2 : (
        probs.currentPage === 'practitioners' ? 3 : (
          probs.currentPage === 'signdocument' ? 4 : (
            probs.currentPage === 'confirmEnrollment' ? 5 : null
          )
        )
      )
    ));

  // medicare

  let new_progress_selfpay = probs.currentPage === 'landing' ? 0 :
    (probs.currentPage === 'patient-info' ? 1 : (
      probs.currentPage === 'practitioners' ? 2 : (
        probs.currentPage === 'signdocument' ? 3 : (
          probs.currentPage === 'confirmEnrollment' ? 4 : (
            probs.currentPage === 'membershipInformation' ? 5 : (
              probs.currentPage === 'payments' ? 6 : null
            )
          )
        )
      )

    ));
  let new_progress_admin = probs.currentPage === 'landing' ? 0 :
    (probs.currentPage === 'patient-info' ? 1 : (
      probs.currentPage === 'practitioners' ? 2 : (
        probs.currentPage === 'signdocument' ? 3 : (
          probs.currentPage === 'confirmEnrollment' ? 4 : null

        )
      )
    )

    );


  for (let number of numbers) {
    progressBararray.push(
      <div key={number} className={number <= ((insuranceType === "medicare") ? new_progress_medicare : ((insuranceType === "selfpay") ? new_progress_selfpay : ((insuranceType?.includes("Admin_") || insuranceType?.includes("third-party_")) ? new_progress_admin : (new_progress_anthem)))) ? "progressBarDivs" : "DisableprogressBarDivs"}></div>
    )
  }
  return (
    <div className="progressBar" data-testid="progressBar-test">
      {progressBararray}
    </div>
  );
}

export default ProgressBar;