import { useTranslation } from 'react-i18next';
import { useNavigate,useLocation } from "react-router-dom";
import './PractitionerProfileCard.css';
import { S3BUCKET_NOIMAGE_URL } from '../../constants/urlEndpoint';
import PropTypes from 'prop-types';

const styles = {
  initStyles: {
    padding: "50px",
    boxShadow: "0px -1px 6px #dcdcdc",
    borderRadius: "32px",
    margin: "0.8rem",
    maxWidth: "500px",
    background: "white"
  },
  image: {
    height: "170px",
    width: "170px",
    borderRadius: "50%",
  },
  btn: {
    padding: "17px 38px",
    width: "112px",
    height: "55px",
    background: "transparent",
    borderRadius: "57px",
    border: "1.5px solid #264E5A",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto"
}
}
 
function PractitionerSingleDisplay({
  education=[],
  certification=[],
  pronouns,
  name,
  location,
  intro,
  sees18,
  healthy,
  profile_img,
  position,
  client_type
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const locationType=useLocation();
  const params = new URLSearchParams(locationType.search);
  const insuranceType=params.get("type");
  const onCloseProfile=()=>{
    navigate(`/practitioners?type=${insuranceType}`, { state: {client_type:client_type}})
   
  }

  return(
    <div style={styles.initStyles}>
      <img style={styles.image} src={profile_img} className="d-block m-auto" alt={name} onError={event => {
          event.target.src = S3BUCKET_NOIMAGE_URL
          event.onerror = null
        }}/>
      <h2 className="practitioner-name mt-3 text-center">
        {name}, {position} <br />
        {location}
      </h2>
      {sees18 && (
        <p style={{ color: "#0080DD", margin: "14px 0" }} className="text-center">{sees18}</p>
      )}
      <p className='practitioner-intro'>{intro}</p>
      <div style={{ marginTop: "14px" }}>
        <h5 className='text-left'><b>{ t('practitioner.single.healthy') }</b></h5>
        <p className='text-left'>{healthy}</p>
      </div>
      <div style={{ marginTop: "14px" }}>
        <h5 className='text-left'><b>{ t('practitioner.single.education') }</b></h5>
        {education.map((item,key) => (
          <p className='text-left practitioner-education'>{item}</p>
        ))}
        
      </div>
      <div style={{ marginTop: "14px" }}>
        <h5 className='text-left'><b>{ t('practitioner.single.boardcert') }</b></h5>
        {certification.map((item,key) => (
          <p className='text-left practitioner-education'>{item}</p>
        ))}
        {/* <p className='text-left'>{certification}</p> */}
      </div>
      <div style={{ marginTop: "14px", marginBottom: "62px" }}>
        <h5 className='text-left'><b>{ t('practitioner.single.pronouns') }</b></h5>
        <p className='text-left'>{pronouns}</p>
      </div>
      <div>
        <button style={styles.btn} onClick={()=>onCloseProfile()}>Close</button>
      </div>
    </div>
  )
}
 
PractitionerSingleDisplay.propTypes = {
  education: PropTypes.array,
  certification: PropTypes.array,
  pronouns: PropTypes.string,
  name: PropTypes.string,
  location: PropTypes.string,
  intro: PropTypes.string,
  sees18: PropTypes.string,
  healthy: PropTypes.string,
  profile_img: PropTypes.string,
  position: PropTypes.string,
  client_type: PropTypes.string,
};

export default PractitionerSingleDisplay;