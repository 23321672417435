import axios from "axios";

const setAuthToken = token => {
  if (token) {
    axios.headers["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.headers["Authorization"];
  }
};

export default setAuthToken;