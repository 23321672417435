import { patientInfo } from "../actions/types";
import { DEPENDANT, PRIMARY, SPOUSE } from "../constants";

const initState = {
  primaryDetails: [
    {
      basicInfo: {},
      insurance: {},
      careProvider: {},
      signDocument: {},
    }
  ],
  spouseDetails: [],
  dependentDetails: [],
  resources: [],
  nextGenResponse: null,
  cmdOffice:[]
}

const patientInfoReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case patientInfo.PATIENT_INFO_DIRECT_CHANGE: {
      const newPrimary = [{
        ...state.primaryDetails[0],
        basicInfo: {
          ...state.primaryDetails[0].basicInfo,
          [payload.key]: payload.value
        }
      }]
      return {
        ...state,
        primaryDetails: payload.id === PRIMARY ? newPrimary : state.primaryDetails,
        dependentDetails: state.dependentDetails.map(dd => dd.basicInfo.id === payload.id ? {
          ...dd,
          basicInfo: {
            ...dd.basicInfo,
            [payload.key]: payload.value
          }
        } : dd)
      }
    }
    case patientInfo.PASS_RESOURCES: {
      return {
        ...state,
        resources: payload
      }
    }
    case patientInfo.UPDATE_PRIMARY_ACCOUNT_HOLDER:
    case patientInfo.ADD_PRIMARY_ACCOUNT_HOLDER: {
      return {
        ...state,
        primaryDetails: [
          {
            ...state.primaryDetails[0],
            basicInfo: payload
          }
        ]
      }
    }
    case patientInfo.CLEAR_ALL_DATA: {
      localStorage.removeItem('RES_FOR_PATIENT_POST');
      localStorage.removeItem('patient_details');
      return initState;
    }
    case patientInfo.UPDATE_SPOUSE:
    case patientInfo.UPDATE_DEPENDENT: {
      return {
        ...state,
        dependentDetails: state.dependentDetails.map(dd => dd.basicInfo.id === payload.data.id ? { ...dd, basicInfo: payload.data } : dd)
      }
    }
    case patientInfo.ADD_SPOUSE:
    case patientInfo.ADD_DEPENDENT: {
      return {
        ...state,
        dependentDetails: [...state.dependentDetails, { ...initState.primaryDetails[0], basicInfo: payload.form, insurance: payload.lookupExtraDetailsDep.insurance, careProvider: payload.lookupExtraDetailsDep.careProvider }]
      }
    }
    case patientInfo.UPDATE_PATIENT_IDS: {
      const newPrimary = [{
        // ...state.primaryDetails,
        basicInfo: {
          ...state.primaryDetails[0].basicInfo,
          patientType: payload.patientIds[0] // Primary is always the first member, not looping for performance's sake
        },
        insurance: { ...state.primaryDetails[0].insurance },
        careProvider: { ...state.primaryDetails[0].careProvider },
        signDocument: { ...state.primaryDetails[0].signDocument }
      }];
      const newDependents = state.dependentDetails.map(dep => ({ basicInfo: { ...dep.basicInfo, relationship: dep.relationship, patientType: dep.relationship }, insurance: { ...dep?.insurance }, careProvider: { ...dep?.careProvider }, signDocument: { ...dep?.signDocument } }));
      return {
        ...state,
        primaryDetails: newPrimary,
        dependentDetails: newDependents
      };
    }
    case patientInfo.REMOVE_MEMBER: {
      const { id, status } = payload;
      const isInDep = (id.includes(SPOUSE) || status.includes(SPOUSE)) || (id.includes(DEPENDANT) || status.includes(DEPENDANT));
      const newState = {
        ...state,
        primaryDetails: [{
          ...state.primaryDetails[0],
          basicInfo: id.includes(PRIMARY) || status.includes(PRIMARY) ? {} : state.primaryDetails[0]?.basicInfo
        }],
        dependentDetails: isInDep ? state.dependentDetails.filter(dep => dep.basicInfo.id !== payload.id) : state.dependentDetails
      }
      return newState;
    }
    case patientInfo.UPDATE_INSURANCE_DETAILS_PRIMARY:
      return {
        ...state,
        primaryDetails: [
          {
            ...state.primaryDetails[0],
            "insurance": payload.primaryDetails[0].insurance
          }
        ]
      }
    case patientInfo.UPDATE_INSURANCE_DETAILS_SECONDARY:
      return {
        ...state,
        spouseDetails: [{
          ...state.spouseDetails[0],
          "insurance": payload.spouseDetails[0].insurance
        }]
      }
    case patientInfo.UPDATE_INSURANCE_DETAILS_DEPENDENT:
      return {
        ...state,
        dependentDetails: payload
      }
    case patientInfo.ADD_PRACTITIONER_PRIMARY:
      return {
        ...state,
        primaryDetails: [
          {
            ...state.primaryDetails[0],
            "careProvider": payload.primaryDetails[0].careProvider
          }
        ]
      }
    case patientInfo.ADD_PRACTITIONER_SECONDARY:
      return {
        ...state,
        spouseDetails: [{
          ...state.spouseDetails[0],
          "careProvider": payload.spouseDetails[0].careProvider
        }]
      }
    case patientInfo.ADD_PRACTITIONER_DEPENDENT:
      return {
        ...state,
        dependentDetails: payload
      }
    case patientInfo.ADD_SIGN_DOCUMENT_PRIMARY:
      return {
        ...state,
        primaryDetails: [
          {
            ...state.primaryDetails[0],
            "signDocument": payload[0]
          }
        ]
      }
    case patientInfo.ADD_SIGN_DOCUMENT_SPOUSE:
      const withSpouse = state.dependentDetails.map(dep => dep.basicInfo.id === SPOUSE ? { ...dep, signDocument: payload } : dep);
      return {
        ...state,
        dependentDetails: withSpouse
      }
    case patientInfo.ADD_SIGN_DOCUMENT_DEPENDENT:
      const deps = state.dependentDetails.map(dep => dep.basicInfo.id === payload.mainId ? { ...dep, signDocument: payload.inputValuesDependents[0] } : dep);
      return {
        ...state,
        dependentDetails: deps,
        // dependentDetails: [...state.dependentDetails.map((dependent, index) => {
        //   return { ...dependent, "signDocument": payload[index] }
        // }
        // )]
      }
    case patientInfo.UPDATE_NEXT_GEN_RESPONSE:
      return {...state,nextGenResponse: payload}
    case patientInfo.SET_ALL_PATIENT:
        localStorage.setItem("patient_details", JSON.stringify(payload));
      return {...state,
        primaryDetails: payload.primaryDetails,
        spouseDetails:payload.spouseDetails,
        dependentDetails: payload.dependentDetails}
    case patientInfo.SET_CMD_OFFICE:
      return {...state,cmdOffice: payload}
    default:
      return state;
  }
}

export default patientInfoReducer