import PageHeading from '../../components/pageHeader/PageHeading';
import ProgressBar from '../../components/progressBar/ProgressBar';
import './paymentPage.css'
import {useTranslation} from "react-i18next";
import Footer from '../../components/footer/Footer';
import React, {useState} from 'react';
import {
    CardElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import {useEffect} from 'react';
import {ApiCall, PUT} from '../../utils';
import {POST} from '../../utils';
import {useLocation, useNavigate} from 'react-router-dom';
import {BASE_URL_PATIENT, BASE_URL_PAYMENT} from "../../constants/urlEndpoint";
import {useDispatch, useSelector} from 'react-redux';
import {useLocalStorage} from "../../hooks";
import { SPACING } from "../../components/forms";
import {BasicInfoDropdown} from "../../components/forms/utils";
import MembersInformationForm from "../../components/forms/MembersInformationForm";
import {UPDATE_NEXT_GEN_RESPONSE} from "../../actions/types/patientInfo";
import {SnackMessage} from "../../components/common/snackMessage/snackMessage";
import { formatPatientPayload } from '../../utils/formatPatientPayload';

const PaymentsPage = ({email, adultQty, dependentQty}) => {
    const cardLabels = {
        buttonName: "Credit/Debit Card",
        header: "Add new card",
        nameLabel: "Cardholder's name",
        errorMessage: "Cardholder's name cannot be empty",
        errorMessageLetter: "Cardholder's name has to consist of at least one letter"
    }
    const accountLabels = {
        buttonName: "ACH Transfer",
        header: "Provide account details",
        nameLabel: "Account holder's name",
        errorMessage: "Account holder's name cannot be empty",
        errorMessageLetter: "Cardholder's name has to consist of at least one letter"
    }
    let list = [{id: 1, value: 'Credit/Debit Card'}, {id: 2, value: 'ACH Transfer'}];

    const stripe = useStripe();
    const elements = useElements();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [disabled, setDisabled] = useState(false);
    const [_, setProgress] = useLocalStorage('progressbar', 1);
    setProgress(7)
    const regexPattern = /[^A-Za-z0-9\s.,'\/-]/g;
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const insuranceType = params.get("type");
    const dispatch = useDispatch();

    const [subscriptionData, setSubscriptionData] = useState(null);
    const [paymentIntent, setPaymentIntent] = useState();
    const [paymentMethod, setPaymentMethod] = useState('credit_card');
    const [validationError, setValidationError] = useState({});
    const [label, setLabel] = useState(cardLabels);
    const [name, setName] = useState('');
    const [snackBar, setSnackBar] = useState({message: "error", isVisible: false})

    const oGlobalPatientInfoObject = useSelector(state => state.patientInfo);
    const resources = useSelector((state) => state.patientInfo.resources);

    const getApiPayload = () => {
        // final payload

        let patientPayload= formatPatientPayload(oGlobalPatientInfoObject,insuranceType,resources,true);
       
        finalAPIcall(patientPayload);
    }

    const finalAPIcall = async (patientPayload) => {
        await ApiCall(BASE_URL_PATIENT + '1', patientPayload, PUT, null, function (response) {
            if (response.status === 200) {
                localStorage.removeItem('patient_details');
                localStorage.removeItem('RES_FOR_PATIENT_POST');
                dispatch({type: UPDATE_NEXT_GEN_RESPONSE, payload: response.data});
            }else if (response.status === 409 || response.status === 417) {
                if (response.data?.errorCode === "NG_1002" || response.data?.errorCode === "AppCMD_1002") {
                    navigate({ pathname: '/existingUser',search:'?type='+insuranceType});
                }
              }
             else {
                navigate('/error?type=selfpay');
            }
        });
    }

    useEffect(() => {
        if (paymentIntent && (paymentIntent.status === 'succeeded' || paymentIntent.status === 'processing')) {
            updatePaymentDetails({
                paymentId: paymentIntent.id,
                status: paymentIntent.status,
                subscriptionId: subscriptionData.subscriptionId,
                customerId: subscriptionData.customerId,
                paymentType: paymentIntent.payment_method
            }).then(_ => {
                setDisabled(true)
                setProgress(8);
                navigate('/successEnrollment?type=selfpay');
            });

        }
        if (paymentIntent && paymentIntent.status === 'failed') {
            updatePaymentDetails({
                status: paymentIntent.status,
                subscriptionId: subscriptionData.subscriptionId,
                customerId: subscriptionData.customerId,
            }).then(_ => {
                setSnackBar({message: "Payment Failed. Please try again", isVisible: true});
            });
        }
    }, [paymentIntent])

    useEffect(() => {
        if (!oGlobalPatientInfoObject.nextGenResponse)
            getApiPayload()
    }, []);

    useEffect(() => {
        if (oGlobalPatientInfoObject.nextGenResponse)
            createSubscription(oGlobalPatientInfoObject.nextGenResponse);
    }, [oGlobalPatientInfoObject.nextGenResponse])

    useEffect(() => {
        if (paymentMethod === "credit_card") {
            const cardElement = elements?.getElement(CardElement);
            cardElement?.on('change', function (event) {
                setValidationError({card: event.error?.message});
                if(!event.complete) {
                    setDisabled(true); 
                } else {
                    setDisabled(false);
                }
            });
        }
    }, [paymentMethod, elements])


    const createSubscription = async ({
                                        insuranceType,
                                        patientId,
                                        emailAddress,
                                        firstName,
                                        lastName,
                                        cellphone,
                                        addressLine1,
                                        addressLine2,
                                        city,
                                        state,
                                        zip,
                                        country,
                                        externalId
                                      }) => {
        await ApiCall('subscription', {
            insuranceType,
            patientId,
            emailAddress,
            fullName: `${firstName} ${lastName}`,
            phone: cellphone,
            address: {addressLine1, addressLine2, city, state, zip, country},
            nextGenId: externalId,
            adultQty,
            childQty: dependentQty
        }, POST, null, function (response) {
            if (response.status === 200) {

                const {subscriptionId, clientSecret, customerId} = response.data
                setSubscriptionData({subscriptionId, clientSecret, customerId});
            } else {
                navigate('/error?type=selfpay');
            }
        }, null, BASE_URL_PAYMENT)
    }

    const updatePaymentDetails = async (paymentDetails) =>
        await ApiCall(`customer`, paymentDetails, PUT, null,
            (response) => {
                if (response.status === 400) {
                }
            }, null, BASE_URL_PAYMENT);

    const handleCreditCardPayment = async (e) => {

        e.preventDefault();
        if (!isNameValid(name, label.errorMessage)) {
            return;
        }
        const cardElement = elements.getElement(CardElement);

        if (!subscriptionData) {
            return
        }
        setDisabled(true)
        let {error, paymentIntent} = await stripe.confirmCardPayment(subscriptionData.clientSecret, {
            payment_method: {
                card: cardElement,
                billing_details: {
                    name,
                }
            }
        });

        if (error && error.type !== 'validation_error') {
            setDisabled(false)
            paymentIntent = {status: 'failed'};
        }
        setPaymentIntent(paymentIntent);
    };

    const handleACHBankPayment = async (e) => {
        
        e.preventDefault();
        if (!isNameValid(name, label.errorMessage)) {
            return;
        }

        if (!subscriptionData) {
            return
        }
        setDisabled(true)
        stripe.collectBankAccountForPayment({
            clientSecret: subscriptionData.clientSecret,
            params: {
                payment_method_type: 'us_bank_account',
                payment_method_data: {
                    billing_details: {
                        name: name
                    },
                },
            },
            expand: ['payment_method'],
        }).then(({paymentIntent, error}) => {
            if (error) {
                setDisabled(false)
                setPaymentIntent({status: 'failed'});
            } else if (paymentIntent.status === 'requires_confirmation') {
                stripe.confirmUsBankAccountPayment(subscriptionData.clientSecret)
                    .then(({paymentIntent, error}) => {
                        if (error) {
                            setDisabled(false)
                            setPaymentIntent({status: 'failed'});
                        } else if (paymentIntent.status === "processing") {
                            setDisabled(true)
                            setPaymentIntent(paymentIntent);
                        }
                        if (paymentIntent?.next_action?.type === "verify_with_microdeposits") {
                            setDisabled(true)
                            setSnackBar({
                                message: "Currently we do not support manual verification for payment",
                                isVisible: true
                            });
                        }
                    });
            }
            if(paymentIntent.status === "requires_confirmation")
                setTimeout(() => setDisabled(false), 2000)
            else setDisabled(false);
        });
    }

    const handleSubmit = paymentMethod === 'credit_card' ? handleCreditCardPayment : handleACHBankPayment;

    if (!stripe || !elements) {
        return '';
    }

    const routingPath = {
        next: '/payments',
        back: '/membershipInformation'
    }


    const isNameValid = (name, message) => {
        const regex = /[a-zA-Z]/g
        if (!name) {
            setValidationError({name: message});
            return false;
        }
        if(!name.match(regex)){
            setValidationError({name: label.errorMessageLetter});
            return false;
        }
        if (validationError?.name) {
            setValidationError({});
        }

        return true;
    }

    const onPaymentMethodChange = (val, _) => {
        setValidationError({});
        setPaymentMethod(val === 'Credit/Debit Card' ? 'credit_card' : 'ach_bank_debit');
        setLabel(val === 'Credit/Debit Card' ? cardLabels : accountLabels);
    }

    return (
        <div className="payment_page">
            {/* <PaymentsBody /> */}
            <ProgressBar currentPage="payments"/>
            <PageHeading title='Enter your credit card information'/>
            <div className="container mb-5">
                <div className="row">
                    <div className="col-6">
                        <MembersInformationForm/>
                    </div>

                    <div className="col-6 ps-lg-5">
                        <form id='payment-form' className="payment-form" onSubmit={handleSubmit}>
                            <div className="row" style={{marginBottom: `${SPACING.formLabelLast.bottom}%`}}>
                                <BasicInfoDropdown
                                    id="payment_method"
                                    htmlFor="payment_method"
                                    mainLabelText="Choose a payment method"
                                    value={label.buttonName}
                                    valPlaceholder="Choose a payment method"
                                    list={list}
                                    onDropdownChange={onPaymentMethodChange}
                                    isEditable="true"
                                    name="payment_method"
                                />
                            </div>
                            <span>{paymentMethod === 'credit_card' ?
                                'Please provide your card details'
                                : 'Please provide your bank account details'}</span>
                            <div className="row" style={{marginBottom: `${SPACING.formLabelLast.bottom}%`}}>
                                <div className="form-group col-12">
                                    <label htmlFor="cardHolderName" className="label-text form-label"
                                           style={{marginBottom: `${SPACING.formLabelLast.bottom}%`}}>{label.nameLabel}</label>
                                    <input
                                        name="cardHolderName"
                                        id="cardHolderName"
                                        type="text"
                                        value={name}
                                        className="form-control"
                                        maxLength={60}
                                        onChange={(e) => {
                                            isNameValid(e.target.value, label.errorMessage);
                                            setName(e.target.value.replace(regexPattern, ""))
                                        }}
                                    />
                                    {validationError.name &&
                                        <div className="pt-2 text-danger">
                                            <div>{validationError.name}</div>
                                        </div>
                                    }
                                </div>
                            </div>
                            {paymentMethod === 'credit_card' &&
                                <div className="row" style={{marginBottom: `${SPACING.formLabelLast.bottom}%`}}>
                                    <div className="form-group col-12">
                                        <label className="label-text form-label"
                                               style={{marginBottom: `${SPACING.formLabelLast.bottom}%`}}>
                                            Card number
                                        </label>
                                        <div className="payment-stripe-card">
                                            <CardElement/>
                                        </div>
                                        {validationError.card &&
                                            <div className="pt-2 text-danger">
                                                <div>{validationError.card}</div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                        </form>
                    </div>
                </div>
            </div>
            <Footer 
                disabled={disabled} 
                routingPath={routingPath} 
                currentPage='payment'
            />
            <SnackMessage 
                snackMessage={snackBar.message} 
                show={snackBar.isVisible}
                clearMsg={() => setSnackBar({...snackBar, isVisible: false})}/>
        </div>
    )
}

export default PaymentsPage;