import React from 'react';
import EnrollmentPersonCard from './EnrollmentPersonCard';

function ConfirmEnrollment() {
  return (
    <div>
    <EnrollmentPersonCard />
    </div>
  )
}

export default ConfirmEnrollment