import Form from 'react-bootstrap/Form';
import './InputField.css';
import PropTypes from 'prop-types';

function InputField(props) {

    const { testId, control_id, label_title, type, placeholder, disabled, errorMessages, parentCallback, value, maxLength } = props;
    const onChangeValue = (e, event) => {
        let value = e.target.value;
        parentCallback(value, event);
    }
    return (
        <div className='form-input-field'>
            {label_title && <Form.Label className='form-input-label'>{label_title}</Form.Label>}
            <Form.Control data-testid={testId} type={type} disabled={disabled} maxLength={maxLength} placeholder={placeholder} onChange={(e) => onChangeValue(e, "onChange")} onBlur={(e) => onChangeValue(e, "onBlur")} id={control_id} className="input-control" value={value} />
            {errorMessages && <div className="pt-2 text-danger"><div>{errorMessages}</div></div>}
        </div>
    )
}

InputField.propTypes = {
    testId: PropTypes.string,
    control_id: PropTypes.string,
    label_title: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    errorMessages: PropTypes.string,
    parentCallback: PropTypes.func,
    value: PropTypes.string,
    maxLength: PropTypes.string,
};

export default InputField;