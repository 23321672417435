import Footer from '../../components/footer/Footer';
import PageHeading from '../../components/pageHeader/PageHeading';
import ProgressBar from '../../components/progressBar/ProgressBar';
import './EnrollmentPersonCard.css';
import { TbEdit } from 'react-icons/tb';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import { S3BUCKET_NOIMAGE_URL } from '../../constants/urlEndpoint';
import { ApiCall, formatDate, PUT, POST } from '../../utils';
import { BASE_URL_PATIENT, SPOUSE } from '../../constants';
import { Spinner } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { formatPatientPayload } from '../../utils/formatPatientPayload';

const EnrollmentPersonCard = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const insuranceType = params.get("type");
    const person = useSelector(state => state.patientInfo);
    const oGlobalPatientInfoObject = useSelector(state => state.patientInfo);
    const [isLoading, setIsLoading] = useState(false)
    const resources = useSelector((state) => state.patientInfo.resources);
    const [hasPCP, setHasPCP] = useState(false)
    const memberId = useSelector((state) => state.patientInfo?.primaryDetails[0]?.insurance?.memberId);

    const navigate = useNavigate();
    const routingPath = {
        next: (insuranceType === "anthem" || insuranceType === "medicare" || insuranceType?.includes("Admin_") || insuranceType?.includes("third-party_")) ? '/successEnrollment' : '/membershipInformation',
        back: '/signdocument'
    }

    const hasUpdatePCP = async () => {
        await ApiCall("config/pcpinstructions", {
            "memberID": memberId
        }, POST, null, function (response) {
            if (response.status === 200) {
                setHasPCP(response.data.hasUpdatePCPInstructions)
            } else {
                navigate('/error');
                return false;
            }
        });
    }

    useEffect(() => {
        if (insuranceType === 'anthem') {
            hasUpdatePCP()
        }
    }, [insuranceType])

    const handleEdit = (id) => {
        const options = { state: { activeId: id } }
        localStorage.setItem('progressbar', 2);
        if (insuranceType === "medicare") {
            navigate('/basic-info?type=medicare', options)
        } else if ((insuranceType === "selfpay" || insuranceType?.includes("Admin_") || insuranceType?.includes("third-party_"))) {
            navigate(`/basic-info?type=${insuranceType}`, options)
        } else {
            navigate('/basic-info?type=anthem', options);
        }
    }

    /* istanbul ignore next */
    const getApiPayload = () => {
        // final payload 

        let patientPayload = formatPatientPayload(oGlobalPatientInfoObject, insuranceType, resources, true);
        finalAPIcall(patientPayload);
    }

    /* istanbul ignore next */
    const finalAPIcall = async (patientPayload) => {
        setIsLoading(true);
        await ApiCall(BASE_URL_PATIENT + '1', patientPayload, PUT, null, function (response) {
            const errors = ["AppNG_1500", "AppNG_1501", "AppNG_1502", "AppNG_1503", "AppNG_1504"];
            for (let i = 0; i < errors.length; i++) {
                if (response.data?.errorCode === errors[i]) {
                    localStorage.removeItem('patient_details');
                    localStorage.removeItem('RES_FOR_PATIENT_POST');
                    navigate(`/error?errorCode=${errors[i]}`);
                }
            }
            if (response.status === 200) {
                setIsLoading(false)
                localStorage.removeItem('patient_details');
                localStorage.removeItem('RES_FOR_PATIENT_POST');
                navigate(`/successEnrollment?type=${insuranceType}`, {state: {patientId: response.data.patientId, hasPCP: hasPCP}});
            }
            else if (response.data?.errorCode === "AppNG_1304") {
                localStorage.removeItem('patient_details');
                localStorage.removeItem('RES_FOR_PATIENT_POST');
                navigate('/successEnrollmentCall?errorCode=AppNG_1304');
                return false;
            } else if (response.status === 409 || response.status === 417) {
                if (response.data?.errorCode === "NG_1002" || response.data?.errorCode === "AppCMD_1002") {
                    navigate({ pathname: '/existingUser', search: '?type=' + insuranceType });
                }
            }
            else {
                setIsLoading(false);
                navigate('/error');
                return false;
            }
        });
    }


    return (
        <>
            {isLoading &&
                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <Spinner style={{ color: "#264E5A", width: "5rem", height: "5rem", borderWidth: "0.5rem", marginTop: "70px" }} animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            }
            {
                !isLoading &&
                <>
                    <div className='enrollment-person-root'>
                        <ProgressBar currentPage="confirmEnrollment" />
                        <PageHeading title={t('enrollment.heading')} description={t('enrollment.description')} />
                        <div className='confirmation-page-content'>
                            <div className='enrollment-card'>
                                <div className='flex-container-enrollment'>
                                    <div className='name-left enrollment-labels'> {t('enrollment.fullname')}: {person.primaryDetails[0].basicInfo.firstName + " " + person.primaryDetails[0].basicInfo.lastName} </div>
                                    <div className='edit-button-right'>
                                        <button className='edit-button-dev' data-testid="editbtn-test" onClick={() => handleEdit(person.primaryDetails[0].basicInfo.id)}>
                                            <TbEdit className='edit-button-icon' />
                                        </button>
                                    </div>
                                </div>

                                <div className='enrollment-labels'> {t('enrollment.dob')}: {
                                    formatDate(person.primaryDetails[0].basicInfo.dob)
                                } </div>
                                <div className='enrollment-labels'> {t('enrollment.email')}: {person.primaryDetails[0].basicInfo.email} </div>
                                <div className='enrollment-labels'> {t('enrollment.phonenum')}: {person.primaryDetails[0].basicInfo.phonenumber} </div>
                                <div className='enrollment-labels'> {t('enrollment.address')}: {person.primaryDetails[0].basicInfo.addressLine2 ? (person.primaryDetails[0].basicInfo.addressLine1 + ", " + person.primaryDetails[0].basicInfo.addressLine2) : person.primaryDetails[0].basicInfo.addressLine1} </div>
                                <div className='enrollment-labels'> {t('enrollment.location')}: {person.primaryDetails[0].careProvider.location} </div>
                                <div className='enrollment-labels'>
                                    <img className='avatar' src={`data:image/png;base64, ${person.primaryDetails[0].careProvider.images?.length ? person.primaryDetails[0].careProvider.images[0].fileDataBase64 : ""}`} alt={person.primaryDetails[0].careProvider.choosePrimary} onError={event => {
                                        event.target.src = S3BUCKET_NOIMAGE_URL
                                        event.onerror = null
                                    }} />
                                    <p className='care-provider-name'>{person.primaryDetails[0].careProvider.choosePrimary}</p>
                                </div>
                                <div className='enrollment-labels'>{t('enrollment.pcp')}</div>
                            </div>
                            <div>
                                {person && person.dependentDetails.length > 0 && person.dependentDetails.map((item, key) => (
                                    <div className='enrollment-card' key={key}>
                                        <div className='flex-container-enrollment'>
                                            <div className='name-left enrollment-labels'> {t('enrollment.fullname')}: {item.basicInfo.firstName + " " + item.basicInfo.lastName} </div>
                                            <div className='edit-button-right'>
                                                <button className='edit-button-dev' data-testid="editbtn-test" onClick={() => handleEdit(item?.basicInfo?.id)}>
                                                    <TbEdit className='edit-button-icon' />
                                                </button>
                                            </div>
                                        </div>

                                        <div className='enrollment-labels'> {t('enrollment.dob')}: {formatDate(item.basicInfo.dob)} </div>
                                        <div className='enrollment-labels'> {t('enrollment.email')}: {item.basicInfo.email} </div>
                                        <div className='enrollment-labels'> {t('enrollment.phonenum')}: {item.basicInfo.phonenumber} </div>
                                        <div className='enrollment-labels'> {t('enrollment.address')}: {item.basicInfo.addressLine2 ? (item.basicInfo.addressLine1 + ", " + item.basicInfo.addressLine2) : item.basicInfo.addressLine1} </div>
                                        <div className='enrollment-labels'> {t('enrollment.location')}: {item.careProvider.location} </div>
                                        <div className='enrollment-labels'>
                                            <img className='avatar' src={`data:image/png;base64, ${item.careProvider.images?.length ? item.careProvider.images[0].fileDataBase64 : ""}`} alt={item.careProvider.choosePrimary} onError={event => {
                                                event.target.src = S3BUCKET_NOIMAGE_URL
                                                event.onerror = null
                                            }} />
                                            <p className='care-provider-name'>{item.careProvider.choosePrimary}</p>
                                        </div>
                                        <div className='enrollment-labels'>{t('enrollment.pcp')}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className='footer-section'>
                        <Footer routingPath={routingPath} currentPage='confirmEnrollment' footerCallback={getApiPayload} />
                    </div>
                </>
            }

        </>
    )
}

export default EnrollmentPersonCard;