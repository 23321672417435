import { useState } from 'react';
import AccordionComponent from '../../components/accordion/AccordionComponent'
import PractitionerList from '../../components/practitionerList/PractitionerList';
import PageHeading from '../../components/pageHeader/PageHeading';
import './PractitionersPage.css';
import Footer from "../../components/footer/Footer";
import ProgressBar from "../../components/progressBar/ProgressBar";
import { useLocation, useNavigate } from 'react-router-dom';
import { BASE_URL_PATIENT } from '../../constants/urlEndpoint';
import { ApiCall } from '../../utils/ApiCall';
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux"
import { PUT } from '../../utils';
import { useLocalStorage } from "../../hooks";
import { SPOUSE } from '../../constants';
import { formatPatientPayload } from '../../utils/formatPatientPayload';
import { Spinner } from 'react-bootstrap';

function PractitionersPage() {
    const { t } = useTranslation();
    const patientInfo = useSelector(state => state.patientInfo);
    const spouseBasic = patientInfo.dependentDetails.find(x => x.basicInfo.id === SPOUSE);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const resources = useSelector((state) => state.patientInfo.resources);
    const cmdLocation = useSelector((state => state.patientInfo.cmdOffice));
    const open_accordion_client = (location.state?.client_type) ? location.state.client_type : "PRIMARY";
    const params = new URLSearchParams(location.search);
    const [_, setProgress, removeProgress] = useLocalStorage('progressbar', 1);
    const [isLoading, setIsLoading] = useState(false);
    let patientPrimaryCareData = {
        "primaryDetails": [
            {
                "careProvider": {
                    "location": patientInfo.primaryDetails[0].careProvider.location ? patientInfo.primaryDetails[0].careProvider.location : "",
                    "locationId": patientInfo.primaryDetails[0].careProvider.locationId ? patientInfo.primaryDetails[0].careProvider.locationId : "",
                    "choosePrimary": patientInfo.primaryDetails[0].careProvider.choosePrimary ? patientInfo.primaryDetails[0].careProvider.choosePrimary : "",
                    "providerID": patientInfo.primaryDetails[0].careProvider.providerID ? patientInfo.primaryDetails[0].careProvider.providerID : "",
                    "images": patientInfo.primaryDetails[0].careProvider.images ? patientInfo.primaryDetails[0].careProvider.images : [],
                }
            }
        ],
        "spouseDetails": [
            {
                "careProvider": {
                    "location": (spouseBasic && spouseBasic.careProvider) ? spouseBasic.careProvider.location : "",
                    "locationId": (spouseBasic && spouseBasic.careProvider) ? spouseBasic.careProvider.locationId : "",
                    "choosePrimary": (spouseBasic && spouseBasic.careProvider) ? spouseBasic.careProvider.choosePrimary : "",
                    "providerID": (spouseBasic && spouseBasic.careProvider) ? spouseBasic.careProvider.providerID : "",
                    "images": (spouseBasic && spouseBasic.careProvider) ? spouseBasic.careProvider.images : []

                }
            }
        ],
        "dependentDetails": []

    }
    for (let j = 0; j < patientInfo.dependentDetails.length; j++) {
        patientPrimaryCareData.dependentDetails.push({
            "careProvider": {
                "location": patientInfo.dependentDetails[j].careProvider.location ? patientInfo.dependentDetails[j].careProvider.location : "",
                "locationId": patientInfo.dependentDetails[j].careProvider.locationId ? patientInfo.dependentDetails[j].careProvider.locationId : "",
                "choosePrimary": patientInfo.dependentDetails[j].careProvider.choosePrimary ? patientInfo.dependentDetails[j].careProvider.choosePrimary : "",
                "providerID": patientInfo.dependentDetails[j].careProvider.providerID ? patientInfo.dependentDetails[j].careProvider.providerID : ""
            }
        })

    }
    // eslint-disable-next-line

    // eslint-disable-next-line
    const [selectedProviderInfo, setSelectedProviderInfo] = useState(patientPrimaryCareData);
    const [errorMessage, setErrorMessage] = useState(true);
    const [accordionRef, setAccordionRef] = useState();
    const [locationDetails, setLocationDetails] = useState(cmdLocation);
    const [callPutMethod, setCallPutMethod] = useState(true)
    const insuranceType = params.get("type");


    const routingPath = {
        next: '/signdocument',
        back: (insuranceType === "selfpay" || insuranceType?.includes("Admin_") || insuranceType?.includes("third-party_")) ? '/basic-info' : '/insurance'
    }

    const closeAccordion = (accordianRef) => {

        accordianRef.current.children[0].click();
    }


    const handleCallback = (ref) => {
        setAccordionRef(ref);
    }

    const practitionerListCallback = (index, obj, type, officeLocation, locationId) => {
        if (type === "PRIMARY") {
            selectedProviderInfo.primaryDetails[0].careProvider.choosePrimary = obj.firstName + " " + obj.lastName;
            selectedProviderInfo.primaryDetails[0].careProvider.location = officeLocation;
            selectedProviderInfo.primaryDetails[0].careProvider.providerID = obj.id;
            selectedProviderInfo.primaryDetails[0].careProvider.locationId = locationId;
            selectedProviderInfo.primaryDetails[0].careProvider.images = obj.images;

            setSelectedProviderInfo(prev => {
                return {
                    ...prev,
                    primaryDetails: selectedProviderInfo.primaryDetails
                }
            })
            dispatch({
                type: "ADD_PRACTITIONER_PRIMARY", payload: selectedProviderInfo
            });
        } else if (type === "SPOUSE") {
            selectedProviderInfo.spouseDetails[0].careProvider.choosePrimary = obj.firstName + " " + obj.lastName;
            selectedProviderInfo.spouseDetails[0].careProvider.location = officeLocation;
            selectedProviderInfo.spouseDetails[0].careProvider.providerID = obj.id;
            selectedProviderInfo.spouseDetails[0].careProvider.locationId = locationId;
            selectedProviderInfo.spouseDetails[0].careProvider.images = obj.images;
            setSelectedProviderInfo(prev => {
                return {
                    ...prev,
                    spouseDetails: selectedProviderInfo.spouseDetails
                }
            })
            dispatch({
                type: "ADD_PRACTITIONER_SECONDARY", payload: selectedProviderInfo
            });
        } else if (type === "DEPENDENT") {
            selectedProviderInfo.dependentDetails[index].careProvider.choosePrimary = obj.firstName + " " + obj.lastName;
            selectedProviderInfo.dependentDetails[index].careProvider.location = officeLocation;
            selectedProviderInfo.dependentDetails[index].careProvider.providerID = obj.id;
            selectedProviderInfo.dependentDetails[index].careProvider.locationId = locationId;
            selectedProviderInfo.dependentDetails[index].careProvider.images = obj.images;
            patientInfo.dependentDetails[index].careProvider = selectedProviderInfo.dependentDetails[index].careProvider;
            setSelectedProviderInfo(prev => {
                return {
                    ...prev,
                    dependentDetails: selectedProviderInfo.dependentDetails
                }
            })
            dispatch({
                type: "UPDATE_INSURANCE_DETAILS_DEPENDENT", payload: patientInfo.dependentDetails
            });
        }
        setCallPutMethod(true);
    }

    const practitionerValidation = () => {
        const primaryCareObject = selectedProviderInfo.primaryDetails[0].careProvider;
        let bPrimaryValidation = (primaryCareObject.location && primaryCareObject.choosePrimary.trim()) ? true : false;
        let bDependentValidation = true;
        if (patientInfo !== null && patientInfo.dependentDetails.length > 0 && patientInfo.dependentDetails.length > 0) {
            const dependantArray = selectedProviderInfo.dependentDetails;
            for (let i = 0; i < dependantArray.length; i++) {
                if (!(dependantArray[i].careProvider.location && dependantArray[i].careProvider.choosePrimary.trim())) {
                    bDependentValidation = false;
                    break;
                }
            }
        }
        if (patientInfo !== null && !spouseBasic && patientInfo.dependentDetails.length <= 0) {
            setErrorMessage(bPrimaryValidation);
            if (bPrimaryValidation) {
                if (callPutMethod) postProvider();
                else goToNextPage();
            }
        }
        else if (patientInfo !== null && !spouseBasic) {
            setErrorMessage(bPrimaryValidation && bDependentValidation);
            if (bPrimaryValidation && bDependentValidation) {
                if (callPutMethod) postProvider();
                else goToNextPage();
            }
        }
        else if (patientInfo !== null && patientInfo.dependentDetails.length <= 0) {
            setErrorMessage(bPrimaryValidation);
            if (bPrimaryValidation) {
                if (callPutMethod) postProvider();
                else goToNextPage();
            }
        }
        else {
            setErrorMessage(bPrimaryValidation && bDependentValidation);
            if (bPrimaryValidation && bDependentValidation) {
                if (callPutMethod) postProvider();
                else goToNextPage();
            }
        }
    }

    const postProvider = () => {

        let patientPayload = formatPatientPayload(patientInfo, insuranceType, resources, false);
        setIsLoading(true);
        ApiCall(BASE_URL_PATIENT + "1", patientPayload, PUT, null, function (response) {

            if (response.status === 200) {
                setIsLoading(false);
                localStorage.setItem("patient_details", JSON.stringify(patientInfo));
                goToNextPage();
            } else if (response.status === 409 || response.status === 417) {
                if (response.data?.errorCode === "NG_1002" || response.data?.errorCode === "AppCMD_1002") {
                    navigate({ pathname: '/existingUser', search: '?type=' + insuranceType });
                }
            }
            else {
                setIsLoading(false);
                navigate('/error');
            }
            return (response)
        });
    }

    const goToNextPage = () => {
        const newProgress = 5;
        removeProgress();
        setProgress(newProgress.toString());
        if (insuranceType === "medicare") {
            navigate({
                pathname: routingPath.next,
                search: '?type=medicare',
            })
        } else if (insuranceType === "selfpay" || insuranceType?.includes("Admin_") || insuranceType?.includes("third-party_")) {
            navigate({
                pathname: routingPath.next,
                search: `?type=${insuranceType}`,
            })
        } else {
            navigate({
                pathname: routingPath.next,
                search: '?type=anthem',
            });
        }

    }


    return (
        <div>
            {isLoading &&
                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <Spinner style={{ color: "#264E5A", width: "5rem", height: "5rem", borderWidth: "0.5rem", marginTop: "70px" }} animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            }
            {
                !isLoading &&
                <>
                    <div className='practitionerPage_main container-xl'>
                        <ProgressBar currentPage="practitioners" />
                        <PageHeading title={t('practitionerpage.page_heading')} />
                        <div className="practitioner-page-accordion">
                            {insuranceType === 'medicare' ?
                                <>
                                    <PractitionerList
                                        careProvider={patientInfo.primaryDetails[0].careProvider}
                                        client_name={patientInfo.primaryDetails[0].basicInfo.firstName}
                                        onCloseAccordion={(accordianRef) => closeAccordion(accordianRef)}
                                        parentCallback={(obj, officeLocation, locationId) => practitionerListCallback(0, obj, "PRIMARY", officeLocation, locationId)}
                                        locationOptions={locationDetails}
                                        dob={patientInfo.primaryDetails[0].basicInfo.dob}
                                    />
                                </> :
                                <>
                                    <AccordionComponent
                                        client_name={patientInfo.primaryDetails[0].basicInfo.firstName + " " + patientInfo.primaryDetails[0].basicInfo.lastName}
                                        client_type={"Primary account holder"}
                                        parentCallback={handleCallback}
                                        page_name="Practitioner"
                                        id={"PRIMARY"}
                                        alwaysOpen={open_accordion_client === "PRIMARY" ? true : false}>
                                        <PractitionerList
                                            careProvider={patientInfo.primaryDetails[0].careProvider}
                                            client_name={patientInfo.primaryDetails[0].basicInfo.firstName}
                                            onCloseAccordion={(accordianRef) => closeAccordion(accordianRef)}
                                            parentCallback={(obj, officeLocation, locationId) => practitionerListCallback(0, obj, "PRIMARY", officeLocation, locationId)}
                                            locationOptions={locationDetails}
                                            client_type={"PRIMARY"}
                                            dob={patientInfo.primaryDetails[0].basicInfo.dob}
                                        />
                                    </AccordionComponent>
                                    <div>
                                        {patientInfo.dependentDetails.length > 0 && patientInfo.dependentDetails.map((item, key) => (
                                            <AccordionComponent
                                                client_name={item.basicInfo.firstName + " " + item.basicInfo.lastName}
                                                client_type={item.basicInfo.status}
                                                key={key}
                                                parentCallback={handleCallback}
                                                page_name="Practitioner"
                                                id={item.basicInfo.id}
                                                alwaysOpen={open_accordion_client === item.basicInfo.id ? true : false}>
                                                <PractitionerList
                                                    careProvider={item.careProvider}
                                                    client_name={item.basicInfo.firstName}
                                                    onCloseAccordion={(accordianRef) => closeAccordion(accordianRef)}
                                                    parentCallback={(obj, officeLocation, locationId) => practitionerListCallback(key, obj, "DEPENDENT", officeLocation, locationId)}
                                                    locationOptions={locationDetails}
                                                    client_type={item.basicInfo.id}
                                                    dob={item.basicInfo.dob} />
                                            </AccordionComponent>
                                        ))}
                                    </div>

                                </>
                            }
                            {!errorMessage && <div className='practitioner-error-message' data-testid="practitioner-error-message-test"> {t('practitionerpage.page_errorMessage')} </div>}
                        </div>
                    </div>
                    <Footer routingPath={routingPath} footerCallback={practitionerValidation} currentPage="practitionerPage" />
                </>
            }
        </div>
    );
}

export default PractitionersPage;