import React from "react";
import "./Footer.css";
import { useNavigate, useLocation } from 'react-router-dom';
import { useLocalStorage } from "../../hooks";
import { useTranslation } from "react-i18next";
import { getProgressFromPage } from ".";
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';

function Footer(props) {
    const { routingPath, currentPage, disabled, footerCallback } = props;
    const { t } = useTranslation();
    const config = useSelector(state => state.appData.config);
    const isPaymentDisabled = (config["DisablePayment"] ?? "true") === "true";
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const insuranceType = params.get("type");
    // eslint-disable-next-line
    const [_, setProgress, removeProgress] = useLocalStorage('progressbar', 1);
    const navigate = useNavigate();
    const nextClick = () => {
        if (currentPage === 'signDocumentPage') {
            const unCheckValidateFlag = props.unCheckValidate();
            if (unCheckValidateFlag) {
                props.setValidationVar(false);
            } else {
                const newProgress = 6;
                removeProgress();
                props.setValidationVar(true);
                setProgress(newProgress.toString());
                if (insuranceType === "medicare") {
                    navigate({
                        pathname: routingPath.next,
                        search: '?type=medicare',
                    })
                } else if ((insuranceType === "selfpay" || insuranceType?.includes("Admin_") || insuranceType?.includes("third-party_"))) {
                    navigate({
                        pathname: routingPath.next,
                        search: `?type=${insuranceType}`,
                    })
                } else {
                    navigate({
                        pathname: routingPath.next,
                        search: '?type=anthem',

                    });
                }
            }
        } else if (currentPage === "practitionerPage") {
            footerCallback();
        } else if (currentPage === "insurancePage") {
            footerCallback();
        } else if (currentPage === "membershipInformation" && insuranceType !== 'selfpay') {
            footerCallback();
        } else if (currentPage === "membershipInformation" && (insuranceType === "selfpay" && isPaymentDisabled)) {
            footerCallback();
        }
        else if (currentPage === "confirmEnrollment" && (insuranceType === 'anthem' || insuranceType === 'medicare' || insuranceType?.includes("Admin_") || insuranceType?.includes("third-party_"))) {
            footerCallback();
        }
        else {
            const newProgress = getProgressFromPage(currentPage) ? getProgressFromPage(currentPage) : parseInt(localStorage.getItem('progressbar')) + 1;
            removeProgress();
            setProgress(newProgress.toString());
            if (insuranceType === "medicare") {
                navigate({
                    pathname: routingPath.next,
                    search: '?type=medicare',
                })
            } else if (insuranceType === "selfpay" || insuranceType?.includes("Admin_") || insuranceType?.includes("third-party_")) {
                navigate({
                    pathname: routingPath.next,
                    search: `?type=${insuranceType}`,
                })
            } else {
                navigate({
                    pathname: routingPath.next,
                    search: '?type=anthem',
                });
            }
        }
    }
    const backClick = () => {
        const newProgress = parseInt(localStorage.getItem('progressbar')) - 1 < 0 ? 0 : parseInt(localStorage.getItem('progressbar')) - 1;
        removeProgress();
        setProgress(newProgress.toString());
        if (insuranceType === "medicare") {
            navigate({
                pathname: routingPath.back,
                search: '?type=medicare',
            }, { state: { from: "/insurance" } })
            if (insuranceType !== 'medicare') navigate('/basic-info', { state: { from: "/insurance" } });
        } else if ((insuranceType === "selfpay" || insuranceType?.includes("Admin_") || insuranceType?.includes("third-party_"))) {
            navigate({
                pathname: routingPath.back === '/basic-info' ? `${routingPath.back}` : routingPath.back,
                search: `?type=${insuranceType}`,
            }, { state: { from: "/practitioners" } })
        } else {
            navigate(routingPath.back === '/basic-info' ? `${routingPath.back}?type=anthem` : `${routingPath.back}?type=anthem`, { state: { from: "/insurance" } })
        }
    }
    const generateTextForButton = () => {
        if (currentPage === "existingMember") return "Exit"
        if (currentPage === "confirmEnrollment") return "Confirm info"
        if (currentPage === "payment") return "Pay"
        return "Next";
    }
    return (
        <div className="footer-section" data-testid="footer-test">
            {currentPage !== 'successEnrollment' ?
                <div className="btn-section">
                    <button className="back-btn" onClick={() => backClick()}>Back</button>

                    {currentPage === "payment" ?
                        <button
                            disabled={disabled}
                            className="next-btn"
                            type="submit"
                            form="payment-form">
                            {generateTextForButton()}
                        </button>
                        :
                        <button
                            className="next-btn"
                            onClick={() => nextClick()}>
                            {generateTextForButton()}
                        </button>
                    }
                </div>
                : null
            }
            <div className="footer-phoneNum">
                {t('footer.questions')} (603) 810-6700
            </div>
        </div>
    );
}

Footer.propTypes = {
    routingPath: PropTypes.object,
    currentPage: PropTypes.string,
    disabled: PropTypes.bool,
    footerCallback: PropTypes.func,
    setValidationVar:PropTypes.func,
    unCheckValidate:PropTypes.func
};

export default Footer;
