import { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './NewLandingPage.css';
import { useEventListener, useLocalStorage } from "../../hooks";
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { clearAllData } from '../../actions/patientInfo';
import { clearAuthData } from '../../actions/auth';
import {aptIcon} from "../../assets";
import { group836 } from '../../assets';
import {stethoscope} from '../../assets';
import {stickFigures} from '../../assets';
import { box } from '../../assets';
import {anthem} from "../../assets";
import { creditcard } from '../../assets';
import { ApiCall, GET } from "../../utils";
// import EnrollmentDropdownValues from "./Enrollment.json"
// import {BasicInfoDropdown} from "../../components/forms/utils"


const NewLandingPage = ({ clearAllData, clearAuthData }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const insuranceType = params.get("type");
  // const [enrollmentFor, setEnrollmentFor] = useState(EnrollmentDropdownValues[1].value)
  const emailRef = useRef("");
  const [isValid, setIsValid] = useState(true);

  const [progress, setProgress, removeProgress] = useLocalStorage("progressbar", 1);

  localStorage.clear();
  useEffect(() => {
    if ((progress && progress !== 1 && JSON.parse(localStorage.getItem('progressbar')) !== 1)) {
      removeProgress();
      // setProgress(1)
    }
    // eslint-disable-next-line
  }, [progress]);

  if (JSON.parse(localStorage.getItem('progressbar')) === null || JSON.parse(localStorage.getItem('progressbar')) !== 1) {
    setProgress(1)
  }

  const navigatePage = () => {
    setProgress(2);
    if (insuranceType === "medicare") {
      navigate("/basic-info?type=medicare")
    } else if (insuranceType !== null && (insuranceType === "selfpay" || insuranceType?.includes("Admin_") || insuranceType?.includes("third-party_"))) {
      navigate(`/basic-info?type=${insuranceType}`)
    } else {
      navigate('/basic-info?type=anthem');
    }
  }
  const nextClick = () => {
    // Since basic info should be the second step (with 2 bars filled), we will increment by 2 from 0 instead
    // const newProgress = parseInt(progress) + 1;
    // removeProgress();

    // email validation 
    const email = emailRef.current.value;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    setIsValid(emailRegex.test(email));
    const emailValid = emailRegex.test(email);
    let isEmailForm = insuranceType?.includes("Admin_") || insuranceType?.includes("third-party_");
    if (isEmailForm) {
      if (emailValid) {
        localStorage.setItem('leadEmail', email);
        navigatePage();
      }
    }
    else {
      navigatePage();
    }
  }


  useEffect(() => {
    clearAllData();
    localStorage.removeItem('RES_FOR_PATIENT_POST');
    localStorage.removeItem('patient_details');
  }, []);

  useEffect(() => {
    localStorage.setItem('type', JSON.stringify(insuranceType ?? 'anthem'));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (insuranceType?.includes("third-party_")) {
      ApiCall(`config?configType=${insuranceType.replace("third-party_", "")}`, null, GET, null, function (response) {
        if (response.status === 200) {
          let config = response.data.configItems.length;
          if (config > 0) {
            response.data.configItems.map((item) => {
              localStorage.setItem(item.key, item.value)
            })
          }
        }
        else {
          navigate('/error');
          return false;
        }
      });
    }
  }, [insuranceType])

  // const onDropdownChange = (val) => {
  //     setEnrollmentFor(val);
  // };

  // useEffect(()=> {
  //   if(enrollmentFor != EnrollmentDropdownValues[1].value){
  //     localStorage.setItem('onlyDep',  'true');
  //   }
  //   else{
  //     localStorage.setItem('onlyDep',  'false');
  //   }
  // },[enrollmentFor])


  return (
    <div className="">
      <div className="Anthem_landing-main-div">
          <div>
            <div className="Anthem_landing-main-heading">
              <h1 className="Anthem_landing-main-headingP">
                <div className="Anthem-landing-main-heading-div"> {t('landing.AreYouReadyFor')}</div>
                <div className="Anthem-landing-main-heading-div">{t('landing.betterPrimaryCare')}</div>
              </h1>
            </div>
          <div className="image-container">
      <div className="image">
        <img src={aptIcon} alt="Image 1"/>
        <p dangerouslySetInnerHTML={{ __html: t('landing.image1des') }}></p>
      </div>
      <div className="image">
        <img src={group836} alt="Image 2" />
        <p dangerouslySetInnerHTML={{ __html: t('landing.image3des') }}></p>
      </div> 
      <div className="image image1">
        <img src={stickFigures} alt="Image 5" />
        <p dangerouslySetInnerHTML={{ __html: t('landing.image4des') }}></p>
      </div> 
      <div className="image">
        <img src={stethoscope} alt="Image 3" />
        <p dangerouslySetInnerHTML={{ __html: t('landing.image5des') }}></p>
      </div>
      <div className="image">
        <img src={creditcard} alt="Image 4" />
        <p dangerouslySetInnerHTML={{ __html: t('landing.image6des') }}></p>
      </div> 
    </div> 
     </div>
          <span>
            {(!insuranceType?.includes("Admin_") && insuranceType !== ("selfpay") && insuranceType !== ("medicare") && !insuranceType?.includes("third-party_")) &&
              < div className="Anthem_landing-card-section">
                  <br/>
                <div className="landing-card-header">
                  <img src={anthem} alt="anthem" className='anthemIcon'/>
                </div>
                <br/><br/>
                <div className="landing-card-sub-header m-b-43">
                  <span>
                    
                     <div className='card-anthem'>
                            {t('landing.pneo_anthem_1')}   <br/>
                            {t('landing.pneo_anthem_2')}
                          </div>
                          <br/>
                  </span>
                </div>
              <div>
                    {/*Adding comments to the following code to align with client requirements,
                             as it may be used in the future*/}
           {/* <div className='image-individual-container'>
                    <div className='pricing-column'>
                      <span>Individuals</span>
                      <br/>
                      <div>
                        <img className='Dollar1' src={dollarNine} alt='individual'></img>
                      </div>
                      <br/>
                      <p>{t('landing.individualdesc_p1')}</p>
                      <p>{t('landing.individualdesc_p2')}</p>
                    </div>

                    <div className='pricing-column'>
                      <span>Children</span> <br/>
                      <div>
                        <img className='Dollar5' src={dollarfive} alt='individual'></img>
                      </div>
                      <br/>
                      <p>{t('landing.childrendesc_p1')}</p>
                       <p>{t('landing.childrendesc_p2')}</p>
                    </div>

                    <div className='pricing-column'>
                      <span>Families</span> <br/>
                      <div>
                        <img className='Dollar28' src={dollartwentyeight} alt='individual'></img>
                      </div>
                      <br/>
                      <p>{t('landing.familydesc_p1')}</p> 
                      <p>{t('landing.familydesc_p2')}</p>
                    </div>
                  </div>  */}
            
             </div>
              </div>
            }
          </span>
        <br/>   <br/>
         <p className="Anthem_landing-sub-heading">
              {t('landing.subheader')}
            </p>
           <br/>
        <div className="anthem-landing-formwrapper">
            {/* {
              insuranceType !== 'medicare' && 
                <div className="row enrollment-for">
                  <BasicInfoDropdown
                      autoComplete="new-password"
                      htmlFor="dropdown_selection"
                      id="dropdown_selection"
                      mainLabelText={"Enrollment For"}
                      value={enrollmentFor}
                      valPlaceholder={t('basic.form.r10.placeholder')}
                      list={EnrollmentDropdownValues}
                      onDropdownChange={onDropdownChange}
                      name="dropdown_selection"
                      isEditable
                      filtering
                  />
              </div>
            } */}

            {(insuranceType?.includes("Admin_") || insuranceType?.includes("third-party_")) &&
                <form id="enrollmentemp" className='needs-validation  landing-form mb-5' data-testid="landing-emailForm">
                  <div className='Employeeid' >
                    <label className='emailLabel'>
                      {insuranceType?.includes("third-party_") ? 'EmployerGroup' : 'ConvenientMD Employee'}  Email ID
                    </label>
                    <br></br>
                    <input className='Newemailid mt-2' id="enrolledByEmployeeEmail" type="email" name="email" ref={emailRef} required data-testid="emailInput-test" />
                    {!isValid && <p className='text-danger' data-testid="emailerror-msg">Please enter a valid email address.</p>}
                  </div>
                </form>
              }
                <button className="Anthem-landing-button Anthem-landing-start-btn" onClick={() => nextClick()}>
                  {t('landing.lgs')}
                </button>
          </div>
            <br/>  <br/>
      </div>
     
      <div className='landing-list'>
    <p>{t('landing.list_p_tag')}</p>
    <ul>
        <li>Input basic information and  {!(insuranceType === ("selfpay")|| insuranceType?.includes("Admin_") || insuranceType?.includes("third-party_")) && 'insurance'} details for yourself 
        {!(insuranceType === ("medicare")) && ` and` }
        <br/>
          {!(insuranceType === ("medicare")) && `any family members you're signing up.` } </li>
        {!(insuranceType === ("medicare") || insuranceType === ("selfpay")|| insuranceType?.includes("Admin_") || insuranceType?.includes("third-party_")) && 'Please note: everyone needs to be covered by the same plan.'}
        <li>Select your PCP</li>
        <li>Acknowledge consent forms</li>
        {!(insuranceType === ("selfpay") || insuranceType?.includes("Admin_") ||insuranceType?.includes("medicare") || insuranceType?.includes("third-party_")) && <li>Update your PCP with Anthem</li> }
    </ul>
</div>

<br/><br/>
      <div className="footer-phoneNum">
        {t('footer.questions')} (603) 810-6700
      </div>
    </div>
  );
}

export default connect(null, { clearAllData, clearAuthData })(NewLandingPage);