import { useContext, useMemo, useState, useLayoutEffect, useEffect, useRef } from "react";
import { CURRENT, DEPENDANT, PRIMARY, REMINDER_MESSAGE, SPOUSE, BASE_URL_PATIENT } from "../../constants";
import './PatientInformation.css';
import { BasicInfoFormGroup, BasicInfoDropdown, BasicInfoRadio, RelationshipDropdown } from "./utils";
import { connect, useSelector } from "react-redux";
import { setPatientInfo, removePatientInfo, getPatientInfo, onDirectChange, setAllPatient } from "../../actions/patientInfo";
import AccordionComponent from '../accordion/AccordionComponentBasicInfo'
import { useTranslation } from 'react-i18next';
import {
    formatPhoneNumberInput,
    getNYearsAgo,
    getYearsOfPerson,
    initForm,
    isValidZipCode,
    nums,
    SPACING,
    isValidName,
    spinNewDepId,
    relational,
    doesFormExist,
    generateForm,
    generateSignDocObject
} from '.'
import { addIcon } from "../../assets";
import { useQuery } from "../../hooks";
import { useLocation, useNavigate } from "react-router-dom";
import States from "../forms/utils/states.json";
import { BasicInfoContext, getCoordinates } from "../../contexts";
import { Form } from "react-bootstrap";
import { httpReq, getAge } from '../../utils';
import PropTypes from 'prop-types';

function BasicInfoForm({
    isMainInstance,
    resource,
    patientInfo,
    setPatientInfo,
    removePatientInfo,
    accordionID,
    setShadowForm,
    onDirectChange,
    setAllPatient,
    showErrorMessageCallback,
}) {
    // CONTEXT
    const {
        errors, check, checkAll, clearAllErrors, clearMsg, formState, changeValue, addPrimary, setReminder,
        saveAll, removePersonCtx, addSpouse, addDependant, changeFocus, clearCurrent, showCurrentForm,
        hideCurrentForm, prepareCurrentForm, updateDependent, isEveryFormSaved
    } = useContext(BasicInfoContext);
    const actualForm = useMemo(() => {
        if (isMainInstance && formState.showCurrentForm) return formState.current;
        if (accordionID && accordionID.includes(PRIMARY)) return formState.primary;
        if (accordionID && accordionID.includes(SPOUSE)) return formState.dependents.find(de => de.id === SPOUSE);
        if (accordionID && accordionID.includes(DEPENDANT)) return formState.dependents.find(de => de.id === accordionID);
        return null;
    }, [formState.primary, formState.dependents, formState.dependents.length, formState.current, isMainInstance, formState.focused]);
    const [isClosed, setIsClosed] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const insuranceTypeRoute = params.get("type");

    const syncAddress = (newState) => {
        // 1. go through forms
        // 2. look at primaryAddress val - make sure it's bool
        // 3. if true, update values accordingly
        // 4. if false do nothing
        if (formState.dependents.length > 0) {
            for (const dep of formState.dependents) {
                if (dep.primaryAddress) {
                    changeValue({ id: dep.id, key: "addressLine1", value: newState.addressLine1 });
                    changeValue({ id: dep.id, key: "addressLine2", value: newState.addressLine2 });
                    changeValue({ id: dep.id, key: "city", value: newState.city });
                    changeValue({ id: dep.id, key: "state", value: newState.state });
                    changeValue({ id: dep.id, key: "zipcode", value: newState.zipcode });
                }
            }
        }
        if (formState.showCurrentForm) {
            if (formState.current.primaryAddress) {
                changeValue({ id: CURRENT, key: "addressLine1", value: newState.addressLine1 });
                changeValue({ id: CURRENT, key: "addressLine2", value: newState.addressLine2 });
                changeValue({ id: CURRENT, key: "city", value: newState.city });
                changeValue({ id: CURRENT, key: "state", value: newState.state });
                changeValue({ id: CURRENT, key: "zipcode", value: newState.zipcode });
            }
        }
        changeValue({ id: PRIMARY, key: "primaryAddress", value: false });
    }

    const syncPhone = (newState) => {
        if (formState.dependents.length > 0) {
            for (const dep of formState.dependents) {
                if (dep.primaryPhone) {
                    changeValue({ id: dep.id, key: "phonenumber", value: newState.phonenumber });
                    changeValue({ id: dep.id, key: "preferredMobile", value: newState.preferredMobile });
                }
            }
        }
        if (formState.showCurrentForm) {
            if (formState.current.primaryPhone) {
                changeValue({ id: CURRENT, key: "phonenumber", value: newState.phonenumber });
                changeValue({ id: CURRENT, key: "preferredMobile", value: newState.preferredMobile });
            }
        }
        changeValue({ id: PRIMARY, key: "primaryPhone", value: false });
    }

    const syncEmail = (value) => {
        if (formState.dependents.length > 0) {
            for (const dep of formState.dependents) {
                if (dep.primaryEmail) changeValue({ id: dep.id, key: "email", value });
            }
        }
        if (formState.showCurrentForm) {
            if (formState.current.primaryEmail) changeValue({ id: CURRENT, key: "email", value });
        }
        changeValue({ id: PRIMARY, key: "primaryEmail", value: false });
    }

    const alwaysOpen = useMemo(() => formState.focused === actualForm?.id, [formState.focused, actualForm?.id]);
    // STATE, STORE & HOOKS
    const singleWrapRef = useRef(null);
    const { t } = useTranslation();
    // REDUX STORE
    const primary = useSelector((x => x.patientInfo.primaryDetails[0]?.basicInfo));
    const spouse = useSelector((x => x.patientInfo.dependentDetails.find(de => de?.basicInfo?.id === SPOUSE || de?.basicInfo?.relationship === SPOUSE || de?.basicInfo?.status === SPOUSE)));
    const dependants = useSelector((x => x.patientInfo.dependentDetails));
    const userLength = useMemo(() => (Object.keys(primary).length > 0 ? 1 : 0) + (spouse && Object.keys(spouse).length > 0 ? 1 : 0) + dependants.length, [primary, spouse, dependants]);
    const insuranceType = useQuery().get('type');
    const [dobPlaceholder, setDobPlaceHolder] = useState('date');
    const [lookupExtraDetailsDep, setLookupExtraDetailsDep] = useState({ insurance: {}, careProvider: {} });
    const linguistic = resource ? resource[3].values : [];
    const sexual = resource ? resource[1].values : [];
    const racial = resource ? resource[0].values : [];
    const ethnic = resource ? resource[2].values : [];
    const accordionRenderCondition = useMemo(() => {
        if (!isMainInstance) return true;
        if (isMainInstance && !formState.showCurrentForm) return false;
        if (formState.showCurrentForm || location?.state?.from !== "/insurance") return true;
        return false;
    }, [isMainInstance, location?.state?.from, formState.showCurrentForm]);
    const showRelationShipCondition = useMemo(() => (actualForm?.relationship?.includes(DEPENDANT) || actualForm?.status.includes(DEPENDANT)) || (actualForm?.relationship?.includes(SPOUSE) || actualForm?.status.includes(SPOUSE)), [actualForm?.relationship, actualForm?.status]);
    const err = (
        actualForm?.id.includes(CURRENT) ? errors.currentErrors :
            actualForm?.id.includes(PRIMARY) ? errors.primaryErrors :
                actualForm?.id.includes(SPOUSE) ? errors.dependentsErrors.find(de => de.id === SPOUSE) :
                    actualForm?.id.includes(DEPENDANT) ? errors.dependentsErrors.find(de => de.id === formState.focused) : errors.currentErrors
    );

    useEffect(() => {
        if (!formState.showCurrentForm && !formState.focused) {
            saveAll();
            changeFocus(null);
        }
    }, [formState.showCurrentForm, formState.focused]);

    // EFFECTS
    useEffect(() => { clearCurrent(); }, []);
    useEffect(() => {
        if (Object.keys(primary).length > 0) addPrimary({ val: primary, next: null });
        if (spouse && Object.keys(spouse).length > 0) addSpouse({ val: spouse.basicInfo, next: null });
        if (dependants.length > 0) {
            for (const dep of dependants) addDependant({ val: dep.basicInfo, next: null });
        }
    }, []);

    // eslint-disable-next-line
    useLayoutEffect(() => { if (userLength > 0) hideCurrentForm(); }, []);
    // eslint-disable-next-line
    useEffect(() => { setShadowForm(formState.current) }, [formState.current]);

    useEffect(() => {
        if (accordionID) {
            const item = getPatientInfo(accordionID);
            if (item) setShadowForm(() => item);
        }
        // eslint-disable-next-line
    }, [accordionID]);

    const getStatusMessage = () => {
        const trueDepLen = formState.dependents.filter(x => x.status === DEPENDANT).length;
        const spouseLen = formState.dependents.filter(x => x.status === SPOUSE).length;
        const actualStatus = trueDepLen < 10 ? DEPENDANT : spouseLen < 1 ? SPOUSE : null;
        return actualStatus;
    }

    /* istanbul ignore next */
    const getPatientCall = async (firstName, lastName, dob, sex, actualForm) => {
        const date_of_birth = new Date(dob).toISOString().replace("00.000Z", "00Z");
        const url = BASE_URL_PATIENT + "?firstName=" + firstName + "&lastName=" + lastName + "&dateOfBirth=" + date_of_birth + "&sex=" + sex;
        const response = await httpReq(url, null);
        if (response.status === 200) {
            if (response.data.externalId === null) {
                let patientAge = getAge(response.data.dateOfBirth);
                let ageLimit = 18;
                if (actualForm.status === PRIMARY || actualForm.relationship === PRIMARY || actualForm.status === "" || actualForm.relationship === "") {
                    if (localStorage.getItem("RES_FOR_PATIENT_POST") === null) {
                        let responseForm = generateForm({ ...response.data, status: PRIMARY, id: PRIMARY }, racial, ethnic, linguistic);
                        if (patientAge >= ageLimit) {
                            let signDoc = generateSignDocObject(response.data);
                            let initState = {
                                primaryDetails: [{
                                    basicInfo: { ...responseForm, status: PRIMARY, id: PRIMARY },
                                    insurance: { memberId: response.data.planNumber ?? "", groupNumber: response.data.groupNumber ?? "" },
                                    careProvider: { location: response.data.location ?? "", locationId: response.data.locationId ?? "", choosePrimary: "", providerID: response.data.practitionerId ?? "" },
                                    signDocument: signDoc
                                }],
                                spouseDetails: [],
                                dependentDetails: [],
                                resources: resource
                            }
                            if (response.data.children.length > 0) {
                                let dependentArray = [];
                                for (let x of response.data.children) {
                                    let responseForm = generateForm({ ...x, status: x.patientType.toUpperCase() }, racial, ethnic, linguistic,)
                                    let signDoc = generateSignDocObject(x);
                                    if (x.patientType.toUpperCase() === SPOUSE) {
                                        dependentArray.push({
                                            basicInfo: { ...responseForm, relationship: SPOUSE, status: SPOUSE, id: SPOUSE },
                                            insurance: { memberId: x.planNumber ?? "", SameMemberId: (response.data.planNumber && response.data.planNumber === x.planNumber) ? true : false },
                                            careProvider: { location: x.location ?? "", locationId: x.locationId ?? "", choosePrimary: "", providerID: x.practitionerId ?? "" },
                                            signDocument: signDoc
                                        });
                                    } else {
                                        dependentArray.push({
                                            basicInfo: { ...responseForm, relationship: DEPENDANT, status: DEPENDANT, id: `${DEPENDANT}-${dependentArray.length}` },
                                            insurance: { memberId: x.planNumber ?? "", SameMemberId: (response.data.planNumber && response.data.planNumber === x.planNumber) ? true : false, status: DEPENDANT },
                                            careProvider: { location: x.location ?? "", locationId: x.locationId ?? "", choosePrimary: "", providerID: x.practitionerId ?? "", status: DEPENDANT },
                                            signDocument: signDoc
                                        });
                                    }
                                }
                                if (dependentArray.length > 0) initState.dependentDetails = dependentArray;
                            }
                            await setAllPatient(initState);

                            if (insuranceTypeRoute.includes("third-party_") && response.data?.enrollmentMetadata?.enrollmentType === "EmployerGroup") {
                                navigate({ pathname: '/basic-info', search: '?type=' + insuranceTypeRoute });
                            }
                            else {
                                navigate({ pathname: '/basic-info', search: '?type=' + response.data.insuranceType });
                            }
                        }
                    }
                } else {
                    let responseForm = generateForm({ ...response.data, status: actualForm.status, relationship: actualForm.relationship }, racial, ethnic, linguistic);
                    let currentForm = {
                        ...actualForm,
                        sex: responseForm.sex,
                        phonenumber: responseForm.phonenumber,
                        email: responseForm.email,
                        addressLine1: responseForm.addressLine1,
                        addressLine2: responseForm.addressLine2,
                        city: responseForm.city,
                        ethnicity: responseForm.ethnicity,
                        language: responseForm.language,
                        preferredMobile: responseForm.preferredMobile,
                        race: responseForm.race,
                        state: responseForm.state,
                        zipcode: responseForm.zipcode
                    };
                    prepareCurrentForm(currentForm);
                    setLookupExtraDetailsDep({
                        insurance: { memberId: response.data.planNumber ?? "", SameMemberId: false },
                        careProvider: { location: response.data.location ?? "", locationId: response.data.locationId ?? "", choosePrimary: "", providerID: response.data.practitionerId ?? "" },
                    });
                }
                if (response.data.patientType.toUpperCase() === PRIMARY && (actualForm.status === PRIMARY || actualForm.relationship === PRIMARY || actualForm.status === "" || actualForm.relationship === "")) {
                    if (patientAge >= ageLimit) {
                        if (localStorage.getItem("RES_FOR_PATIENT_POST") === null) {
                            localStorage.setItem("RES_FOR_PATIENT_POST", JSON.stringify(response.data));
                        }
                    }
                }
            } else if (response.data.externalId) {
                if (actualForm.status === PRIMARY || actualForm.relationship === PRIMARY || actualForm.status === "" || actualForm.relationship === "") {
                    let failedCases = ["FAILED", "INCOMPLETE", "REQUIRES_PAYMENT_VERIFICATION", null];
                    let bPaymentStatusFail = failedCases.includes(response.data.paymentStatus) ? true : false;
                    navigate(`/existingUser?type=${response.data.insuranceType?.toLowerCase()}`, { state: { paymentStatusFail: bPaymentStatusFail } });
                } else {
                    let name = (`${response.data.firstName} ${response.data.lastName}`);
                    let message = t('ErrorMessageInBanner.dependentAlreadyPresent1') + `<b> ${name}</b> ` + t('ErrorMessageInBanner.dependentAlreadyPresent2');
                    showErrorMessageCallback(message);
                }
            }
        }
    }

    const lookupCall = (name, value, form) => {
        const fieldNameArray = ["firstName", "lastName", "email", "phonenumber", "dob", "sex"];
        if (fieldNameArray.includes(name)) {
            const firstName = name === "firstName" ? value : form.firstName;
            const lastName = name === "lastName" ? value : form?.lastName;
            const dob = name === "dob" ? value : form?.dob;
            const sex = name === "sex" ? value : form?.sex;
            if (firstName && lastName && dob && sex)
                getPatientCall(firstName, lastName, dob, sex, form);
        }
    }

    // CHANGES AND VALIDATIONS
    const onChange = (e, id) => {
        changeFocus(id);
        if (e.target.name === "firstName" || e.target.name === "lastName") {
            if (!isValidName(e.target.value)) {
                e.preventDefault();
                return;
            }
        }

        if (formState.focused === CURRENT) setShadowForm(() => ({ ...formState.current, [e.target.name]: e.target.value }));
        changeValue({ id, key: e.target.name, value: e.target.value });
        if (userLength > 0 && e.target.name === "email" && id === PRIMARY) syncEmail(e.target.value);
        if (
            (userLength > 0 && id === PRIMARY) && (
                e.target.name === "addressLine1" ||
                e.target.name === "addressLine2" ||
                e.target.name === "city" ||
                e.target.name === "state" ||
                e.target.name === "zipcode"
            )
        ) syncAddress({
            addressLine1: formState.primary.addressLine1,
            addressLine2: formState.primary.addressLine2,
            city: formState.primary.city,
            state: formState.primary.state,
            zipcode: formState.primary.zipcode,
            [e.target.name]: e.target.value
        });
        if ((accordionID && accordionID !== CURRENT) && !isMainInstance)
            onDirectChange({
                id: accordionID,
                key: e.target.name,
                value: e.target.value
            });
    };

    const onDropdownChange = (val, name, id) => {
        changeFocus(id);
        changeValue({ id, key: name, value: val });
        if (name === "state" && id === PRIMARY) syncAddress({
            addressLine1: formState.primary.addressLine1,
            addressLine2: formState.primary.addressLine2,
            city: formState.primary.city,
            state: formState.primary.state,
            zipcode: formState.primary.zipcode,
            [name]: val
        });
        if (id === CURRENT) setShadowForm(() => ({ ...formState.current, [name]: val }));
        if ((accordionID && accordionID !== CURRENT) && !isMainInstance)
            onDirectChange({
                id: accordionID,
                key: name,
                value: val
            });
        if (name === "sex" && actualForm && val !== actualForm.sex) { 
            if (id !== PRIMARY) {
                lookupCall(name, val, actualForm);
            } }
    };

    /* istanbul ignore next */
    const changeRelationShip = (id, form) => {
        const spouseExistsAndIsPicked = (id === SPOUSE && spouse) || (id === SPOUSE && formState.dependents.find(dep => dep.id === SPOUSE));
        const depsExistPickedAndMaxedOut = (id.includes(DEPENDANT) && dependants.length > 9) || (id.includes(DEPENDANT) && formState.dependents.length > 9);
        if (spouseExistsAndIsPicked || depsExistPickedAndMaxedOut) return;
        if (id.includes(SPOUSE) && form.id.includes(SPOUSE)) return;
        if (id.includes(DEPENDANT) && form.id.includes(DEPENDANT)) return;
        if (!form.id.includes(CURRENT)) return;
        const statusRelVals = id.includes(DEPENDANT) ? DEPENDANT : SPOUSE;
        changeValue({ id: form?.id, key: "status", value: statusRelVals });
        if (id.includes(SPOUSE)) changeValue({ id: form?.id, key: "primaryEmail", value: false });
        changeValue({ id: form?.id, key: "relationship", value: statusRelVals });
    }

    /* istanbul ignore next */
    const formatZipCode = (e, id) => {
        changeFocus(id);
        if (e.target.value === "") {
            changeValue({ id: formState.focused, key: "zipcode", value: "" });
            if (formState.focused === CURRENT) setShadowForm(() => ({ ...formState.current, zipcode: "" }));
        }
        if (!isValidZipCode(e.target.value)) return;
        if (e.target.value.length > 5) return;
        else onChange(e, id);
    };

    // MISC
    const openAccordion = (_, __, id) => {
        if ((formState.focused === CURRENT && !formState.showCurrentForm) || (formState.focused == null || !formState.focused)) {
            changeFocus(id);
            setIsClosed(() => true);
            return;
        }
        if (formState.focused) {
            setReminder(true);
            return;
        }
    };

    const addressItemsChange = (e, id) => {
        onChange(e, id);
        if (userLength > 0 && id === PRIMARY) syncAddress({
            addressLine1: formState.primary.addressLine1,
            addressLine2: formState.primary.addressLine2,
            city: formState.primary.city,
            state: formState.primary.state,
            zipcode: formState.primary.zipcode,
            [e.target.name]: e.target.value
        });
    }

    const handlePhoneNumber = (e, id) => {
        changeFocus(id);
        if (e.target.value.replace(/[^\d]/g, '').length > 10) return;
        const newPhoneNumber = formatPhoneNumberInput(e.target.value);
        changeValue({ id: formState.focused, key: "phonenumber", value: newPhoneNumber })
        if (formState.focused === CURRENT) setShadowForm(() => ({ ...formState.current, phonenumber: newPhoneNumber }));
        if (accordionID && accordionID !== CURRENT)
            onDirectChange({
                id: accordionID,
                key: "phonenumber",
                value: newPhoneNumber
            });
        if (id === PRIMARY) syncPhone({
            phonenumber: formState.focused === PRIMARY ? newPhoneNumber : formState.primary.phonenumber,
            preferredMobile: formState.primary.preferredMobile
        });
    }

    const handlePreferredMobile = (n, id) => {
        changeFocus(id);
        changeValue({ id: formState.focused, key: "preferredMobile", value: n.value });
        if (id === PRIMARY) syncPhone({ preferredMobile: n.value, phonenumber: formState.primary.phonenumber });
        if (formState.focused === CURRENT) setShadowForm(() => ({ ...formState.current, preferredMobile: n.value }));
        if ((accordionID && accordionID !== CURRENT) && !isMainInstance)
            onDirectChange({
                id: accordionID,
                key: "preferredMobile",
                value: n.value
            });
    }

    const maximumDate = useMemo(() => {
        if ((Object.keys(primary).length === 0 && (formState.focused === PRIMARY || formState.focused === CURRENT)) || formState.focused === SPOUSE)
            return getNYearsAgo(18);
        if (formState.focused === SPOUSE || formState.current.relationship === SPOUSE || formState.current.status === SPOUSE)
            return getNYearsAgo(18);
        if (formState.focused === CURRENT && actualForm?.relationship === SPOUSE || actualForm?.status === SPOUSE)
            return getNYearsAgo(18);
        return new Date(Date.now()).toISOString().split("T")[0];
    }, [primary, formState.focused, spouse, formState.current.relationship, formState.current.status]);

    const handlePrimaryAddress = (id) => {
        changeFocus(id);
        if (id !== PRIMARY) {
            changeValue({ id, key: "primaryAddress", value: !actualForm.primaryAddress });
            changeValue({ id, key: "addressLine1", value: !actualForm.primaryAddress ? formState.primary.addressLine1 : "" });
            changeValue({ id, key: "addressLine2", value: !actualForm.primaryAddress ? formState.primary.addressLine2 : "" });
            changeValue({ id, key: "city", value: !actualForm.primaryAddress ? formState.primary.city : "" });
            changeValue({ id, key: "state", value: !actualForm.primaryAddress ? formState.primary.state : "" });
            changeValue({ id, key: "zipcode", value: !actualForm.primaryAddress ? formState.primary.zipcode : "" });
        }
        setShadowForm((prevForm) => prevForm.status === PRIMARY ? prevForm : ({
            ...prevForm,
            primaryAddress: !prevForm.primaryAddress,
            addressLine1: !prevForm.primaryAddress ? primary.addressLine1 : "",
            addressLine2: !prevForm.primaryAddress ? primary.addressLine2 : "",
            city: !prevForm.primaryAddress ? primary.city : "",
            state: !prevForm.primaryAddress ? primary.state : "",
            zipcode: !prevForm.primaryAddress ? primary.zipcode : "",
        }));
        if (isMainInstance) return;
        else {
            if (id !== PRIMARY) {
                onDirectChange({ id: accordionID, key: "primaryAddress", value: !actualForm.primaryAddress, });
                onDirectChange({ id: accordionID, key: "addressLine1", value: !actualForm.primaryAddress ? primary.addressLine1 : "", });
                onDirectChange({ id: accordionID, key: "city", value: !actualForm.primaryAddress ? primary.city : "", });
                onDirectChange({ id: accordionID, key: "state", value: !actualForm.primaryAddress ? primary.state : "", });
                onDirectChange({ id: accordionID, key: "zipcode", value: !actualForm.primaryAddress ? primary.zipcode : "", });
            }
        }
    }

    const handlePrimaryPhone = (id) => {
        if (id !== PRIMARY) {
            changeFocus(id);
            changeValue({ id, key: "primaryPhone", value: !actualForm.primaryPhone });
            changeValue({ id, key: "phonenumber", value: !actualForm.primaryPhone ? formState.primary.phonenumber : "" });
            changeValue({ id, key: "preferredMobile", value: !actualForm.primaryPhone ? formState.primary.preferredMobile : "" });
            setShadowForm((prevForm) => prevForm.status === PRIMARY ? prevForm : ({
                ...prevForm,
                primaryPhone: !prevForm.primaryPhone,
                phonenumber: !prevForm.primaryPhone ? primary.phonenumber : "",
                preferredMobile: !prevForm.primaryPhone ? primary.preferredMobile : ""
            }));
        }
        if (isMainInstance) return;
        else {
            if (id !== PRIMARY) {
                onDirectChange({ id: accordionID, key: "primaryPhone", value: !actualForm.primaryPhone, });
                onDirectChange({ id: accordionID, key: "phonenumber", value: !actualForm.primaryPhone ? primary.phonenumber : "", });
                onDirectChange({ id: accordionID, key: "preferredMobile", value: !actualForm.primaryPhone ? primary.preferredMobile : "", });
            }
        }
    }

    const handlePrimaryEmail = (id) => {
        if (id !== PRIMARY) {
            changeFocus(id);
            changeValue({ id, key: "primaryEmail", value: !actualForm.primaryEmail });
            changeValue({ id, key: "email", value: !actualForm.primaryEmail ? formState.primary.email : "" });
            setShadowForm((prevForm) => prevForm.status === PRIMARY ? prevForm : ({
                ...prevForm,
                primaryEmail: !prevForm.primaryEmail,
                email: !prevForm.primaryEmail ? primary.email : "",
            }));
        }
        if (isMainInstance) return;
        else {
            if (id !== PRIMARY) {
                onDirectChange({ id: accordionID, key: "primaryEmail", value: !actualForm.primaryEmail, });
                onDirectChange({ id: accordionID, key: "email", value: !actualForm.primaryEmail ? primary.email : "", });
            }
        }
    }

    const handlePersonCleanup = (data, newInitForm, status) => {
        if (data.status.includes(PRIMARY)) addPrimary({ val: data, next: status });
        if (data.status.includes(SPOUSE)) {
            if (formState.dependents.filter(dep => dep.id === SPOUSE).length <= 0) addSpouse({ val: data, next: status });
            else if (formState.dependents.filter(dep => dep.id === SPOUSE).length > 0) updateDependent({ val: data, next: status });
        }
        if (data.status.includes(DEPENDANT)) {
            if (formState.dependents.filter(dep => dep.id === data.id).length <= 0) addDependant({ val: data, next: status });
            else if (formState.dependents.filter(dep => dep.id === data.id).length > 0) updateDependent({ val: data, next: status });
        }
        clearCurrent();
        hideCurrentForm();
        changeFocus(null);
        setPatientInfo(data, lookupExtraDetailsDep);
        clearAllErrors();
        setShadowForm(() => newInitForm);
    };

    const prepareRemoveForAdd = () => {
        changeFocus(null);
        clearCurrent();
        hideCurrentForm();
        saveAll();
        // Remove the data from the form completely
    };

    const saveCurrentChanges = (form, id) => {
        const { hasErrors, errIds } = check(form, isMainInstance, true, insuranceType);
        const currentDepIds = formState.dependents.map(d => d.id);
        const newDepId = spinNewDepId(false, `${DEPENDANT}-${formState.dependents.length}`, currentDepIds);
        const newId = form?.status.includes(DEPENDANT) ? newDepId : form?.status.includes(SPOUSE) ? SPOUSE : PRIMARY;
        if (hasErrors) {
            getCoordinates(errIds[0], form?.id);
            return;
        }
        if (id === CURRENT) {
            addPerson(getStatusMessage(), { ...form, isFormSaved: true });
            handlePersonCleanup({ ...form, id: newId }, { ...initForm, primaryEmail: false, primaryPhone: false, primaryAddress: false }, form.status);
            saveAll();
            changeFocus(null);
        }
        else {
            setIsClosed(() => false);
            const formExists = (id === PRIMARY && doesFormExist(formState.primary.firstName)) || (id !== CURRENT && formState.dependents.find(de => de.id === id))
            if (formExists) {
                saveAll();
            }
            else handlePersonCleanup(form, { ...initForm, primaryEmail: false, primaryPhone: false, primaryAddress: false }, formState.current.status);
        }
        changeFocus(null);
        setReminder(false);
    };

    // REDUX STORE MANIPULATION
    const addPerson = (status) => {
        if (!status) return;
        const EVERY_FORM_SAVED = !isEveryFormSaved();
        setIsClosed(() => true);
        const { hasErrors: fullCheckHasErrors, erroredForms } = checkAll(formState.showCurrentForm, insuranceType);
        const SPOUSE_EXISTS_AND_IS_PICKED_CONDITION = (status === SPOUSE && formState.focused === SPOUSE && spouse) || (status === SPOUSE && spouse && Object.keys(spouse).length > 0) || (status === SPOUSE && formState.dependents.find(d => d.id === SPOUSE));
        const DEPENDENTS_FULL_CONDITION = (status === DEPENDANT) && (formState.dependents.filter(d => d.id.includes(DEPENDANT)).length > 9);
        if (!formState.showCurrentForm) {
            if (fullCheckHasErrors) {
                changeFocus(erroredForms[0].id);
                return;
            }
            if (SPOUSE_EXISTS_AND_IS_PICKED_CONDITION || DEPENDENTS_FULL_CONDITION) return;
            if (EVERY_FORM_SAVED) {
                setReminder(true);
                return;
            }
            showCurrentForm();
            prepareCurrentForm({ ...formState.current, status, relationship: status });
            changeFocus(CURRENT);
            return;
        }
        if (SPOUSE_EXISTS_AND_IS_PICKED_CONDITION || DEPENDENTS_FULL_CONDITION) return;
        const { hasErrors } = check(formState.current, isMainInstance, true, insuranceType);
        if (hasErrors) return;
        if (EVERY_FORM_SAVED) {
            setReminder(true);
            return;
        }
        const NEW_INIT_FORM = { ...initForm, status, relationship: status };
        const NO_PRIMARY_CONDITION = !primary || Object.keys(primary).length === 0 || userLength === 0;
        const SPOUSE_DOESNT_EXIST_CONDITION = (formState.current.status === SPOUSE || formState.current.relationship === SPOUSE) && !spouse;
        const DEPENDENTS_NOT_FULL_CONDITION = (formState.current.status === DEPENDANT || formState.current.relationship === DEPENDANT) && patientInfo?.dependentDetails.filter(x => x.id.includes(DEPENDANT)).length <= 10;
        const NO_STATUS_RELATIONSHIP_CONDITION = !formState.current.status && !formState.current.relationship;
        if (NO_PRIMARY_CONDITION) {
            const newPrimary = { ...formState.current, status: PRIMARY, id: PRIMARY, isFormSaved: true };
            handlePersonCleanup(newPrimary, NEW_INIT_FORM, PRIMARY);
            return;
        }
        if (SPOUSE_DOESNT_EXIST_CONDITION) {
            handlePersonCleanup({ ...formState.current, status: SPOUSE, relationship: SPOUSE, id: SPOUSE, isFormSaved: true }, NEW_INIT_FORM, status);
            return;
        }
        if (DEPENDENTS_NOT_FULL_CONDITION) {
            const currentDepIds = formState.dependents.map(d => d.basicInfo.id);
            const newId = spinNewDepId(false, `${DEPENDANT}-${formState.dependents.length}`, currentDepIds);
            // current form is used for new forms, hence the `false`
            const newDep = { ...formState.current, status: DEPENDANT, relationship: DEPENDANT, id: newId, isFormSaved: true };
            handlePersonCleanup(newDep, NEW_INIT_FORM, status);
            return;
        }
        if (NO_STATUS_RELATIONSHIP_CONDITION) {
            // Same as the previous comment
            if (status === SPOUSE && spouse) return;
            if (status === DEPENDANT && formState.dependents.length > 9) return; // should allow max 10 now
            handlePersonCleanup({
                ...formState.current,
                status,
                relationship: status, id: status === SPOUSE ? SPOUSE : `${DEPENDANT}-${formState.dependents.length}`,
                isFormSaved: true
            }, NEW_INIT_FORM, status);
            return;
        }
    };

    const onSubmit = e => e.preventDefault();
    const removePerson = async (form) => {
        changeFocus(form?.id);
        if (formState.showCurrentForm || !form?.id || !form) {
            prepareRemoveForAdd();
            return;
        }
        setIsClosed(() => false);
        removePersonCtx(form?.id);
        await removePatientInfo(form?.id, form.status);
    }

    // FORM JSX
    const basicInfoForm = (form) => (
        <form onSubmit={onSubmit} className="details-form p-0" style={{ marginTop: "26px" }}>
            <div className="row" style={{ marginBottom: `${SPACING.formLabelLast.bottom}%` }}>
                <label className="label-text" style={{ marginBottom: `${SPACING.formLabelLast.bottom}%` }}>{t('basic.form.r1.label')}</label>
                <BasicInfoFormGroup
                    data-testid="textInput-basicForm"
                    autoComplete="on"
                    isHalf
                    name="firstName"
                    id="firstName"
                    value={form?.firstName}
                    placeholder={t('basic.form.r1.placeholder1')}
                    type="text"
                    className="form-control details-input basic-info-input-accent"
                    onChange={(e) => onChange(e, form?.id)}
                    error={err && err.firstNameErr}
                    autoFocus
                    patientId={form?.id}
                    onBlur={(e) => lookupCall(e.target.name, e.target.value, actualForm)}
                />
                <BasicInfoFormGroup
                    data-testid="textInput-basicForm"
                    autoComplete="on"
                    isHalf
                    name="lastName"
                    id="lastName"
                    value={form?.lastName}
                    type="text"
                    placeholder={t('basic.form.r1.placeholder2')}
                    className="form-control details-input basic-info-input-accent"
                    onChange={(e) => onChange(e, form?.id)}
                    error={err && err.lastNameErr}
                    patientId={form?.id}
                    onBlur={(e) => lookupCall(e.target.name, e.target.value, actualForm)}
                />
            </div>
            <div className="row" style={{ marginBottom: `${SPACING.formLabelLast.bottom}%` }}>
                <BasicInfoFormGroup
                    data-testid="textInput-basicForm"
                    autoComplete="on"
                    name="dob"
                    id="dob"
                    value={form?.dob}
                    placeholder={t('basic.form.r2.placeholder')}
                    type={dobPlaceholder}
                    className={`form-control details-input basic-info-input-accent ${!form?.dob && "placeholderColor"}`}
                    onChange={(e) => onChange(e, form?.id)}
                    formId={form?.id}
                    labelText={t('basic.form.r2.label')}
                    onFocus={() => setDobPlaceHolder("date")}
                    onBlur={(e) => { !form?.dob && setDobPlaceHolder("text"); lookupCall(e.target.name, e.target.value, actualForm) }}
                    min="1900-01-01"
                    error={err && err.dobErr}
                    max={maximumDate}
                    patientId={form?.id}
                />
            </div>
            <div className="row" style={{ marginBottom: `${SPACING.formLabelLast.bottom}%` }}>
                <BasicInfoDropdown
                    autoComplete="new-password"
                    testId="dropdown-basicForm-sex"
                    htmlFor="sex"
                    id="sex"
                    mainLabelText={t('basic.form.r10.label')}
                    value={form?.sex}
                    valPlaceholder={t('basic.form.r10.placeholder')}
                    list={sexual}
                    onDropdownChange={onDropdownChange}
                    formId={form?.id}
                    error={err && err.sexErr}
                    name="sex"
                    isEditable
                    filtering
                />
            </div>
            {/* eslint-disable-next-line */}
            {(showRelationShipCondition) && (
                <div className="row" style={{ marginBottom: `${SPACING.formLabelLast.bottom}%` }}>
                    <RelationshipDropdown
                        id="relationship"
                        htmlFor="relationship"
                        mainLabelText={t('basic.form.cond.rel.label')}
                        value={form?.status}
                        valPlaceholder={t('basic.form.cond.rel.placeholder')}
                        list={relational}
                        onDropdownChange={changeRelationShip}
                        isEditable={false}
                        form={form}
                        name="relationship"
                    />
                </div>
            )}
            <div className="row" style={{ marginBottom: `${SPACING.formLabelLast.bottom}%` }}>
                <div className="form-group col-12">
                    <label htmlFor="email" className="label-text form-label" style={{ marginBottom: `${SPACING.formLabelLast.bottom}%` }}>{t('basic.form.r3.label')}</label>
                    {form?.status === DEPENDANT && form?.dob && getYearsOfPerson(form?.dob) < 14 && (
                        <div style={{ marginBottom: "3%" }}>
                            <label htmlFor="primaryEmail">
                                <Form.Check
                                    type="checkbox"
                                    name="primaryEmail"
                                    id={"primaryEmail" + form?.id}
                                    value={form?.primaryEmail}
                                    onChange={() => handlePrimaryEmail(form?.id)}
                                    label={t("basic.form.r3.primary")}
                                    checked={form?.primaryEmail}
                                />
                            </label>
                        </div>
                    )}
                    <input
                        autoComplete="on"
                        name="email"
                        id="email"
                        data-testid="Primaryemail-test"
                        value={form?.email}
                        type="email"
                        className={`form-control details-input basic-info-input-accent`}
                        placeholder={t('basic.form.r3.placeholder')}
                        onChange={(e) => onChange(e, form?.id)}
                        readOnly={form?.id !== PRIMARY && form?.primaryEmail && !(getYearsOfPerson(form?.dob) >= 14 && document.getElementById(`primaryEmail${form?.id}`).click())}
                    />
                    <div id={`${form?.id}-emailErr`} className="pt-2 text-danger">
                        {err && err?.emailErr.length > 0 && (<div>{err.emailErr[0]}</div>)}
                    </div>
                </div>
            </div>
            <div className="row" style={{ marginBottom: `${SPACING.formLabelLast.bottom}%` }}>
                <div className="form-group col-12">
                    <label htmlFor="phonenumber" className="label-text form-label" style={{ marginBottom: `${SPACING.formLabelLast.bottom}%` }}>
                        <span>{t('basic.form.r4.label')}</span> <br />
                        <small><i className="phoneNumber">{t('basic.form.r4.misc')}</i></small>
                    </label>
                    {(form?.status === DEPENDANT || form?.status === SPOUSE) && (
                        <div style={{ marginBottom: "3%" }}>
                            <label htmlFor="primaryPhone">
                                <Form.Check
                                    type="checkbox"
                                    name="primaryPhone"
                                    id={"primaryPhone" + form?.id}
                                    checked={form?.primaryPhone}
                                    value={form?.primaryPhone}
                                    onChange={() => handlePrimaryPhone(form?.id)}
                                    label={t("basic.form.r4.primary")}
                                />
                            </label>
                        </div>
                    )}
                    <input
                        autoComplete="on"
                        name="phonenumber"
                        id="phonenumber"
                        data-testid="phoneNumber-test"
                        type="search"
                        value={form?.phonenumber}
                        className="form-control details-input basic-info-input-accent"
                        placeholder={t('basic.form.r4.placeholder')}
                        onChange={(e) => handlePhoneNumber(e, form?.id)}
                        readOnly={form?.id !== PRIMARY && form?.primaryPhone}
                    />
                    <div id={`${form?.id}-phonenumberErr`} className="pt-2 text-danger">
                        {err && err.phonenumberErr.length > 0 && (<div>{err.phonenumberErr[0]}</div>)}
                    </div>
                </div>
            </div>
            <div className="row" style={{ margin: `${SPACING.radioItems.top}% 0 ${SPACING.radioItems.bottom}% 0` }}>
                <BasicInfoRadio
                    list={nums}
                    onChange={onChange}
                    error={err && err.preferredMobileErr}
                    stateVal={form?.preferredMobile}
                    id={form?.id}
                    setRadio={handlePreferredMobile}
                    readOnly={form?.id !== PRIMARY && form?.primaryPhone}
                    patientId={form?.id}
                />
            </div>
            <div className="row" style={{ marginBottom: `${SPACING.formLabelLast.bottom}%` }}>
                <div className="form-group col-12">
                    <label style={{ marginBottom: `${SPACING.formLabelLast.bottom}%` }} htmlFor="address_line_1" className="label-text form-label">{t('basic.form.r6.label')}</label>
                    {(form?.status === DEPENDANT || form?.status === SPOUSE) && (
                        <div>
                            <div style={{ margin: "0 auto 2% auto" }}>
                                <label htmlFor="primaryAddress">
                                    <Form.Check
                                        type="checkbox"
                                        name="primaryAddress"
                                        id={"primaryAddress" + form?.id}
                                        checked={form?.primaryAddress}
                                        value={form?.primaryAddress}
                                        onChange={() => handlePrimaryAddress(form?.id)}
                                        label={t("basic.form.r6.primary")}
                                    />
                                </label>
                            </div>
                        </div>
                    )}
                    <input
                        autoComplete="on"
                        name="addressLine1"
                        id="addressLine1"
                        value={form?.addressLine1}
                        data-testid="addressLine1-test"
                        type="text"
                        className="form-control details-input basic-info-input-accent"
                        placeholder={t('basic.form.r6.placeholder1')}
                        onChange={(e) => addressItemsChange(e, form?.id)}
                        readOnly={form?.id !== PRIMARY && form?.primaryAddress}
                    />
                    <div id={`${form?.id}-addressLine1Err`} className="pt-2 text-danger">
                        {err && err.addressLine1Err.length > 0 && (<div>{err && err.addressLine1Err[0]}</div>)}
                    </div>
                    <input
                        autoComplete="on"
                        name="addressLine2"
                        id="addressLine2"
                        data-testid="addressLine2-test"
                        value={form?.addressLine2}
                        type="text"
                        className="form-control details-input mt-3 basic-info-input-accent"
                        placeholder={t('basic.form.r6.placeholder2')}
                        onChange={(e) => addressItemsChange(e, form?.id)}
                        readOnly={form?.id !== PRIMARY && form?.primaryAddress}
                    />
                </div>
            </div>
            <div className="row" style={{ marginBottom: `${SPACING.formLabelLast.bottom}%` }}>
                <BasicInfoFormGroup
                    autoComplete="on"
                    name="city"
                    id="city"
                    value={form?.city}
                    placeholder={t('basic.form.r7.placeholder')}
                    type="text"
                    className="form-control details-input basic-info-input-accent"
                    onChange={(e) => addressItemsChange(e, form?.id)}
                    error={err && err.cityErr}
                    labelText={t('basic.form.r7.label')}
                    patientId={form?.id}
                    readOnly={form?.id !== PRIMARY && form?.primaryAddress}
                />
            </div>
            <div className="row" style={{ marginBottom: `${SPACING.formLabelLast.bottom}%` }}>
                <BasicInfoDropdown
                    autoComplete="on"
                    id="state"
                    htmlFor="state"
                    mainLabelText={t('basic.form.r8.label')}
                    value={form?.state}
                    valPlaceholder={t('basic.form.r8.placeholder')}
                    list={States}
                    filtering
                    onDropdownChange={onDropdownChange}
                    formId={form?.id}
                    error={err && err.stateErr}
                    name="state"
                    patientId={form?.id}
                    isEditable={!(form?.id !== PRIMARY && form?.primaryAddress)}
                />
            </div>
            <div className="row" style={{ marginBottom: `${SPACING.formLabelLast.bottom}%` }}>
                <BasicInfoFormGroup
                    readOnly={form?.id !== PRIMARY && form?.primaryAddress}
                    name="zipcode"
                    id="zipcode"
                    value={form?.zipcode}
                    placeholder={t('basic.form.r9.placeholder')}
                    type="text"
                    className="form-control details-input basic-info-input-accent"
                    onChange={(e) => formatZipCode(e, form?.id)}
                    error={err && err.zipcodeErr}
                    labelText={t('basic.form.r9.label')}
                    patientId={form?.id}
                    autoComplete="on"
                />
            </div>
            <div className="row" style={{ marginBottom: `${SPACING.formLabelLast.bottom}%` }}>
                <BasicInfoDropdown
                    htmlFor="race"
                    id="race"
                    autoComplete="new-password"
                    mainLabelText={t('basic.form.r11.label')}
                    value={form?.race}
                    valPlaceholder={t('basic.form.r11.placeholder')}
                    patientId={form?.id}
                    list={racial}
                    onDropdownChange={onDropdownChange}
                    formId={form?.id}
                    error={err && err.raceErr}
                    isEditable
                    name="race"
                    filtering
                />
            </div>
            {/* <!-- What is your Ethnicity? --> */}
            <div className="row" style={{ marginBottom: `${SPACING.formLabelLast.bottom}%` }}>
                <BasicInfoDropdown
                    htmlFor="ethnicity"
                    id="ethnicity"
                    autoComplete="new-password"
                    mainLabelText={t('basic.form.r12.label')}
                    value={form?.ethnicity}
                    valPlaceholder={t('basic.form.r12.placeholder')}
                    patientId={form?.id}
                    isEditable
                    list={ethnic}
                    onDropdownChange={onDropdownChange}
                    formId={form?.id}
                    error={err && err.ethnicityErr}
                    name="ethnicity"
                    filtering
                />
            </div>
            {/* <!-- Preferred language --> */}
            <div className="row" style={{ marginBottom: `${SPACING.formLabelLast.bottom}%` }}>
                <BasicInfoDropdown
                    htmlFor="language"
                    id="language"
                    autoComplete="new-password"
                    mainLabelText={t('basic.form.r13.label')}
                    value={form?.language}
                    patientId={form?.id}
                    valPlaceholder={t('basic.form.r13.placeholder')}
                    list={linguistic}
                    onDropdownChange={onDropdownChange}
                    formId={form?.id}
                    error={err && err.languageErr}
                    isEditable
                    name="language"
                    filtering
                />
            </div>
            {/* {Object.keys(primary).length !== 0 && ( */}
            <div className={((form?.status === PRIMARY || form?.status === "") && insuranceType === "medicare") ? "medicare-btn-section custom-width" : "btn-section custom-width"}>
                {(insuranceType !== "medicare") && (
                    <>
                        <button type="button"
                            data-testid="removeBtn-test"
                            disabled={(form?.status === PRIMARY || form?.status === "") && !isMainInstance}
                            hidden={(form?.status === PRIMARY || form?.status === "") && !isMainInstance && insuranceType === "medicare"}
                            className={`back-btn ${((form?.status === PRIMARY || form?.status === "") && !isMainInstance) ? "btn-disabled" : ""}`}
                            onClick={() => { clearAllErrors(); removePerson(form) }}
                        >Remove</button>
                        <button className="next-btn" hidden={insuranceType === "medicare"} onClick={() => saveCurrentChanges(form, form?.id)} type="button" data-testid="saveBtn-test">Save</button>
                    </>
                )}
            </div>
            {/* )} */}
        </form>
    );

    // RETURN STATEMENT
    return userLength > 0 ? (
        <div>
            {accordionRenderCondition && (
                <AccordionComponent
                    client_name={`${actualForm?.firstName || ""} ${actualForm?.lastName || ""}`}
                    client_type={actualForm?.status}
                    id={accordionID || CURRENT}
                    parentCallback={openAccordion}
                    styles={{ color: "#2B2B2B", fontWeight: 500 }}
                    alwaysOpen={alwaysOpen}
                    isClosed={isClosed}
                >{basicInfoForm(actualForm)}</AccordionComponent>
            )}
            <div>
                <div>
                    {isMainInstance && insuranceType !== "medicare" && (
                        <>
                            {/* <!-- Add Dependants section --> */}
                            {dependants.length < 11 && (
                                <div className="addDependants mb-3 pointer" data-testid="add-dependants-section">
                                    <div className="d-flex align-items-center" data-testid="add-dependants-btn-test" onClick={() => addPerson(getStatusMessage(), actualForm)}>
                                        <img className="me-2" src={addIcon} alt="Add a dependent" />
                                        <label><span style={{ color: "#0080DD" }}>{t('basic.form.r14.addChild')}</span> <span className="text-muted">{t('basic.form.r14.mustbe')}</span></label>
                                    </div>
                                </div>
                            )}
                            {/* <!-- Add spouse section --> */}
                        </>
                    )}
                </div>
            </div>
            {errors?.hasErrors && errors.message && (
                <div className="patient-info-snack-msg patient-info-snack-msg-danger">
                    <span>{errors.message}</span>
                    <span className="pointer" onClick={clearMsg}>&times;</span>
                </div>
            )}
            {errors?.message && errors?.message === REMINDER_MESSAGE && (
                <div className="patient-info-snack-msg patient-info-snack-msg-warning">
                    <span>{errors.message}</span>
                    <span className="pointer" onClick={clearMsg}>&times;</span>
                </div>
            )}
        </div>
    ) : (
        <div ref={singleWrapRef}>
            {basicInfoForm(formState.current)}
            <div>
                {isMainInstance && insuranceType !== "medicare" && (
                    <>
                        {/* <!-- Add Dependants section --> */}
                        <div className="addDependants mb-3 pointer" onClick={() => addPerson(DEPENDANT, actualForm)} data-testid="add-dependants-section">
                            <div className="d-flex align-items-center">
                                <img className="me-2" src={addIcon} alt="Add a dependent" />
                                <label><span style={{ color: "#0080DD" }}>{t('basic.form.r14.addChild')}</span> <span className="text-muted">{t('basic.form.r14.mustbe')}</span></label>
                            </div>
                        </div>
                        {/* <!-- Add spouse section --> */}
                    </>
                )}
            </div>
            {errors?.hasErrors && errors.message && (
                <div className="patient-info-snack-msg patient-info-snack-msg-danger">
                    <span>{errors.message}</span>
                    <span className="pointer" onClick={clearMsg}>&times;</span>
                </div>
            )}
            {errors?.message && errors?.message === REMINDER_MESSAGE && (
                <div className="patient-info-snack-msg patient-info-snack-msg-warning">
                    <span>{errors.message}</span>
                    <span className="pointer" onClick={clearMsg}>&times;</span>
                </div>
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({ patientInfo: state.patientInfo });

BasicInfoForm.propTypes = {
    isMainInstance: PropTypes.bool,
    resource: PropTypes.array,
    patientInfo: PropTypes.object,
    setPatientInfo: PropTypes.func,
    removePatientInfo: PropTypes.func,
    accordionID: PropTypes.string,
    setShadowForm: PropTypes.func,
    onDirectChange: PropTypes.func,
    setAllPatient: PropTypes.func,
    showErrorMessageCallback: PropTypes.func,
};

export default connect(mapStateToProps, { setPatientInfo, removePatientInfo, onDirectChange, setAllPatient })(BasicInfoForm);