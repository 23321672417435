import AccordionComponent from "../../../components/accordion/AccordionComponent";
import { useTranslation } from 'react-i18next';
import PatientIframe from "./PatientIframe";

const PatientBlock = ({ mainItem , updateDetailsAcknArray, personDetails, iframeDropdown, updateDetailsArray, handleCallback, index = 1 }) => {
    const { t } = useTranslation();

    return (
        <div className='signDoc-accordian-root' key={index}>            
            <AccordionComponent 
                id={mainItem.key} 
                client_name={mainItem.name} 
                client_type={mainItem.type} 
                key={index} 
                parentCallback={handleCallback}>
                <div key={index}>          
                    <div className='approval-padding-5'>
                        <div className="approval-flex-container">
                        <div className='signDocBtnSec'>
                            <div className="signDocapproval-font-age">{t('signDocument.ageConfirmation')}</div>
                            <div>
                            <button className='signDoc-btn-select-all' onClick={() => updateDetailsAcknArray(personDetails, mainItem.id)}>
                                {t('signDocument.btnSelectAll')}
                            </button>
                            </div>
                        </div>
                        </div>
                    </div> 
                    {mainItem.inputValues.map((item, index) => 
                        <PatientIframe
                            key={item.id} 
                            item={item}
                            iframeDropdown={iframeDropdown}
                            personDetails={personDetails}
                            mainItem={mainItem}
                            updateDetailsArray={updateDetailsArray}
                            index={index}
                        />
                    )}
                </div>
            </AccordionComponent>      
       </div>
    )
}

export default PatientBlock;